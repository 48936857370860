import { ToastProgrammatic as Toast } from "buefy";
import cookie from "vue-cookies";

// initial state
const state = {
  token: null,
  userId: null,
};

// getters
const getters = {
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
};

// actions
const actions = {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },

  async auth(context, payload) {
    let url = `${process.env.VUE_APP_API_URL}/qrgame/users/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    if (result.token) {
      cookie.set("token", result.token, new Date(result.tokenExp * 1000));
      cookie.set("userId", result.user._id, new Date(result.tokenExp * 1000));
      cookie.set(
        "username",
        result.user.username,
        new Date(result.tokenExp * 1000)
      );
      cookie.set("email", result.user.email, new Date(result.tokenExp * 1000));

      context.commit("setUser", {
        token: result.token,
        userId: result.user._id,
      });
    }

    Toast.open({
      message: result.message,
      type: "is-primary",
    });
  },
  async tryLogin(context) {
    if (cookie.isKey("token")) {
      const token = cookie.get("token");
      const userId = cookie.get("userId");
      const username = cookie.get("username");
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/token/refresh`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const result = await response.json();
      cookie.set("token", result.token, new Date(result.tokenExp * 1000));
      cookie.set("userId", userId, new Date(result.tokenExp * 1000));
      cookie.set("username", username, new Date(result.tokenExp * 1000));

      context.commit("setUser", {
        token: result.token,
        userId: userId,
      });
    }
  },
  logout(context) {
    cookie.remove("token");
    cookie.remove("userId");
    cookie.remove("username");
    cookie.remove("email");
    context.commit("setUser", {
      token: null,
      userId: null,
    });
  },
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.token = payload.token;
    state.userId = payload.userId;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
