var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{ref:"mapRef",staticClass:"vue-map-container",staticStyle:{"width":"70%","height":"329px"},attrs:{"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    },"center":_vm.mapCoordinates,"zoom":_vm.zoom,"map-style-id":"roadmap"},on:{"click":_vm.handleMapClick}},[_c('gmap-marker',{attrs:{"position":_vm.qrCodeCoordinates,"draggable":true,"clickable":true},on:{"drag":_vm.handleMapClick,"click":_vm.panToMarker}})],1),_c('div',[(!_vm.hasPositionChanged)?_c('p',{staticClass:"required-message"},[_vm._v("Carrega no mapa para indicar onde estará o código QR")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }