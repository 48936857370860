import { render, staticRenderFns } from "./GameDetails.vue?vue&type=template&id=440b059e&scoped=true&"
import script from "./GameDetails.vue?vue&type=script&lang=js&"
export * from "./GameDetails.vue?vue&type=script&lang=js&"
import style0 from "./GameDetails.vue?vue&type=style&index=0&id=440b059e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440b059e",
  null
  
)

export default component.exports