<template>
	<div>
		<b-field v-if="photoText.trim().length=== 0" label="Local a fotografar" type="is-danger" message="É obrigatório adicionar um local para fotografar">
			<b-input 
				v-model="photoText" class="inputDefault"></b-input
			>
		</b-field>
    <b-field v-else label="Local a fotografar">
			<b-input 
				v-model="photoText" class="inputDefault"></b-input
			>
		</b-field>
	</div>
</template>
<script>
import addR from '@iconify-icons/gg/add-r';
import bxTrash from '@iconify-icons/bx/bx-trash';
export default {
	emits: ['clicked'],
	props: ['photoText'],
	data() {
		return {
			inactiveNavigation: false,
			index: 0,
			icons: {
				add: addR,
				remove: bxTrash,
			},
		};
	},
	watch: {
		photoText() {
			this.sendData();
		},
	},

	methods: {
		sendData() {
			this.$emit('photoInfo', {
				text: this.photoText,
			});
		},
		nextStep() {
			if (!this.inactiveNavigation) {
				this.$emit('clicked', 2);
			}
		},
		previousStep() {
			this.$emit('clicked', 0);
		},
	},
};
</script>

<style scoped lang="scss">
@import '../../styles/_game-hint-option';
</style>
