<template>
	<div>
		<header-mobile :route="true" title="" />
		<!--  <header-mobile :route="true" title="Criar um jogo" /> -->
		<section id="create-game">
			<game-form v-if="activeStep === 0" @clicked="onStepClick"></game-form>

			<game-steps
				v-if="activeStep === 1"
				:mapCenter="mapCenter"
				:gameDetails="gameDetails"
				:formatedDetails="formatedDetails"
				:currentStep="currentStep"
				:currentStepOption="currentStepOption"
				:inactiveNavigation="inactiveNavigation"
				@clicked="onStepClick"
				keep-alive
			></game-steps>

			<game-overview
				v-if="activeStep === 2"
				:gameDetails="gameDetails"
				:formatedDetails="formatedDetails"
				:formatedData="formatedGame"
				@clicked="onStepClick"
			></game-overview>
		</section>
	</div>
</template>

<script>
import GameForm from './GameForm.vue';
import GameOverview from './GameOverview.vue';
import GameSteps from './GameSteps.vue';
import HeaderMobile from '../../components/layout/header-mobile';
//import HeaderMobile from "../../components/layout/header-mobile";

export default {
	components: {
		GameForm,
		GameOverview,
		GameSteps,
		HeaderMobile,
	},
	data() {
		return {
			gameDetails: null,
			currentStepOption: null,
			formatedGame: null,
			mapCenter: null,
			activeStep: 0,
			currentStep: null,
			inactiveNavigation: null,
			isAnimated: true,
			isRounded: true,
			isStepsClickable: false,
			hasNavigation: false,
			prevIcon: 'chevron-left',
			nextIcon: 'chevron-right',
			labelPosition: 'bottom',
			mobileMode: 'compact',
			isNextStepAvailable: false,
		};
	},
	methods: {
		onStepClick(value) {
			this.activeStep = value.step;
			this.gameDetails = value.gameDetails;
			this.currentStepOption = value.gameDetails.steps[0].type.value;
			this.invalidStepsCounter = 0;

			value.gameDetails.steps.map((step) => {
				if (!step.isStepValid) {
					this.invalidStepsCounter++;
				}
			});

			if (this.invalidStepsCounter > 0) {
				this.inactiveNavigation = true;
			} else {
				this.inactiveNavigation = false;
			}
			this.formatedGame = value.formatedGame;

			if (value.step === 1) {
				this.mapCenter = value.mapCenter;
				this.gameDetails.steps.filter((step, index) => {
					// remove selected state from the previous selected step
					if (step.selected && index !== 0) {
						step.selected = false;
					}
					// add selected state to the new selected step
					if (index === 0) {
						step.selected = true;
					}
				});
				this.formatedDetails = value.formatedDetails;
			}

			if (value.step === 2) {
				this.formatedDetails = value.formatedDetails[0];
			}
			this.currentStep = 0;
		},
	},
};
</script>

<style lang="scss">
@import '../../styles/_create-game';
</style>
