const DB_NAME = 'gameprogress';
const DB_VERSION = 1;
let DB;

export default {
	async getDb() {
		return new Promise((resolve, reject) => {
			if (DB) {
				return resolve(DB);
			}
			let request = window.indexedDB.open(DB_NAME, DB_VERSION);

			request.onerror = (e) => {
				reject('Error', e);
			};

			request.onsuccess = (e) => {
				DB = e.target.result;
				resolve(DB);
			};

			request.onupgradeneeded = (e) => {
				let db = e.target.result;

				db.createObjectStore('gameprogress', { autoIncrement: true, keyPath: 'id' });
			};
		});
	},

	async getProgressDataById(id) {
		let db = await this.getDb();
		return new Promise((resolve) => {
			let trans = db.transaction(['gameprogress'], 'readwrite');
			trans.oncomplete = () => {
				resolve(gameprogress);
			};

			let store = trans.objectStore('gameprogress');
			let gameprogress;

			store.openCursor().onsuccess = (e) => {
				let cursor = e.target.result;
			
				if (cursor !== null) {
					if (cursor.key === id) {
						gameprogress = cursor.value;
					} else {
						cursor.continue();
					}
				}
			};
		});
	},

	async deleteProgressData(game) {
		let db = await this.getDb();

		return new Promise((resolve) => {
			let trans = db.transaction(['gameprogress'], 'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('gameprogress');
			store.delete(game.id);
		});
	},

	async getProgress() {
		let db = await this.getDb();

		return new Promise((resolve) => {
			let trans = db.transaction(['gameprogress'], 'readonly');
			trans.oncomplete = () => {
				resolve(gameprogress);
			};

			let store = trans.objectStore('gameprogress');
			let gameprogress = [];

			store.openCursor().onsuccess = (e) => {
				let cursor = e.target.result;
				if (cursor) {
					gameprogress.push(cursor.value);
					cursor.continue();
				}
			};
		});
	},

	async saveProgress(game) {
		let db = await this.getDb();

		return new Promise((resolve) => {
			let trans = db.transaction(['gameprogress'], 'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('gameprogress');
			store.put(game);
		});
	},
};
