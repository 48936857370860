<template>
  <div class="columns is-mobile">
    <div class="column is-full-mobile">
      <div class="container">
        <div class="image-container">
          <img :src="`${publicPath}home_logo.png`" alt="QRGame" />
        </div>
        <div class="header"><span class="is-header">Iniciar Sessão</span></div>

        <b-field label="Email/ Username">
          <b-input
            placeholder="Email ou Username"
            class="input-default"
            v-model="user"
            :class="{ 'error-text': errors.has('name') }"
            v-validate="'required'"
            name="user"
          ></b-input>
        </b-field>
        <p v-show="errors.has('user')" class="error-info">
          Introduza o seu email ou username!
        </p>
        <b-field label="Password">
          <b-input
            type="password"
            password-reveal
            placeholder="Password"
            class="input-default"
            v-model="password"
            :class="{ 'error-text': errors.has('name') }"
            v-validate="'required'"
            name="password"
          >
          </b-input>
        </b-field>
        <p v-show="errors.has('password')" class="error-info">
          Introduza a sua password!
        </p>

        <b-field class="reset-password"
          ><span @click="isComponentModalActive = true"
            >Recuperar Password</span
          ></b-field
        >

        <b-field>
          <b-button @click="login()" type="is-login">Iniciar Sessão</b-button>
        </b-field>
        <div class="socials">
          <hr class="line" />
          <span class="socials-text">Ou continuar com</span>
        </div>
        <div class="socials-container">
          <div class="social-icon">
            <a href="https://qrgame.iotechpis.com/qrgame/users/auth/google"
              ><google></google
            ></a>
          </div>
          <div class="social-icon">
            <a href="https://qrgame.iotechpis.com/qrgame/users/auth/facebook/"
              ><facebook></facebook
            ></a>
          </div>
        </div>
        <div class="login-text">
          <span>Ainda não tem uma conta? </span
          ><router-link to="/signup">
            <span id="signup">Registe-se</span>
          </router-link>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isComponentModalActive"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      :has-modal-card="true"
    >
      <template>
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <modal-reset @closeModal="closeModal"></modal-reset>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Google from "../../assets/icons/google-mobile-login";
import Facebook from "../../assets/icons/facebook-mobile-login";
import modalReset from "../../components/Auth/ResetPasswordModal";
export default {
  components: {
    Google,
    Facebook,
    modalReset,
  },
  data() {
    return {
      user: "",
      password: "",
      isComponentModalActive: false,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    async login() {
      this.$validator.validateAll().then(async (valid) => {
        if (valid) {
          try {
            await this.$store.dispatch("login", {
              username: this.user,
              email: this.user,
              password: this.password,
            });
            this.$router.push("/home");
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    socialLogin() {
      if (
        this.$route.query.token &&
        this.$route.query.tokenExp &&
        this.$route.query.userId &&
        this.$route.query.username
      ) {
        this.$cookies.set(
          "token",
          this.$route.query.token,
          new Date(this.$route.query.tokenExp * 1000)
        );
        this.$cookies.set(
          "userId",
          this.$route.query.userId,
          new Date(this.$route.query.tokenExp * 1000)
        );
        this.$cookies.set(
          "username",
          this.$route.query.username,
          new Date(this.$route.query.tokenExp * 1000)
        );

        this.$store.commit("setUser", {
          token: this.$route.query.token,
          userId: this.$route.query.userId,
        });

        this.$router.push("/home");
      }
    },
    closeModal(value) {
      this.isComponentModalActive = value;
    },
     scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.socialLogin();
    this.scrollToTop()
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_mobileLogin";
</style>
