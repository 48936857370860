<template>
  <div class="columns is-mobile">
    <div class="column is-full-mobile">
      <div class="container">
        <div class="image-container">
          <img :src="`${publicPath}home_logo.png`" alt="QRGame" />
        </div>
        <div class="header"><span class="is-header">Criar Conta</span></div>

        <div class="inline">
          <b-field label="Nome">
            <b-input
              placeholder="Nome"
              class="input-default"
              v-model="name"
              :class="{ 'error-text': errors.has('name') }"
              v-validate="'required'"
              name="name"
            ></b-input>
          </b-field>
          <p v-show="errors.has('name')" class="error-info">
            Introduza o seu nome!
          </p>
        </div>

        <div id="username" class="inline">
          <b-field label="Username">
            <b-input
              placeholder="Username"
              class="input-default"
              v-model="username"
              :class="{ 'error-text': errors.has('username') }"
              v-validate="'required'"
              name="username"
            ></b-input
            ><br />
          </b-field>
          <p v-show="errors.has('username')" class="error-info">
            Introduza o seu username!
          </p>
        </div>

        <b-field class="login-label" label="Email">
          <b-input
            placeholder="Email"
            class="input-default"
            v-model="email"
            :class="{ 'error-text': errors.has('email') }"
            v-validate="'email|required'"
            name="email"
          >
          </b-input>
        </b-field>
        <p v-show="errors.has('email')" class="error-info">
          Introduza um endereço de email válido!
        </p>

        <b-field label="Password">
          <b-input
            ref="password"
            type="password"
            password-reveal
            placeholder="Password"
            class="input-default"
            v-model="password"
            :class="{ 'error-text': errors.has('password') }"
            v-validate="'required|min:8'"
            name="password"
          >
          </b-input>
        </b-field>
        <ul>
          <li
            v-for="error in errors.collect('password')"
            :key="error"
            class="error-info"
          >
            {{ error }} <span v-if="error[2] == 't'">caracteres!</span>
          </li>
        </ul>

        <b-field label="Confirmar Password">
          <b-input
            type="password"
            password-reveal
            placeholder="Password"
            class="input-default"
            v-model="repeatPassword"
            :class="{ 'error-text': errors.has('repeatPassword') }"
            v-validate="'required|confirmed:password'"
            name="repeatPassword"
          >
          </b-input>
        </b-field>
        <p v-show="errors.has('repeatPassword')" class="error-info">
          As passwords não coincidem!
        </p>

        <b-field>
          <b-checkbox
            v-model="terms"
            :class="{ 'error-text': errors.has('terms') }"
            v-validate="'required'"
            name="terms"
            type="checkbox"
            ><span class="terms"
              >Li e concordo com os Termos de Serviço e as Políticas de
              Privacidade.</span
            >
          </b-checkbox>
        </b-field>
        <p v-show="this.terms === false" class="error-info">
          Deve aceitar os termos e condições!
        </p>

        <b-field>
          <b-button @click="register()" type="is-login">Registar</b-button>
        </b-field>
        <div class="socials">
          <hr class="line" />
          <span class="socials-text">Ou continuar com</span>
        </div>
        <div class="socials-container">
          <div class="social-icon"><google></google></div>
          <div class="social-icon"><facebook></facebook></div>
        </div>
        <div class="login-text">
          <span>Já tem uma conta? </span
          ><router-link to="/auth">
            <span id="signin">Iniciar Sessão</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Google from "../../assets/icons/google-mobile-login";
import Facebook from "../../assets/icons/facebook-mobile-login";
import apiAuth from "../../api/auth";

export default {
  components: {
    Google,
    Facebook,
  },
  data() {
    return {
      name: "",
      username: "",
      email: "",
      password: "",
      repeatPassword: "",
      terms: null,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    async register() {
      this.$validator.validateAll().then(async (valid) => {
        if (!this.terms) {
          this.terms = false;
        }
        if (valid) {
          let data = {
            name: this.name,
            username: this.username,
            email: this.email,
            password: this.password,
          };
          apiAuth
            .register(data)
            .then((response) => {
              console.log(response);
              this.$buefy.toast.open({
                message: response[1].message,
                type: "is-primary",
              });
              if (response[0].status !== 401) {
                this.loginAfterResgister();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
    async loginAfterResgister() {
      try {
        if (this.username !== "" && this.email !== "" && this.password !== "") {
          await this.$store.dispatch("login", {
            username: this.username,
            email: this.email,
            password: this.password,
          });
          this.$router.push("/home");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async login() {
      try {
        await this.$store.dispatch("login", {
          username: this.username,
          email: this.email,
          password: this.password,
        });
      } catch (error) {
        console.error(error);
      }
    },
     scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created(){
    this.scrollToTop()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_mobileSignup";
</style>
