<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <header>O que achaste deste jogo?</header>
      <div class="star-container">
        <b-rate v-model="rating" size="is-medium" @change="feedback"></b-rate>
      </div>
      <div class="send-feedback">
        <b-button class="is-primary" @click="sendRating"
          >Enviar feedback</b-button
        ><br />
      </div>
      <div @click="closeModal()" class="not-now"><span>Agora não</span></div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rating: null,
      userFeedback: 0,
    };
  },

  methods: {
    sendRating() {
      this.$emit("sendRating", this.userFeedback);
    },
    closeModal() {
      this.$emit("closeModal", false);
      this.$emit("sendRating", null);
    },
    feedback(value) {
      this.userFeedback = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_gameFeedBackModal";
</style>
