<template>
  <div>
    <div v-if="size <= 768">
      <mobile-login></mobile-login>
    </div>
    <div v-else class="auth-container">
      <desktop-login></desktop-login>
    </div>
  </div>
</template>

<script>
import DesktopLogin from "../../components/Auth/DesktopLogin";
import MobileLogin from "../../components/Auth/MobileLogin";

export default {
  components: {
    DesktopLogin,
    MobileLogin,
  },
  data() {
    return {
      size: 0,
    };
  },
  methods: {
    getWindowWidth() {
      this.size = window.innerWidth;
     
    },
  },
  mounted() {
    window.addEventListener("resize", this.getWindowWidth);
    this.getWindowWidth();
 
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  display: flex;
  justify-content: center;
}
</style>
