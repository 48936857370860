const DB_NAME = "gamesdb";
const DB_VERSION = 1;
let DB;

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        reject("Error", e);
      };

      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onupgradeneeded = (e) => {
        let db = e.target.result;
        
        db.createObjectStore("games", { autoIncrement: true, keyPath: "id" });
      };
    });
  },

  async getGameById(id) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["games"], "readwrite");
      trans.oncomplete = () => {
        resolve(games);
      };

      let store = trans.objectStore("games");
      let games ;

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;  
        if(typeof id === 'string'){
          if (cursor.value._id === id) { 
            games= cursor.value
          }
          else { 
            cursor.continue();
          }
        } else {
          if (cursor.value.id === id) { 
            games= cursor.value
          }
          else { 
            cursor.continue();
          }
        }
        
      };
    });
  },

  async deleteGame(game) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["games"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("games");
      store.delete(game.id);
    });
  },

  async getGames() {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["games"], "readonly");
      trans.oncomplete = () => {
        resolve(games);
      };

      let store = trans.objectStore("games");
      let games = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          games.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async saveGame(game) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["games"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("games"); 
      store.put(game);
    });
  },
};
