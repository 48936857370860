<template>
  <div>
    <ul>
      <li
        class="tips-container"
        v-for="(tips, index) in unlockedTips"
        :key="index"
      >
        <span class="unlocked-tip"
          ><light class="light" /> {{ tips.text }}</span
        >
      </li>
    </ul>
    <div class="tips-container" v-if="!noTips">
      <span class="tip-header">Dicas</span>
      <div class="tips-list">
        <ul>
          <li v-for="(tips, index) in game" :key="index">
            <span class="list-span"
              >Desbloquear por <coins class="coins" /> {{ tips.value }}
              <button
                v-if="userCredits >= tips.value"
                class="unlock-button"
                @click="unlock(index, tips.value)"
              >
                Desbloquear
              </button>
              <button class="unlock-button" disabled v-else>Sem créditos</button>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="empty-tips-container">
      <span class="empty-tips"> Não existem mais dicas para esta pista</span>
    </div>
  </div>
</template>

<script>
import coins from "../../assets/icons/credit";
import light from "../../assets/icons/light";
export default {
  components: {
    coins,
    light,
  },
  props: ["game", "userCredits"],
  data() {
    return {
      unlockedTips: [],
      noTips: true,
    };
  },
  created() {

    if (this.game.length > 0) {
      if (this.game[0].text.length < 1) {
        this.noTips = true;
      } else {
        this.noTips = false;
      }
    } else {
      this.noTips = true;
    }
  },
  watch: {
    game: function(newGame, oldGame) {
  
      if (newGame.length > 0) {
        if (newGame[0].text.length < 1) {
          this.noTips = true;
        } else {
          this.noTips = false;
        }
      } else {
        this.noTips = true;
      }
      if (newGame !== oldGame) {
        this.unlockedTips = [];
      }
    },
  },

  methods: {
    unlock(index, credits) {
      this.unlockedTips.push(this.game[index]);
      this.game.splice(index, 1);
      this.$emit("unlockTip", credits);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_gameTips";
</style>
