import store from "../../store";
import router from "../../router";
export function createGame() {
  let newGame = {
    creator: {
      name: window.$cookies.get("username"),
      id: window.$cookies.get("userId"),
    },
    name: "",
    id: null,
    description: "",
    gameMode: {
      gameType: "",
      playersType: "",
      timeControl: {
        value: false,
        time: null,
      },
    },
    playersLimit: 0,
    location: "",
    dificulty: 0,
    gameTypesButtons: [
      {
        id: 0,
        isActive: false,
        text: "Jogo Público",
        value: "public",
      },
      {
        id: 1,
        isActive: false,
        text: "Jogo Privado",
        value: "private",
      },
    ],
    playersTypeButtons: [
      {
        id: 0,
        isActive: false,
        text: "Individual",
        value: "individual",
      },
      {
        id: 1,
        isActive: false,
        text: "Equipa",
        value: "group",
      },
      {
        id: 2,
        isActive: false,
        text: "Ambos",
        value: "both",
      },
    ],
    dificulttyTypeButtons: [
      {
        id: 0,
        isActive: false,
        text: "Fácil",
        value: 1,
      },
      {
        id: 1,
        isActive: false,
        text: "Médio",
        value: 3,
      },
      {
        id: 2,
        isActive: false,
        text: "Difícil",
        value: 4,
      },
    ],
    tags: [],
    categories: [],
    suggestedCategories: [],
    invalidStepsCounter: 0,
    mapCenter: {
      lat: null,
      lng: null,
    },
    steps: [
      {
        id: 1,
        type: { text: "Pista", value: "hint" },
        stepCode: null,
        selected: true,
        isStepValid: false,
        estimatedDuration: {
          duration: null,
          formatedDuration: null,
        },
        numberOfCreditsToWin: 5,
        numberOfCreditsToLose: 5,
        qrCodeCoordinates: {
          coordinates: {
            lat: null,
            lng: null,
          },
          hasPositionChanged: false,
        },

        question: {
          question: "",
          answers: [
            {
              optionText: "",
              rightOption: true,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
          ],
        },
        hint: {
          text: "",
          tips: [
            {
              text: "",
              value: 20,
            },
          ],
        },
        photo: {
          text: "",
        },
      },
      {
        id: 2,
        type: { text: "Pista", value: "hint" },
        stepCode: null,
        selected: true,
        isStepValid: false,
        estimatedDuration: {
          duration: null,
          formatedDuration: null,
        },
        numberOfCreditsToWin: 5,
        numberOfCreditsToLose: 5,
        qrCodeCoordinates: {
          coordinates: {
            lat: null,
            lng: null,
          },
          hasPositionChanged: false,
        },

        question: {
          question: "",
          answers: [
            {
              optionText: "",
              rightOption: true,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
          ],
        },
        hint: {
          text: "",
          tips: [
            {
              text: "",
              value: 20,
            },
          ],
        },
        photo: {
          text: "",
        },
      },
      {
        id: 3,
        type: { text: "Pista", value: "hint" },
        stepCode: null,
        selected: true,
        isStepValid: false,
        estimatedDuration: {
          duration: null,
          formatedDuration: null,
        },
        numberOfCreditsToWin: 5,
        numberOfCreditsToLose: 5,
        qrCodeCoordinates: {
          coordinates: {
            lat: null,
            lng: null,
          },
          hasPositionChanged: false,
        },

        question: {
          question: "",
          answers: [
            {
              optionText: "",
              rightOption: true,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
          ],
        },
        hint: {
          text: "",
          tips: [
            {
              text: "",
              value: 20,
            },
          ],
        },
        photo: {
          text: "",
        },
      },
      {
        id: 4,
        type: { text: "Pista", value: "hint" },
        stepCode: null,
        selected: true,
        isStepValid: false,
        estimatedDuration: {
          duration: null,
          formatedDuration: null,
        },
        numberOfCreditsToWin: 5,
        numberOfCreditsToLose: 5,
        qrCodeCoordinates: {
          coordinates: {
            lat: null,
            lng: null,
          },
          hasPositionChanged: false,
        },

        question: {
          question: "",
          answers: [
            {
              optionText: "",
              rightOption: true,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
          ],
        },
        hint: {
          text: "",
          tips: [
            {
              text: "",
              value: 20,
            },
          ],
        },
        photo: {
          text: "",
        },
      },
      {
        id: 5,
        type: { text: "Pista", value: "hint" },
        stepCode: null,
        selected: true,
        isStepValid: false,
        estimatedDuration: {
          duration: null,
          formatedDuration: null,
        },
        numberOfCreditsToWin: 5,
        numberOfCreditsToLose: 5,
        qrCodeCoordinates: {
          coordinates: {
            lat: null,
            lng: null,
          },
          hasPositionChanged: false,
        },

        question: {
          question: "",
          answers: [
            {
              optionText: "",
              rightOption: true,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
            {
              optionText: "",
              rightOption: false,
            },
          ],
        },
        hint: {
          text: "",
          tips: [
            {
              text: "",
              value: 20,
            },
          ],
        },
        photo: {
          text: "",
        },
      },
    ],
  };

  if (store.state.games.length > 0) {
    newGame.id = store.state.games[store.state.games.length - 1].id + 1;
  } else {
    newGame.id = 0;
  }
  store.dispatch("saveGame", newGame);
  router.push({ path: `create/${newGame.id}` });
}
