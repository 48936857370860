const auth = {
  register: async (data) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/register`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );
    const responseData = await response.json();
    return [response, responseData];
  },

  resetPassword: async (data, token) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/password/recover`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "same-origin",
      }
    );
    const responseData = await response.json();
    return [response, responseData];
  },

  sendEmail: async (email) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/email/password/?email=${email}`
    );
    const responseData = await response.json();
    return [response, responseData];
  },
};

export default auth;
