<template>
	<div class="fulscreen">
		<qrcode-stream @init="onInit" @detect="onDetect" v-if="!destroyed">
			<b-loading :is-full-page="isFullPage" v-model="loading"></b-loading>

			<div class="close-icon" v-if="!loading" @click="close">
				<icon-close />
			</div>
			<div class="info-message" v-if="!loading">
				<p class="message-content">
					{{ message }}
				</p>
			</div>
		</qrcode-stream>
	</div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import IconClose from '../assets/icons/icon-close';
import GroupRequests from '../api/GroupRequests';
//const io = require('socket.io-client');

export default {
	components: {
		QrcodeStream,
		IconClose,
	},

	data() {
		return {
			loading: false,
			isFullPage: true,
			destroyed: false,
			//socket: io(`${process.env.VUE_APP_API_URL}`),
			content: null,
			message:
				'Faz scan do código QR para seres adicionado(a) a uma equipa ou entrares num jogo para o qual foste convidado(a).',
		};
	},

	methods: {
		async getGameInfo(gameId) {
			const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/games/${gameId}`);
			let receivedGame = await response.json();
			return receivedGame;
		},
		close() {
			this.$router.go(-1);
		},
		async onDetect(promise) {
			try {
				const {
					content, // decoded String
				} = await promise;
				this.content = JSON.parse(content);
				

				if (this.content.type == 'game') {
					this.$router.push({ path: 'game-info/' + this.content.gameID });
				} else {
					GroupRequests.joinGroup(this.content.groupID, {
						newMember: {
							groupId: this.content.groupID,
							username: this.$cookies.get('username'),
							photo: this.$cookies.get('photo'),
							id: this.$cookies.get('userId'),
						},
					}).then((data) => {
						if (data) {
							this.getGameInfo(this.content.gameID).then((gameData) => {
								this.socket.emit('joinGroup', {
								groupId: this.content.groupID,
								username: this.$cookies.get('username'),
								photo: this.$cookies.get('photo'),
								id: this.$cookies.get('userId'),
							});
								if (gameData.creator.id !== this.$cookies.get('userId')) {
									this.$router.push({ path: '/invite-members/' + this.content.groupID });
								} else {
									this.message = 'Não podes jogar este jogo porque foi criado por ti.';
								}
							});
						}
					});
				}
			} catch (error) {
				console.error(error);
			}
		},
		async onInit(promise) {
			// show loading indicator
			this.loading = true;
			try {
				await promise;

				// successfully initialized
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					// user denied camera access permisson
				} else if (error.name === 'NotFoundError') {
					// no suitable camera device installed
				} else if (error.name === 'NotSupportedError') {
					// page is not served over HTTPS (or localhost)
				} else if (error.name === 'NotReadableError') {
					// maybe camera is already in use
				} else if (error.name === 'OverconstrainedError') {
					// did you requested the front camera although there is none?
				} else if (error.name === 'StreamApiNotSupportedError') {
					// browser seems to be lacking features
				}
			} finally {
				// hide loading indicator
				this.loading = false;
			}
		},
	},
};
</script>

<style>
.fulscreen {
	position: fixed;
	z-index: 1000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.info-message {
	padding: 5%;
	width: calc(100% - 32px);
	margin: 16px;
	bottom: 0;
	position: absolute;
	background-color: #00000066;
	backdrop-filter: blur(20px);
	border-radius: 10px;
}

.message-content {
	color: #fff;
	text-align: -webkit-center;
	font-size: 14px;
}

.close-icon {
	background-color: #00000066;
	backdrop-filter: blur(20px);
	border-radius: 50px;
	padding: 4px;
	width: 32px;
	height: 32px;
	text-align: -webkit-center;
	vertical-align: middle;
	margin: 16px;
}
</style>
