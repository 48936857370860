<template>
  <section>
    <div class="header-container columns is-mobile">
      <div class="icon-left column is-1" v-if="route" @click="goToPage">
        <icon-back />
      </div>
     
     <!--  <h4 v-if="title.length > 1" class="header-title has-text-centered column">
        {{ title }}
      </h4> -->
      <slot class="header-title has-text-centered column"></slot>
       <!--<div class="icon-right column is-1">
        <icon-bookmark v-if="iconRight" />
      </div>-->
    </div>
  </section>
</template>

<script>
import IconBack from "../../assets/icons/icon-back";
//import IconBookmark from "../../assets/icons/bookmark-outline";
export default {
  
  components: {
    IconBack,
   // IconBookmark,
  },
  props: ["route", "title", "icon-right"],
  methods: {
    goToPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.header-container {
  width: 100%;
  height: 74px;
  text-align: -webkit-center;
  position: fixed;
  top: 0;
  padding: 10px;
  padding-top: 24px;
  background-color: #f9f9fb;
  z-index: 10;
  display: inline-block;
  align-items: center;
      left: 0;
}
.icon-left {
  float: left; 
  margin-left: 0px;
  z-index: 10;
}

.icon-right {
  float: right;
  margin: 16px;
  z-index: 10;
}
.header-container .header-title {
  font-size: 18px;
  color: #3158a3;
  font-weight: 500;
  align-self: center;
}



@media screen and (max-width: 768) {
  .icon-right {
    
    margin: 30px;
   
  }
}

</style>
