<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <header>
        Tem a certeza que pretende desistir do jogo? Não irá aparecer no ranking
        de jogadores deste jogo.
      </header>

      <div class="send-feedback">
        <b-button @click="leaveGame()">Sim</b-button>
        <b-button class="danger" @click="closeModal()">Não</b-button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },

    leaveGame() {
      this.$emit("leaveGame", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_leaveGameModal";
</style>
