import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faThLarge, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFontAwesome, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGeolocation from 'vue-browser-geolocation';
import BaseCard from './components/layout/BaseCard.vue'
import DifficultyCard from './components/layout/DifficultyCard.vue'
import GameModeCard from './components/layout/GameModeCard.vue'
import VeeValidate, { Validator } from 'vee-validate';
import attributesPt from 'vee-validate/dist/locale/pt_BR'
import VueCookies from 'vue-cookies'
import VueCrontab from 'vue-crontab'
import VueApexCharts from  'vue-apexcharts'



library.add(faFontAwesome, faFacebookF, faUserSecret, faThLarge, faChevronLeft, faChevronRight)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GM_KEY,
    libraries: "places"
  },
}); 

Vue.use(VueGeolocation);
Validator.localize('pt_BR', attributesPt);
Vue.use(VeeValidate, {
  locale: 'pt_BR',
});
Vue.use(VueCookies)


Vue.use(VueCrontab)


Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('base-card', BaseCard)
Vue.component('difficulty-card', DifficultyCard)
Vue.component('game-mode-card', GameModeCard)
Vue.component('apexchart', VueApexCharts)

Vue.use(Buefy)

Vue.config.productionTip = false

new Vue({ 
  router,
  store,
  render: h => h(App)
}).$mount('#app')

