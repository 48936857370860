<template>
  <div class="columns">
    <div class="is-full-desktop">
      <div class="container">
        <div class="image-container">
          <img :src="`${publicPath}home_logo.png`" alt="QRGame" />
        </div>
        <b-tabs type="is-boxed" animation="none">
          <b-tab-item>
            <template #header>
              <span class="is-header">Entrar</span>
            </template>

            <b-field label="Email/ Username">
              <b-input
                placeholder="Email ou Username"
                class="input-default"
                v-model="loginUser"
              ></b-input>
            </b-field>

            <div style="font-weight:100">
              <b-field class="login-label" label="Password">
                <b-input
                  type="password"
                  password-reveal
                  placeholder="Password"
                  class="input-default"
                  v-model="loginPassword"
                >
                </b-input>
              </b-field>
              <b-field class="reset-password"
                ><span @click="isComponentModalActive = true"
                  >Recuperar Password</span
                ></b-field
              >
            </div>
            <b-field>
              <b-button @click="login()" type="is-login"
                >Iniciar Sessão</b-button
              >
            </b-field>
            <p v-show="loginError" class="login-message error-info">
              Deve preencher os campos!
            </p>
            <div class="continue">
              <hr class="line" />
              <span>Ou continuar com</span>
            </div>
          </b-tab-item>

          <b-tab-item>
            <template #header>
              <span class="is-header"> Criar Conta</span>
            </template>

            <div class="inline">
              <b-field class="login-label" label="Nome">
                <b-input
                  placeholder="Nome"
                  class="register"
                  v-model="registerName"
                  :class="{ 'error-text': errors.has('name') }"
                  v-validate="'required'"
                  name="name"
                ></b-input>
              </b-field>
              <p v-show="errors.has('name')" class="error-info">
                Introduza o seu nome!
              </p>
            </div>

            <div id="username" class="inline">
              <b-field class="login-label" label="Username">
                <b-input
                  placeholder="Username"
                  class="register"
                  v-model="registerUsername"
                  :class="{ 'error-text': errors.has('username') }"
                  v-validate="'required'"
                  name="username"
                ></b-input>
              </b-field>
              <p v-show="errors.has('username')" class="error-info">
                Introduza o seu username!
              </p>
            </div>

            <div class="register-input-container">
              <b-field class="login-label" label="Email">
                <b-input
                  placeholder="Email"
                  class="input-default"
                  v-model="registerEmail"
                  :class="{ 'error-text': errors.has('email') }"
                  v-validate="'email|required'"
                  name="email"
                >
                </b-input>
              </b-field>
              <p v-show="errors.has('email')" class="error-info">
                Introduza um endereço de email válido!
              </p>
              <b-field class="login-label" label="Password">
                <b-input
                  ref="password"
                  type="password"
                  password-reveal
                  placeholder="Password"
                  class="input-default"
                  v-model="registerPassword"
                  :class="{ 'error-text': errors.has('password') }"
                  v-validate="'required|min:8'"
                  name="password"
                >
                </b-input>
              </b-field>
              <ul>
                <li
                  v-for="error in errors.collect('password')"
                  :key="error"
                  class="error-info"
                >
                  {{ error }} <span v-if="error[2] == 't'">caracteres!</span>
                </li>
              </ul>
              <b-field class="login-label" label="Repetir Password">
                <b-input
                  type="password"
                  password-reveal
                  placeholder="Repetir Password"
                  class="input-default"
                  v-model="registerRepeatPassword"
                  :class="{ 'error-text': errors.has('repeatPassword') }"
                  v-validate="'required|confirmed:password'"
                  name="repeatPassword"
                >
                </b-input>
              </b-field>
              <p v-show="errors.has('repeatPassword')" class="error-info">
                As passwords não coincidem!
              </p>
            </div>
            <b-field>
              <b-checkbox
                type="checkbox"
                :class="{ 'error-text': errors.has('terms') }"
                v-validate="'required'"
                name="terms"
                v-model="terms"
                ><span class="terms"
                  >Li e concordo com os Termos de Serviço e as Políticas de
                  Privacidade.</span
                >
              </b-checkbox>
            </b-field>
            <p v-show="this.terms === false" class="error-info">
              Deve aceitar os termos e condições!
            </p>
            <b-field>
              <b-button @click="register" type="is-login">Criar Conta</b-button>
            </b-field>
            <div>
              <hr class="line" />
              <span>Ou continuar com</span>
            </div>
          </b-tab-item>
        </b-tabs>
        <div class="social-container">
          <b-field style="display:inline">
            <a href="https://qrgame.iotechpis.com/qrgame/users/auth/google">
              <b-button type="is-google">
                <div class="google-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="20px"
                    height="21px"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                  <span class="google">Google</span>
                </div>
              </b-button></a
            >
          </b-field>

          <b-field style="display:inline">
            <a href="https://qrgame.iotechpis.com/qrgame/users/auth/facebook/">
              <b-button type="is-facebook"
                ><font-awesome-icon
                  :icon="['fab', 'facebook-f']"
                />Facebook</b-button
              ></a
            >
          </b-field>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isComponentModalActive"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      :has-modal-card="true"
    >
      <template>
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <modal-reset @closeModal="closeModal"></modal-reset>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import modalReset from "../../components/Auth/ResetPasswordModal";
import apiAuth from "../../api/auth";

export default {
  components: {
    modalReset,
  },
  data() {
    return {
      loginUser: "",
      loginPassword: "",
      registerName: "",
      registerUsername: "",
      registerEmail: "",
      registerPassword: "",
      registerRepeatPassword: "",
      token: "",
      isComponentModalActive: false,
      terms: null,
      loginError: false,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    async register() {
      this.$validator.validateAll().then(async (valid) => {
        if (!this.terms) {
          this.terms = false;
        }
        if (valid && this.terms) {
          let data = {
            name: this.registerName,
            username: this.registerUsername,
            email: this.registerEmail,
            password: this.registerPassword,
          };
          apiAuth
            .register(data)
            .then((response) => {
              this.$buefy.toast.open({
                message: response[1].message,
                type: "is-primary",
              });
              if (response[0].status !== 401) {
                this.loginAfterResgister();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },

    async login() {
      try {
        if (this.loginUser !== "" && this.loginPassword !== "") {
          await this.$store.dispatch("login", {
            username: this.loginUser,
            email: this.loginUser,
            password: this.loginPassword,
          });
          this.loginError = false;
          this.$router.push("/home");
        } else {
          this.loginError = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loginAfterResgister() {
      try {
        if (
          this.registerUsername !== "" &&
          this.registerEmail !== "" &&
          this.registerPassword !== ""
        ) {
          await this.$store.dispatch("login", {
            username: this.registerUsername,
            email: this.registerEmail,
            password: this.registerPassword,
          });
          this.$router.push("/home");
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeModal(value) {
      this.isComponentModalActive = value;
    },
    socialLogin() {
      if (
        this.$route.query.token &&
        this.$route.query.tokenExp &&
        this.$route.query.userId &&
        this.$route.query.username
      ) {
        this.$cookies.set(
          "token",
          this.$route.query.token,
          new Date(this.$route.query.tokenExp * 1000)
        );
        this.$cookies.set(
          "userId",
          this.$route.query.userId,
          new Date(this.$route.query.tokenExp * 1000)
        );
        this.$cookies.set(
          "username",
          this.$route.query.username,
          new Date(this.$route.query.tokenExp * 1000)
        );

        this.$store.commit("setUser", {
          token: this.$route.query.token,
          userId: this.$route.query.userId,
        });

        this.$router.push("/home");
      }
    },
  },
  created() {
    this.socialLogin();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_desktopAuth";
</style>
