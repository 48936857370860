import Vue from "vue";
import VueRouter from "vue-router";
import UserGames from "../pages/UserGames";
import CreateGame from "../pages/CreateNewGame/CreateGame";
import Search from "../pages/Search/Search";
import BestQualified from "../pages/Search/BestQualified";
import PopularGames from "../pages/Search/PopularGames";
import CreatorRanking from "../pages/Search/CreatorRanking";
import SavedGames from "../pages/SavedGames/SavedGames";
import MonitorGame from "../pages/MonitorGame";
import InviteToTeam from "../components/PlayGame/InviteToTeam";
import ReadQRCode from "../pages/ReadQRCode";
import GameInfo from "../pages/GameDetails/GameDetails";
import Auth from "../pages/Authentication/Auth";
import MobileSignup from "../components/Auth/MobileSignup";
import ResetPassword from "../components/Auth/ResetPassword";
import Profile from "../pages/Profile/Profile";
import AddedToTeam from "../pages/AddedToTeam";
import PlayGame from '../pages/PlayGame/StepDetails'
import cookie from "vue-cookies";



Vue.use(VueRouter);

const isAuthenticated = () => {
  if (cookie.get("token")) {

    return true;
  } else {

    return false;
  }
};

const routes = [
  { path: "/", redirect: "/auth" },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: MobileSignup,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: UserGames,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/added-to-team",
    name: "added-to-team",
    component: AddedToTeam,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update/:id",
    name: "update",
    component: CreateGame,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create/:id",
    name: "create",
    component: CreateGame,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit/:id",
    name: "edit",
    component: CreateGame,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/play-game",
    name: "play-game",
    component: PlayGame,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/best-qualified",
    name: "best-qualified",
    component: BestQualified,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/most-played",
    name: "most-played",
    component: PopularGames,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/creator-ranking",
    name: "creator-ranking",
    component: CreatorRanking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-games",
    name: "my-games",
    component: UserGames,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/saved-games",
    name: "saved-games",
    component: SavedGames,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/game-info/:id",
    name: "game-info",
    component: GameInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/join-group",
    name: "join-group",
    component: ReadQRCode,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monitor-game/:id",
    name: "monitor-game",
    component: MonitorGame,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/invite-members/:id",
    name: "invite-members",
    component: InviteToTeam,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "*",
    redirect: 'home'
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {

  if (to.meta.requiresAuth && !isAuthenticated()) {
    next("/auth");
  } else if (to.meta.requiresUnauth && isAuthenticated()) {
    next("/home");
  } else next();
});

export default router;
