<template>
  <div class="header header-desktop">
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item"></div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <hr class="header-divider" />
        <figure class="image is-64x64">
          <img
            v-if="
              profile_picture &&
              profile_picture != null
            "
            class="is-rounded"
            :src="profile_picture"
          />
          <i v-else class="mdi mdi-account mdi-48px" />
        </figure>
      </div>
    </nav>
  </div>
</template>

<script>
import apiProfile from "../api/profile";
export default {
  data() {
    return {
      profile_picture: null,
    };
  },
  methods: {
    async getUserInfo() {
      apiProfile
        .getUserInfo(this.userId)
        .then((result) => {
          this.profile_picture = result.user.photo;
          console.log(this.profile_picture);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    mounted() {
    this.getUserInfo();
    console.log(this.profile_picture);
  },
  },
};
</script>

<style lang="scss">
@import "../styles/header-desktop.scss";
</style>
