<template>
	<div class="modal-card">
		<section class="modal-card-body">
			<header class="result-message"><slot></slot> <credit /></header>
			<div class="button-group">
				<b-button @click="$emit('closeMessageModal')" class="is-primary">Ok</b-button>
			</div>
		</section>
	</div>
</template>

<script>
import credit from "../../assets/icons/credit";
export default {
    components:{
        credit
    },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '../../styles/_reportCodeMissingModal';
</style>
