<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <b-upload v-model="file" accept="image/*">
        <span class="info">Editar Fotografia</span>
      </b-upload>
      <br />
      <span class="remove-text" @click="removePhoto">Remover Fotografia</span>
      <br />
      <span class="info" @click="closeModal">Cancelar</span><br />
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  watch: {
    file() {
      this.updatePhoto();
      this.closeModal();
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },

    updatePhoto() {
      this.$emit("updatePhoto", this.file);
    },

    removePhoto() {
      this.$emit("removePhoto", this.file);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_profileModal";
</style>
