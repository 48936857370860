<template>
  <section class="invite-players page-content">
    <header-mobile :route="true" title="" />
    <div class="info-message-container">
      <div class="columns is-mobile">
        <div class="column is-1">
          <icon-info />
        </div>

        <div class="column">
          <p class="info-body">
            Para formar a equipa todos os membros precisam de fazer scan deste
            código.
          </p>
        </div>
      </div>
    </div>
    <div class="members-container">
      <p>Membros</p>
      <div class="members-avatar">
        <div class="fill-required"></div>
        <div class="columns is-variable is-1 is-multiline is-mobile">
          <div v-for="item in members" :key="item.id" class="column is-3">
            <div>
              <figure class="image is-64x64">
                <icon-leader-badge class="leader-badge" v-if="item === 1" />
                <img class="is-rounded" :src="item.photo" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-items" v-if="qrCode !== ''">
      <img :src="qrCode.qrCode" alt="" />
    </div>
    <div v-if="leaderId === userId" class="center-items">
      <b-button @click="playGame()" type="is-primary">Jogar</b-button>
    </div>
    <div class="center-items">
      <b-button
        v-if="leaderId === userId"
        @click="removeGroup()"
        type="is-danger"
        outlined
        >Desfazer grupo</b-button
      >
      <b-button v-else @click="leaveGroup()" type="is-danger" outlined
        >Sair do grupo</b-button
      >
    </div>
  </section>
</template>

<script>
import HeaderMobile from "../layout/header-mobile";
import IconInfo from "../../assets/icons/icon-info";
import IconLeaderBadge from "../../assets/icons/icon-leader-badge";
import GroupRequests from "../../api/GroupRequests";
import groupRequests from "../../api/GroupRequests";
const io = require("socket.io-client");
export default {
  components: { HeaderMobile, IconInfo, IconLeaderBadge },
  data() {
    return {
      qrCode: "",
      socket: io(`${process.env.VUE_APP_API_URL}`),
      members: null,
      groupId: null,
      groupName: null,
      gameId: null,
      userLessCredits: 0,
      leaderId: null,
      userId: null,
    };
  },
  methods: {
    async generateQRCode() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/groups/qrcode/?groupID=${this.groupId}&gameID=${this.gameId}`,
      );
      
      this.qrCode = await response.json();
    },
    goBack() {
      this.$router.go(-1);
    },
    playGame() {
      this.members.sort(function(a, b) {
        return a.credits - b.credits;
      });
      this.userLessCredits = this.members[0];

      this.$router.push({
        name: "play-game",
        query: {
          game: this.gameId,
          gameType: "group",
          groupName: this.groupName,
          members: this.members,
          groupId: this.groupId,
          credits: this.userLessCredits.credits,
        },
      });
    },

    removeGroup() {
      groupRequests
        .removeGroup(this.groupId)
        .then((result) => {
        console.log(result);
          this.$buefy.toast.open({
            message: "Grupo apagado com sucesso!",
            type: "is-primary",
          });
          this.$router.push({ path: "game-info/" + this.gameId });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    leaveGroup() {
      let data = {
        groupID: this.groupId,
      };
   
      groupRequests
        .leaveGroup(this.userId, data)
        .then((result) => {
          console.log(result);
          this.socket.emit("joinGroup", {
            groupId: this.groupId,
            id: this.$cookies.get("userId"),
            action: "remove",
          });
     
          this.$buefy.toast.open({
            message: "Saiu do grupo com sucesso!",
            type: "is-primary",
          });
          this.$router.push({ path: "/home" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.groupId = this.$route.params.id;
    this.userId = this.$cookies.get("userId");
    
    this.data = [];
    GroupRequests.getGroupInfo(this.groupId).then((result) => {
    
      this.leaderId = result.leaderID;
      this.groupName = result.name;
      this.gameId = result.gameID;
   
      result.members.map((member) => {
        this.data.push(member);
      });
      this.generateQRCode();
    });
    this.members = this.data;
    this.socket.on("newMember", (data) => {
      if (data.groupId === this.groupId) {
        if (data.action === "remove") {
          this.members = this.members.filter((member) => {
            return member.id !== data.id;
          });
          
        } else {
          this.members.push(data);
        }
      }
    });
  },
};
</script>

<style lang="scss">
@import "../../styles/_invite-members";
</style>
