<template>
  <div class="gameMode">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.gameMode {
    border-radius: 5px;
    box-shadow: none;
    width: max-content;
    height: 25px;
    background-color: #e7ecf7;
    display: inline-block;
    margin-right: 5px; 
    font-size: 13px;
    padding:   4px; 
    display: inline-flex;
     margin-top: 10px;
      align-items: center;
}

.gameMode #title {
  font-size: 13px;
  font-family: "Inter", sans-serif;
  display: inline-block;
  color: #3158a3;
}

.gameMode .icon {
  color: #3158a3;
  display: inline-block;
  height: 16px; 
  margin-right: 2px;
}
</style>
