<template>
  <div id="app">
    <div>
      <router-view />
    </div>
    <footer class="bottom-navigation" v-if="showFooter"><bottomNav /></footer>
  </div>
</template>

<script>
import bottomNav from "../src/components/layout/BottomNav";
export default {
  components: {
    bottomNav
  },
  data() {
    return {
      isLoggedIn: false,
      token: null,
      showFooter: false,
    };
  },
  created() {
    this.$store.dispatch("tryLogin");
    this.$crontab.addJob({
      name: "counter",
      interval: { seconds: "0", minutes: "0", hours: "/1" },
      job: this.refreshToken,
    });
    window.addEventListener("resize", this.getWindowWidth);
    this.getWindowWidth();
  },
  watch: {
    $route() {
      this.getWindowWidth();
    },
  },
  methods: {
    getWindowWidth() {
      this.size = window.innerWidth;
      /* 	if (this.size < 1024) { */
      this.checkFooter(this.size);
      //}
    },
    async refreshToken() {
      this.token = this.$cookies.get("token");
      if (this.token) {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/qrgame/users/token/refresh`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        );
        const result = await response.json();
        this.$cookies.set(
          "token",
          result.token,
          new Date(result.tokenExp * 1000)
        );
      }
    },
    checkFooter(size) {
      /* 	console.log(
				/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
					this.$route.name !== 'play-game'
			); */
      if (
        /* /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) */ size <
          1024 &&
        this.$route.name !== "play-game" &&
        this.$route.name !== "monitor-game" &&
        this.$route.name !== "invite-members" &&
        this.$route.name !== "auth" &&
        this.$route.name !== "update" &&
        this.$route.name !== "create" &&
        this.$route.name !== "edit" &&
        this.$route.name !== "profile" &&
        this.$route.name !== "game-info" &&
        this.$route.name !== "signup"
      ) {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./styles/_main";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
html,
body {
  height: 100%;
  background-color: #f9f9fb !important;
}

*:focus {
  outline: none;
}

.menu-list a:hover {
  background-color: transparent !important;
  color: #363636;
}

.page-content {
  margin-left: 364px;
  display: block;
  padding: 20px;
  margin-bottom: 10px;
}

.header {
  margin-bottom: 2rem;
}

/* Sets the dimensions of the entire scrollbar */
html::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 12px;
}

/* The grabbable scrollbar button  */
html::-webkit-scrollbar-thumb {
  background: #3158a3;
  border-radius: 8px;
}

/* The vertical scrollbar background */
html::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #f9f9f9, #f9f9f9 1px, #f9f9f9 0, #f9f9f9);
}

@media screen and (max-width: 1024px) {
  .page-content {
    padding-left: 4%;
    margin-left: 0;
    margin-top: 2rem !important;
    padding-bottom: 108px;
  }
}
</style>
