<template>
  <section id="game-form">
    <div class="navigation-buttons">
      <b-button @click="previousStep">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </b-button>
      <b-button
        :class="{ 'button-inactive': inactiveNavigation }"
        @click="nextStep"
      >
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </b-button>
    </div>
    <div v-for="(step, index) in gameDetails.steps" :key="step.id">
      <div v-if="index === currentStep">
        <div class="columns reverse-columns is-desktop">
          <div class="column is-three-quarters-desktop">
            <game-step-track
              class="steps-form-colum"
              v-if="step.type.value === 'hint'"
              :hintText="step.hint.text"
              :tips="step.hint.tips"
              @hintInfo="stepHintChanged"
            ></game-step-track>
            <game-step-question
              class="steps-form-colum"
              @questionInfo="stepQuestionChanged"
              :questionData="step.question"
              v-if="step.type.value === 'question'"
            ></game-step-question>
            <game-step-photo
              class="steps-form-colum"
              v-if="step.type.value === 'photo'"
              :photoText="step.photo.text"
              @photoInfo="stepPhotoChanged"
            ></game-step-photo>
            <div
              class="steps-form-colum"
              id="map"
              v-if="step.type.value === 'hint'"
            >
              <b-field label="Localização do código QR"> </b-field>
              <google-map
                @newQRCodePosition="setQrCodePosition"
                :qrCodeCoordinates="step.qrCodeCoordinates.coordinates"
                :mapCenter="mapCenter"
              ></google-map>
            </div>
            <div
              class="steps-form-colum"
              id="map"
              v-if="step.type.value === 'photo'"
            >
              <b-field label="Localização do local para fotografar"> </b-field>
              <google-map
                @newQRCodePosition="setQrCodePosition"
                :qrCodeCoordinates="step.qrCodeCoordinates.coordinates"
                :mapCenter="mapCenter"
              ></google-map>
            </div>
          </div>
          <div class="column">
            <step-options
              @setCreditsToWin="setCreditsToWin"
              @setCreditsToLose="setCreditsToLose"
              :currentOption="step.type"
              @stepType="onGameTypeChange"
              :numberOfCreditsToLose="step.numberOfCreditsToLose"
              :numberOfCreditsToWin="step.numberOfCreditsToWin"
            ></step-options>
          </div>
        </div>
      </div>
    </div>

    <div class="steps-row is-hidden-mobile">
      <div v-for="(step, index) in gameDetails.steps" :key="step.id">
        <step-container
          @delete="removeStep"
          @toogleSelection="toogleSelection"
          :isSelected="step.selected"
          :step-number="index + 1"
          :step-id="step.id"
          :is-valid="step.isStepValid"
        ></step-container>
      </div>
      <div
        class="add-step"
        :class="{ 'add-step-inactive': gameDetails.invalidStepsCounter !== 0 }"
        @click="addNewStep"
      >
        <span class="add-icon">+</span>
      </div>
    </div>

    <!-- <div class="is-hidden-tablet">
      <step-container2
        @delete="removeStep"
        @toogleSelection="toogleSelection"
        @addNewStep="addNewStep"
        :step-number="step"
        :is-valid="step.isStepValid"
      ></step-container2>
    </div> -->

    <div class="steps-row-mobile is-hidden-tablet">
      <div
        v-if="currentStep > 0"
        class="step-box left-box"
        @click="toogleSelection(currentStep)"
      >
        <div class="icons">
          <iconify-icon
            v-if="currentStep > 5"
            class="delete-icon"
            @click="removeStep(currentStep)"
            :icon="icons.delete"
            height="24"
          />
        </div>
        <p class="step-number">{{ currentStep }}</p>
      </div>
      <div class="step-box active-step center-box">
        <div class="icons">
          <iconify-icon
            v-if="currentStep + 1 > 5"
            class="delete-icon"
            @click="removeStep(currentStep)"
            :icon="icons.delete"
            height="24"
          />
        </div>
        <p class="step-number">{{ currentStep + 1 }}</p>
      </div>
      <div
        v-if="currentStep + 2 <= gameDetails.steps.length"
        class="step-box right-box"
        @click="toogleSelection(currentStep + 2)"
      >
        <p class="step-number">{{ currentStep + 2 }}</p>
      </div>
      <div
        v-if="currentStep + 2 > gameDetails.steps.length"
        class="step-box right-box"
        @click="addNewStep"
      >
        <p class="add-icon">+</p>
      </div>
    </div>
  </section>
</template>

<script>
import GameStepTrack from "../../components/CreateGame/GameStepTrack";
import GameStepQuestion from "../../components/CreateGame/GameStepQuestion";
import GameStepPhoto from "../../components/CreateGame/GameStepPhoto";
import StepOptions from "../../components/CreateGame/StepOptions";
import GoogleMap from "../../components/CreateGame/GoogleMap";
import StepContainer from "../../components/CreateGame/StepContainer.vue";
import IconifyIcon from "@iconify/vue";
import bxTrash from "@iconify-icons/bx/bx-trash";
export default {
  props: [
    "gameDetails",
    "formatedDetails",
    "currentStep",
    "inactiveNavigation",
    "mapCenter",
    "currentStepOption",
  ],
  emits: ["clicked"],
  components: {
    GameStepTrack,
    GameStepQuestion,
    GoogleMap,
    StepContainer,
    StepOptions,
    GameStepPhoto,
    IconifyIcon,
  },
  data() {
    return {
      invalidStep: false,
      hasSelectedStep: false,
      stepsFormated: [],
      formatedGame: [],
      currentOption: this.currentStepOption,
      icons: {
        delete: bxTrash,
      },
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    nextStep() {
      this.gameDetails.invalidStepsCounter = 0;
      this.gameDetails.steps.map((step) => {
        if (!step.isStepValid) {
          this.gameDetails.invalidStepsCounter++;
        }
      });
      if (this.gameDetails.invalidStepsCounter == 0) {
        this.inactiveNavigation = false;
        this.saveGameInfo(2);
      }
    },
    previousStep() {
      this.saveGameInfo(0);
    },
    onGameTypeChange(value) {
      if (value === "hint") {
        this.gameDetails.steps[this.currentStep].type = {
          text: "Pista",
          value: "hint",
        };
      } else if (value === "question") {
        this.gameDetails.steps[this.currentStep].type = {
          text: "Pergunta",
          value: "question",
        };
      } else {
        this.gameDetails.steps[this.currentStep].type = {
          text: "Fotografia",
          value: "photo",
        };
      }
      this.currentOption = value;
      if (this.currentOption === "hint") {
        this.validateStepData("hint", this.gameDetails.steps[this.currentStep]);
      } else if (this.currentOption === "question") {
        this.validateStepData(
          "question",
          this.gameDetails.steps[this.currentStep]
        );
      } else {
        this.validateStepData(
          "photo",
          this.gameDetails.steps[this.currentStep]
        );
      }
    },
    getInvalidStepsNumber() {
      this.gameDetails.invalidStepsCounter = 0;
      this.gameDetails.steps.map((step) => {
        if (!step.isStepValid) {
          this.gameDetails.invalidStepsCounter++;
        }
      });
    },
    addNewStep() {
      this.gameDetails.invalidStepsCounter = 0;
      this.gameDetails.steps.map((step) => {
        if (!step.isStepValid) {
          this.gameDetails.invalidStepsCounter++;
        }
      });
      if (this.gameDetails.invalidStepsCounter === 0) {
        this.inactiveNavigation = true;
        this.gameDetails.steps.push({
          id: new Date(),
          type: { text: "Pista", value: "hint" },
          selected: true,
          isStepValid: false,
          estimatedDuration: {
            duration: null,
            formatedDuration: null,
          },
          numberOfCreditsToWin: 5,
          numberOfCreditsToLose: 5,
          qrCodeCoordinates: {
            coordinates: this.mapCenter,
            hasPositionChanged: false,
          },
          stepCode: null,
          question: {
            question: "",
            answers: [
              {
                optionText: "",
                rightOption: true,
              },
              {
                optionText: "",
                rightOption: false,
              },
              {
                optionText: "",
                rightOption: false,
              },
              {
                optionText: "",
                rightOption: false,
              },
            ],
          },
          hint: {
            text: "",
            tips: [
              {
                text: "",
                value: 20,
              },
            ],
          },
          photo: {
            text: "",
          },
        });
        this.save();
        this.toogleSelection(
          this.gameDetails.steps[this.gameDetails.steps.length - 1].id
        );
      }
    },
    removeStep(stepId) {
      if (this.gameDetails.steps.length > 1) {
        this.hasSelectedStep = 0;
        //remove step from array
        this.gameDetails.steps = this.gameDetails.steps.filter((step) => {
          return step.id !== stepId;
        });
        //check if the selected step is not the one being removed
        this.gameDetails.steps.filter((step) => {
          if (step.selected) {
            this.hasSelectedStep++;
          }
        });
        // if the step being removed is the selected one than change the current step to the last one on the array
        if (this.hasSelectedStep === 0) {
          this.toogleSelection(
            this.gameDetails.steps[this.gameDetails.steps.length - 1].id
          );
        }
        this.save();
      }
    },
    toogleSelection(stepId) {
      this.gameDetails.steps.filter((step, index) => {
        // remove selected state from the previous selected step
        if (step.selected && step.id !== stepId) {
          step.selected = false;
        }
        // add selected state to the new selected step
        if (step.id === stepId) {
          step.selected = true;
          this.currentStep = index;
          this.step = this.currentStep + 1;
          this.currentOption = step.type.value;
        }
      });
    },
    /* setStepDuration(value) {
			this.gameDetails.steps[this.currentStep].estimatedDuration = value;
		}, */
    setQrCodePosition(value) {
      this.gameDetails.steps[this.currentStep].qrCodeCoordinates = value;
      this.validateStepData(
        this.currentOption,
        this.gameDetails.steps[this.currentStep]
      );
    },
    setCreditsToWin(value) {
      this.gameDetails.steps[this.currentStep].numberOfCreditsToWin = value;
    },
    setCreditsToLose(value) {
      this.gameDetails.steps[this.currentStep].numberOfCreditsToLose = value;
    },
    stepQuestionChanged(value) {
      this.gameDetails.steps[this.currentStep].question = value;
      this.validateStepData(
        "question",
        this.gameDetails.steps[this.currentStep]
      );
    },
    stepHintChanged(value) {
      this.gameDetails.steps[this.currentStep].hint = value;
      this.validateStepData("hint", this.gameDetails.steps[this.currentStep]);
    },
    stepPhotoChanged(value) {
      this.gameDetails.steps[this.currentStep].photo = value;
      this.validateStepData("photo", this.gameDetails.steps[this.currentStep]);
    },
    validateStepData(type, value) {
      let input = JSON.parse(JSON.stringify(value));
      if (type === "question") {
        if (
          input.question.question.trim().length > 0 &&
          input.question.answers[0].optionText.trim().length > 0 &&
          input.question.answers[1].optionText.trim().length > 0
        ) {
          this.gameDetails.steps[this.currentStep].isStepValid = true;
          this.invalidStep = false;
        } else {
          this.gameDetails.steps[this.currentStep].isStepValid = false;
          this.invalidStep = true;
        }
      } else if (type === "hint") {
        if (
          input.hint.text.trim().length > 0 &&
          input.qrCodeCoordinates.hasPositionChanged
        ) {
          this.gameDetails.steps[this.currentStep].isStepValid = true;
          this.inactiveNavigation = false;
          this.invalidStep = false;
        } else {
          this.gameDetails.steps[this.currentStep].isStepValid = false;
          this.inactiveNavigation = true;
          this.invalidStep = true;
        }
      } else {
        if (input.photo.text.trim().length > 0) {
          this.gameDetails.steps[this.currentStep].isStepValid = true;
          this.inactiveNavigation = false;
          this.invalidStep = false;
        } else {
          this.gameDetails.steps[this.currentStep].isStepValid = false;
          this.inactiveNavigation = true;
          this.invalidStep = true;
        }
      }
      this.save();
    },
    saveGameInfo(goToStep) {
      // prepare game data to be sent to the db
      //this.stepsFormated = [];
      this.stepsFormated = this.gameDetails.steps.map((step) => {
        if (step.type.value === "hint") {
          return {
            type: step.type.value,
            text: step.hint.text,
            tips: step.hint.tips,
            stepCode: Math.random(),
            //estimatedTime: step.estimatedDuration.formatedDuration * 60,
            qrCodeCoordinates: {
              lat: step.qrCodeCoordinates.coordinates.lat,
              lng: step.qrCodeCoordinates.coordinates.lng,
            },
          };
        } else if (step.type.value === "question") {
          return {
            type: step.type.value,
            question: step.question.question,
            answers: step.question.answers,
            creditsToWin: step.numberOfCreditsToWin,
            creditsToLose: step.numberOfCreditsToLose,
            //estimatedTime: step.estimatedDuration.formatedDuration * 60,
          };
        } else {
          return {
            type: step.type.value,
            text: step.photo.text,
            qrCodeCoordinates: {
              lat: step.qrCodeCoordinates.coordinates.lat,
              lng: step.qrCodeCoordinates.coordinates.lng,
            },
          };
        }
      });

      this.formatedGame = {
        details: this.formatedDetails,
        steps: this.stepsFormated,
      };

      this.$emit("clicked", {
        step: goToStep,
        gameDetails: this.gameDetails,
        formatedGame: this.formatedGame,
      });
    },
    async save() {
      await this.$store.dispatch("saveGame", this.gameDetails);
    },
  },
  created() {
    this.scrollToTop();
    console.log(this.gameDetails.steps.length);
    console.log(this.currentStep);
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_create-game";
</style>
