<template>
  <section>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

    <div id="game-page" v-if="!isLoading">
      <div class="game-header">
        <b class="step-number">Etapa nº {{ currentStep + 1 }}</b>
        <div class="credits-container">
          <credit />
          <b>{{ credits }}</b>
        </div>
      </div>

      <div v-for="(step, index) in receivedGame" :key="index">
        <div v-if="index === currentStep">
          <div v-if="step.type === 'hint'">
            <div class="container">
              <p>{{ step.text }}</p>
            </div>
            <game-tips
              :userCredits="credits"
              :game="step.tips"
              @unlockTip="unlockTip"
            />
            <div class="map-container" v-if="isMapVisible">
              <p class="title">
                Segue o mapa para descobrires o próximo código
              </p>
              <GmapMap :zoom="18" :center="origin">
                <DirectionsRenderer
                  travelMode="WALKING"
                  :origin="origin"
                  :destination="destination"
                />
              </GmapMap>
            </div>
          </div>

          <div v-if="step.type === 'question'">
            <div v-if="!isQuestionAnswered">
              <div class="container">
                <p>{{ step.question }}</p>
              </div>

              <div v-for="(answer, index) in step.answers" :key="index">
                <div
                  class="options-container"
                  v-if="answer.optionText.length > 0"
                  :class="{ 'option-selected': selectedOption === index }"
                  @click="selectedOption = index"
                >
                  <p>{{ answer.optionText }}</p>
                </div>
              </div>
              <div>
                <button
                  v-if="!confirmButton"
                  disabled
                  class="confirm-answer-block"
                >
                  Confirmar
                </button>
                <button v-else class="confirm-answer" @click="checkAnswers">
                  Confirmar
                </button>
              </div>
            </div>
          </div>

          <div v-if="step.type === 'photo'">
            <div class="container">
              <p>{{ step.text }}</p>
            </div>
            <div class="map-container" v-if="isMapVisible">
              <p class="title">
                Segue o mapa para descobrires o local onde tirar a foto
              </p>
              <GmapMap :zoom="18" :center="origin">
                <DirectionsRenderer
                  travelMode="WALKING"
                  :origin="origin"
                  :destination="destination"
                />
              </GmapMap>
            </div>
          </div>
        </div>
      </div>

      <!--       <div class="map-container" v-if="isMapVisible">
        <p class="title">Segue o mapa para descobrires o próximo código</p>
        <GmapMap :zoom="18" :center="origin">
          <DirectionsRenderer
            travelMode="WALKING"
            :origin="origin"
            :destination="destination"
          />
        </GmapMap>
      </div> -->

      <div v-if="receivedGame === undefined && !isMapVisible">
        <div class="scan-message">
          <p>Faz scan de um código para jogar</p>
        </div>
      </div>

      <div class="game-options-container">
        <div class="game-options">
          <div @click="isLeaveModalActive = true"><icon-leave /></div>

          <div
            v-if="receivedGame[currentStep].type === 'hint'"
            @click="isReportModalActive = true"
          >
            <icon-qr-missing class="icon-missing" />
          </div>
          <div v-else>
            <icon-qr-missing-blocked class="icon-missing" />
          </div>

          <div
            v-if="receivedGame[currentStep].type === 'hint'"
            @click="isComponentModalActiveHint = true"
          >
            <icon-read-qr />
          </div>

          <div
            v-if="receivedGame[currentStep].type === 'photo'"
            @click="isComponentModalActivePhoto = true"
          >
            <icon-read-qr />
          </div>

          <div
            v-if="
              isQuestionAnswered &&
              receivedGame[currentStep].type === 'question'
            "
          >
            <icon-read-qr-blocked />
          </div>
        </div>
      </div>

      <b-modal
        v-model="answersMessage"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
        :has-modal-card="true"
      >
        <template>
          <div class="modal-card" style="width: auto">
            <section class="modal-card-body">
              <question-result-message @closeMessageModal="closeMessageModal">{{
                checkAnswerMessage
              }}</question-result-message>
            </section>
          </div>
        </template>
      </b-modal>

      <b-modal
        v-model="isFeedbackModalActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
        :has-modal-card="true"
      >
        <template>
          <div class="modal-card" style="width: auto">
            <section class="modal-card-body">
              <modal @closeModal="closeModal" @sendRating="sendRating"></modal>
            </section>
          </div>
        </template>
      </b-modal>

      <b-modal
        v-model="isReportModalActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
        :has-modal-card="true"
      >
        <template>
          <div class="modal-card" style="width: auto">
            <section class="modal-card-body">
              <report-modal
                :stepId="currentStep"
                @closeReportModal="closeReportModal"
              ></report-modal>
            </section>
          </div>
        </template>
      </b-modal>

      <b-modal
        v-model="isComponentModalActiveHint"
        has-modal-card
        full-screen
        :can-cancel="false"
      >
        <read-qr-code
          :gameId="gameId"
          :currentStep="currentStep"
          @stepDetails="getStepDetails"
        />
      </b-modal>

      <b-modal
        v-model="isComponentModalActivePhoto"
        full-screen
        :can-cancel="false"
      >
        <take-photo
          :gameId="gameId"
          :currentStep="currentStep"
          @stepDetails="getStepDetails"
        />
      </b-modal>

      <b-modal
        v-model="isLeaveModalActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
        :has-modal-card="true"
      >
        <template>
          <div class="modal-card" style="width: auto">
            <section class="modal-card-body">
              <leave-modal
                @closeModal="closeLeaveModal"
                @leaveGame="leaveGame"
              ></leave-modal>
            </section>
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import credit from "../../assets/icons/credit";
import IconLeave from "../../assets/icons/leave-game";
import IconQrMissing from "../../assets/icons/qr-missing";
import IconQrMissingBlocked from "../../assets/icons/qr-missing-blocked";
import IconReadQr from "../../assets/icons/scan";
import IconReadQrBlocked from "../../assets/icons/scan-blocked";
import GameTips from "../../components/PlayGame/GameTips";
import ReadQrCode from "./ReadQrCode";
import TakePhoto from "./TakePhoto";
import modal from "../../components/PlayGame/FeedbackModal";
import leaveModal from "../../components/PlayGame/LeaveGameModal";
import reportModal from "../../components/PlayGame/ReportModal";
import QuestionResultMessage from "../../components/PlayGame/QuestionResultMessage";
import DirectionsRenderer from "../../components/PlayGame/DirectionsRenderer";
import gameProgress from "@/api/gameprogress";
import GameRequests from "@/api/GameRequests";
import { io } from "socket.io-client";

export default {
  components: {
    credit,
    IconLeave,
    IconReadQr,
    IconQrMissing,
    IconReadQrBlocked,
    IconQrMissingBlocked,
    GameTips,
    modal,
    ReadQrCode,
    TakePhoto,
    reportModal,
    QuestionResultMessage,
    DirectionsRenderer,
    leaveModal,
  },
  data() {
    return {
      allSteps: [],
      isLoading: true,
      receivedGame: undefined,
      currentStep: 0,
      isQuestionAnswered: false,
      selectedOption: -1,
      isComponentModalActiveHint: false,
      isComponentModalActivePhoto: false,
      isMapVisible: true,
      origin: { lat: 39.9317152, lng: -8.3991808 },
      destination: { lat: 39.93423843, lng: -8.420591217758435 },
      series: ["1"],
      chartOptions: {
        chart: {
          height: 35,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "10%",
            },
          },
        },
        labels: ["1"],
      },
      credits: 0,
      isFeedbackModalActive: false,
      isReportModalActive: false,
      isLeaveModalActive: false,
      rightOption: null,
      checkAnswerMessage: "",
      answersMessage: false,
      confirmButton: false,
      totalSteps: 0,
      userRating: 0,
      gameId: "",
      gameType: "",
      groupName: "",
      members: null,
      username: "",
      membersId: [],
      groupId: null,
      socket: io(`${process.env.VUE_APP_API_URL}`, {
        cors: {
          origin: "*",
        },
      }),
      userRightAnswers: 0,
      usedTips: 0,
      startedGameTime: null,
      endedGameTime: null,
      lastStep: null,
      initialCredits: 0,
      finalCredits: 0,
    };
  },
  watch: {
    selectedOption() {
      this.confirmButton = true;
    },
    origin: function (val) {
      this.origin = val;
    },
    destination: function (val) {
      this.destination = val;
    },
  },
  methods: {
    async getTeamMembers() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/groups/${this.groupId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      );
      const responseData = await response.json();
      return responseData;
    },
    shuffleAnswers(array) {
      return array.sort(() => Math.random() - 0.5);
    },
    getStepDetails(value) {
      /* this.isMapVisible = false; */
      this.currentStep = value.index;

      if (this.receivedGame[this.currentStep].type === "question") {
        this.receivedGame[this.currentStep].answers = this.shuffleAnswers(
          this.receivedGame[this.currentStep].answers
        );
      }

      this.socket.emit("playerStepChanged", {
        gameId: this.gameId,
        username: this.groupName,
        id: this.monitorPlayerId,
        step: this.currentStep,
      });
      let data = {
        playersInfo: {
          id: this.monitorPlayerId,
          username: this.groupName,
          step: this.currentStep,
        },
      };

      GameRequests.setPlayersStep(data, this.gameId).then(() => {});

      if (
        (this.totalSteps === this.currentStep &&
          this.receivedGame[this.currentStep].type === "hint") ||
        (this.totalSteps === this.currentStep &&
          this.receivedGame[this.currentStep].type === "photo")
      ) {
        this.lastStep = "completed";
        setTimeout(() => {
          this.isFeedbackModalActive = true;
        }, 2000);
        this.endedGameTime = new Date();
      } else if (this.receivedGame[this.currentStep].type == "question") {
        this.isQuestionAnswered = false;
      }
      this.save();
    },
    unlockTip(value) {
      if (this.credits > 0 || this.credits >= value) {
        this.credits = this.credits - value;
        this.usedTips = this.usedTips + 1;
      } else this.noCredits = true;
    },
    async gameData() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/games/${this.gameId}`
      );

      const responseData = await response.json();

      this.receivedGame = responseData.steps;

      this.totalSteps = responseData.steps.length - 1;
      console.log(this.totalSteps);
      console.log(this.currentStep);

      responseData.steps.map((step) => {
        this.allSteps.push({
          type: step.type,
          coordinates: step.qrCodeCoordinates,
        });
      });

      const userData = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/${this.$cookies.get(
          "userId"
        )}`
      );
      this.userInfo = await userData.json();
      if (this.gameType === "individual") {
        this.credits = this.userInfo.user.credits;
      }
    },
    closeMessageModal() {
      this.answersMessage = false;
    },
    closeModal(value) {
      this.isFeedbackModalActive = value;
      this.endGame();
    },
    checkAnswers() {
      this.isQuestionAnswered = true;
      if (
        this.receivedGame[this.currentStep].answers[this.selectedOption]
          .rightOption
      ) {
        this.rightOption = true;
        this.checkAnswerMessage = `Certo! Ganhaste ${
          this.receivedGame[this.currentStep].creditsToWin
        }  `;
        this.answersMessage = true;
        this.credits += this.receivedGame[this.currentStep].creditsToWin;
        this.userRightAnswers = this.userRightAnswers + 1;
      } else {
        this.rightOption = false;
        this.checkAnswerMessage = `Errado! Perdeste ${
          this.receivedGame[this.currentStep].creditsToLose
        }  `;
        this.answersMessage = true;
        this.credits -= this.receivedGame[this.currentStep].creditsToLose;
      }
      this.selectedOption = -1;
      if (this.totalSteps === this.currentStep) {
        setTimeout(() => {
          this.isFeedbackModalActive = true;
        }, 2000);
        this.lastStep = "completed";
        this.endedGameTime = new Date();
      }
      this.isQuestionAnswered = false;
      this.currentStep += 1;
      this.save();
    },
    changeStepManualy(requestType) {
      console.log(this.receivedGame[this.currentStep].type === "question");
      if (requestType === "report") {
        this.isMapVisible = false;
        this.currentStep = this.currentStep + 1;
      } else if (this.receivedGame[this.currentStep].type === "question") {
        this.isMapVisible = false;
      }
      console.log(this.totalSteps === this.currentStep);
      if (
        (this.totalSteps === this.currentStep &&
          this.receivedGame[this.currentStep].type === "hint") ||
        (this.totalSteps === this.currentStep &&
          this.receivedGame[this.currentStep].type === "photo")
      ) {
        this.lastStep = "completed";
        this.endedGameTime = new Date();
        setTimeout(() => {
          this.isFeedbackModalActive = true;
        }, 2000);
      } else if (
        this.receivedGame[this.currentStep].type === "question" &&
        requestType === "report"
      ) {
        this.isQuestionAnswered = false;
      }
      this.socket.emit("playerStepChanged", {
        gameId: this.gameId,
        username: this.groupName,
        id: this.monitorPlayerId,
        step: this.currentStep,
      });
      let data = {
        playersInfo: {
          id: this.monitorPlayerId,
          username: this.groupName,
          step: this.currentStep,
        },
      };
      GameRequests.setPlayersStep(data, this.gameId).then((data) => {
        if (!data) {
          console.log("Bad Request");
        }
      });
      this.save();
    },
    async closeReportModal(value) {
      if (value.action === "report") {
        // Get next step's info
        this.changeStepManualy("report");
        let reportId = new Date();
        let data = {
          operationType: "add",
          username: this.$cookies.get("username"),
          userId: this.$cookies.get("userId"),
          messageId: reportId.toString(),
          index: value.index + 1,
        };

        /* Send notification to the game administrator */
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/qrgame/games/${this.gameId}/qrcode`,
          {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "same-origin",
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        this.isReportModalActive = false;
      }
      this.isReportModalActive = false;
    },
    sendRating(value) {
      this.userRating = value;
      this.isFeedbackModalActive = false;
      this.endGame();
    },
    leaveGame(value) {
      this.isLeaveModalActive = value;
      this.lastStep = this.currentStep;
      this.endedGameTime = new Date();
      setTimeout(() => {
        this.isFeedbackModalActive = true;
      }, 2000);
    },
    closeLeaveModal(value) {
      this.isLeaveModalActive = value;
    },
    async save() {
      await this.$store.dispatch("saveProgress", {
        currentStep: this.currentStep,
        id: this.gameId,
        gameId: this.gameId,
        userID: this.membersId,
        credits: this.credits,
        isQuestionAnswered: this.isQuestionAnswered,
        groupName: this.groupName,
        groupId: this.groupId,
        membersId: this.membersId,
        gameType: this.gameType,
        monitorPlayerId: this.groupId,
      });
    },
    async endGame() {
      this.finalCredits = this.credits;
      let sendUserCredits;
      if (this.gameType === "individual") {
        sendUserCredits = this.credits;
      } else sendUserCredits = this.finalCredits - this.initialCredits;
      this.socket.emit("playerStepChanged", {
        gameId: this.gameId,
        username: this.groupName,
        id: this.monitorPlayerId,
        step: this.currentStep + 1,
      });
      let data = {
        feedback: {
          teamName: this.groupName,
          teamPlayers: this.membersId,
          rating: this.userRating,
        },
        playersInfo: {
          type: this.gameType,
          id: this.groupId,
          finalTime: 0,
          lastStep: this.lastStep,
        },
        credits: sendUserCredits,
        usedTips: this.usedTips,
        correctMultipleQuestions: this.userRightAnswers,
        playersTime: [
          this.startedGameTime.getTime(),
          this.endedGameTime.getTime(),
        ],
      };
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/games/${this.gameId}/finish-game`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      );
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.success) {
        this.$router.push({
          name: "home",
        });
      }
    },
  },
  created() {
    this.gameType = this.$route.query.gameType;

    this.startedGameTime = new Date();
    if (this.gameType === "group") {
      this.groupName = this.$route.query.groupName;
      this.groupId = this.$route.query.groupId;
      this.gameId = this.$route.query.game;
      this.credits = this.$route.query.credits;
      this.initialCredits = this.$route.query.credits;
      this.monitorPlayerId = this.$route.query.groupId;
      this.getTeamMembers().then((response) => {
        this.membersId = response.members;
      });
    } else if (this.gameType === "individual") {
      this.gameId = this.$route.query.game;
      this.gameType = "individual";
      this.groupName = this.$cookies.get("username");
      this.membersId = this.$cookies.get("userId");
      this.groupId = this.$cookies.get("userId");
      this.monitorPlayerId = this.$cookies.get("userId");
    }
    this.gameData().then(() => {
      gameProgress.getProgressDataById(this.gameId).then((progressInfo) => {
        if (progressInfo) {
          this.currentStep = progressInfo.currentStep;
          this.isQuestionAnswered = progressInfo.isQuestionAnswered;
          this.credits = progressInfo.credits;
          this.isMapVisible = progressInfo.isQuestionAnswered;
          this.groupName = progressInfo.groupName;
          this.groupId = progressInfo.groupId;
          this.membersId = progressInfo.membersId;
          this.gameId = progressInfo.gameId;
          this.gameType = progressInfo.gameType;
          this.monitorPlayerId = progressInfo.monitorPlayerId;
          if (this.currentStep !== 0) {
            this.changeStepManualy("reload");
          } else {
            this.isQuestionAnswered = false;
          }
        }
      });
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_stepDetails.scss";
</style>
