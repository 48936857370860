const userRequests = {  
    getCreatedGames: async (userId) => {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${userId}/games-created`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
      const games = await response.json()
      return games; 
    },

    updateUsername: async (userId,data,token) => {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${userId}/username`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "same-origin",
      })
      const responseData = await response.json()
      return responseData; 
    },
    
    //getting user data
    getFirstTimeSocial: async (userId,token) => {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "same-origin",
      })
      const userData = await response.json()
      return userData; 
    }
   
  };
  
  export default userRequests;