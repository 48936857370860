import router from "../router";
const categoriesRequests = {
  getCategories: async () => {
    try {
      const request = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/categories`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            let categories = data.filter((category) => {
              return category.status === "visible";
            });
            return categories;
          }
        })
        .catch((error) => {
          return error.message;
        });
      return JSON.parse(JSON.stringify(request));
    } catch (error) {
      return error;
    }
  },

  setCategory: (data) => {
    fetch(`${process.env.VUE_APP_API_URL}/qrgame/categories`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch(function (error) {
        console.log("Erro a adicionar a(s) categoria(s) " + error.message);
      });
  },

  createGame: async (data) => {
    console.log(data);
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/games`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );
    console.log(response);

    const game = await response.json();
    return game;
  },

  setPlayersStep: async (data, gameId) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/games/${gameId}/playing`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );

    const responseData = await response.json();
    return responseData;
  },

  deleteGame: async (gameId) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/games/${gameId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );

    const game = await response.json();
    return game;
  },

  updateGame: async (gameId, data) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/games/${gameId}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );

    const game = await response.json();
    return game;
  },

  getGameData: async () => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/games/${router.history.current.params.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );
    const game = await response.json();
    return game;
  },

  MonitorGame: async (gameId) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/games/${gameId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    );

    const game = await response.json();
    return game;
  },
};

export default categoriesRequests;
