var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-nav-container"},[_c('transition',{attrs:{"name":"expand"}},[(_vm.showPanel)?_c('div',{staticClass:"panel-container"},[_c('div',{staticClass:"link-align"},[_c('router-link',{attrs:{"to":"/best-qualified"},nativeOn:{"click":function($event){return _vm.togglePanel.apply(null, arguments)}}},[_c('span',{staticClass:"link"},[_vm._v("Melhores Qualificados")])]),_c('br'),_c('router-link',{attrs:{"to":"/creator-ranking"},nativeOn:{"click":function($event){return _vm.togglePanel.apply(null, arguments)}}},[_c('span',{staticClass:"link"},[_vm._v("Criadores Populares")])]),_c('br'),_c('router-link',{attrs:{"to":"/most-played"},nativeOn:{"click":function($event){return _vm.togglePanel.apply(null, arguments)}}},[_c('span',{staticClass:"link"},[_vm._v("Jogos Populares")])])],1)]):_vm._e()]),_c('div',{staticClass:"icons-container"},[_c('svg',{staticClass:"non-active",class:{
        active:
          _vm.$route.path == '/most-played' ||
          _vm.$route.path == '/creator-ranking' ||
          _vm.$route.path == '/best-qualified' ||
          _vm.showPanel == true,
      },attrs:{"width":"34","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":_vm.togglePanel}},[_c('path',{attrs:{"d":"M0 13.7143H14.3673V0H0V13.7143ZM4.78912 4.57143H9.57823V9.14286H4.78912V4.57143ZM19.1565 0V13.7143H33.5238V0H19.1565ZM28.7347 9.14286H23.9456V4.57143H28.7347V9.14286ZM0 32H14.3673V18.2857H0V32ZM4.78912 22.8571H9.57823V27.4286H4.78912V22.8571ZM31.1292 18.2857H33.5238V27.4286H26.3401V25.1429H23.9456V32H19.1565V18.2857H26.3401V20.5714H31.1292V18.2857ZM31.1292 29.7143H33.5238V32H31.1292V29.7143ZM26.3401 29.7143H28.7347V32H26.3401V29.7143Z","fill":"#F9F9FB"}})]),_c('router-link',{staticClass:"non-active",attrs:{"to":"/search"}},[_c('discover')],1),_c('router-link',{staticClass:"non-active",attrs:{"to":"/join-group"}},[_c('scan')],1),_c('router-link',{staticClass:"non-active",class:{
        active: _vm.$route.path == '/' || _vm.$route.path == '/home',
      },attrs:{"to":"/my-games"}},[_c('create-game')],1),_c('router-link',{staticClass:"non-active",attrs:{"to":"/saved-games"}},[_c('bookmark')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }