<template>
  <div class="question-container">
    <div class="columns">
      <div class="column">
        <b-field
          v-if="question.trim().length === 0"
          label="Pergunta"
          type="is-danger"
          message="É obrigatório adicionar uma pergunta"
        >
          <b-input v-model="question"></b-input>
        </b-field>
        <b-field v-else label="Pergunta">
          <b-input v-model="question"></b-input>
        </b-field>
      </div>
    </div>
    <b-field label="Opções"> </b-field>
    <div class="columns">
      <div class="column">
        <b-field
          v-if="correctOption.trim().length === 0"
          type="is-danger"
          message="É obrigatório adicionar a opção correta"
        >
          <b-input
            v-model="correctOption"
            placeholder="Texto para a opção correta"
          ></b-input>
        </b-field>
        <b-field v-else>
          <b-input
            v-model="correctOption"
            placeholder="Texto para a opção correta"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field
          v-if="wrongOption.trim().length === 0"
          type="is-danger"
          message="É obrigatório adicionar uma opção errada"
        >
          <b-input
            v-model="wrongOption"
            placeholder="Texto para a opção errada"
          ></b-input>
        </b-field>
        <b-field v-else>
          <b-input
            v-model="wrongOption"
            placeholder="Texto para a opção errada"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-input
          v-model="wrongOption2"
          placeholder="Texto para a opção errada (opcional)"
        ></b-input>
      </div>
      <div class="column">
        <b-input
          v-model="wrongOption3"
          placeholder="Texto para a opção errada (opcional)"
        ></b-input>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["questionInfo"],
  props: ["questionData"],
  watch: {
    question() {
      this.sendData();
    },

    correctOption() {
      this.sendData();
    },
    wrongOption() {
      this.sendData();
    },
    wrongOption2() {
      this.sendData();
    },
    wrongOption3() {
      this.sendData();
    },
  },
  methods: {
    sendData() {
      this.$emit("questionInfo", {
        question: this.question,
        answers: [
          {
            optionText: this.correctOption,
            rightOption: true,
          },
          {
            optionText: this.wrongOption,
            rightOption: false,
          },
          {
            optionText: this.wrongOption2,
            rightOption: false,
          },
          {
            optionText: this.wrongOption3,
            rightOption: false,
          },
        ],
      });
    },
  },
  data() {
    return {
      question: this.questionData.question,
      correctOption: this.questionData.answers[0].optionText,
      wrongOption: this.questionData.answers[1].optionText,
      wrongOption2: this.questionData.answers[2].optionText,
      wrongOption3: this.questionData.answers[3].optionText,
    };
  },
};
</script>

<style>
.is-medium {
  font-size: 16px !important;
  color: #1c3e7080;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}
.is-large {
  text-align: center;
  font-size: 1rem !important;
  color: #1c3e7080;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}

.question-container {
  padding-bottom: 7rem;
}
</style>
