<template>
  <section class="page-content">
    <sidebar title="Criadores Populares" dropdown="true"/>
    <header-desktop class="is-hidden-touch" />
    <div class="columns is-mobile">
      <div class="column is-8-desktop is-full-tablet is-full-mobile">
        <div class="title">
          <span>Criadores Populares</span>
        </div>
        <div class="card-container" v-if="searchResult.length !== 0">
          <ul v-for="(creator, index) in searchResult" :key="creator._id">
            <li>
              <div
                class="card"
                @click="
                  getCreatorInfo(creator._id);
                  openCreatorInfo();
                "
              >
                <gold-medal class="first" v-if="index === 0" />
                <silver-medal class="second" v-else-if="index === 1" />
                <bronze-medal class="third" v-else-if="index === 2" />
                <span v-else class="position">{{ index + 1 }}</span>
                <div class="name-container">
                  <span class="creator-name">{{ creator.username }}</span>
                </div>

                <group-icon class="icon-rank" /><span class="games-played-label"
                  >{{ creator.totalGamesPlayed }} Jogadores</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="creator-info" v-if="isVisible == true">
        <div class="info-container">
          <div @click="closeCreatorInfo">
            <icon-close class="close" />
          </div>
          <div class="creator-name-container">
            <p class="name-label">{{ creatorResult[0].creator.name }}</p>
          </div>
          <div>
            <div v-if="creatorResult.length !== 0" class="title-container">
              <span class="title-top-games">Melhores jogos deste criador</span>
            </div>
            <div v-if="creatorResult.length !== 0">
              <ul
                v-for="creator in creatorResult.slice(0, 3)"
                :key="creator._id"
              >
                <li class="creator-top-games">
                  <base-card
                    class="creator-top-games-card"
                    @click.native="seeGameDetails(creator._id)"
                    :game="creator"
                    :savedGames="savedGame"
                    @saveGame="saveGame"
                    @removeGame="removeGame"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import goldMedal from "../../assets/icons/gold-medal";
import silverMedal from "../../assets/icons/silver-medal";
import bronzeMedal from "../../assets/icons/bronze-medal";
import Sidebar from "../../components/layout/Sidebar";
import HeaderDesktop from "../../components/Header";
import apiCreatorRanking from "../../api/creatorRanking";
import groupIcon from "../../assets/icons/icon-group";
import iconClose from "../../assets/icons/icon-close";

export default {
  components: {
    goldMedal,
    silverMedal,
    bronzeMedal,
    Sidebar,
    HeaderDesktop,
    groupIcon,
    iconClose,
  },
  data() {
    return {
      searchResult: [],
      creatorResult: [],
      savedGame: [],
      seeDetails: true,
      userId: "",
      isVisible: false,
    };
  },
  methods: {
    search() {
      apiCreatorRanking
        .search()
        .then((result) => {
          this.searchResult = result;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getCreatorInfo(value) {
      apiCreatorRanking
        .getCreatorInfo(value)
        .then((result) => {
          this.creatorResult = result;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getGameModeName: function (game) {
      if (game.gameMode.playersType === "both") {
        return "Ambos";
      } else if (game.gameMode.playersType === "group") {
        return "Jogo de Equipa";
      } else return "Jogo Individual";
    },
    getGameIcon: function (game) {
      if (game.gameMode.playersType === "group") {
        return this.icons.group;
      } else if (game.gameMode.playersType === "individual") {
        return this.icons.individual;
      } else return;
    },

    getUserSavedGames() {
      apiCreatorRanking.getUserSavedGames(this.userId).then((result) => {
        this.savedGame = result.gameIDs;
      });
    },

    updateGamesSaved(gameId) {
      apiCreatorRanking.updateGamesSaved(this.userId, { gameID: gameId });
    },

    removeGame(gameId) {
      this.seeDetails = false;
      this.savedGame.splice(this.savedGame.indexOf(gameId), 1);

      this.updateGamesSaved(gameId);
    },
    saveGame(gameId) {
      this.seeDetails = false;
      this.isGameSaved = !this.isGameSaved;
      this.savedGame.push(gameId);
      this.updateGamesSaved(gameId);
    },
    seeGameDetails(gameId) {
      if (this.seeDetails) {
        this.$router.push({ path: "game-info/" + gameId });
      } else {
        this.seeDetails = !this.seeDetails;
      }
    },
    openCreatorInfo() {
      setTimeout(() => this.isVisible = !this.isVisible, 150);
    },
    closeCreatorInfo() {
      this.isVisible = !this.isVisible;
    },
  },
  created() {
    this.userId = this.$cookies.get("userId");
    this.search();
    this.getUserSavedGames();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_creatorRanking.scss";
</style>
