<template>
  <div>
    <b-field
      v-if="hintText.trim().length === 0"
      label="Pista"
      type="is-danger"
      message="É obrigatório adicionar uma pista"
    >
      <b-input v-model="hintText" class="inputDefault"></b-input>
    </b-field>
    <b-field v-else label="Pista">
      <b-input v-model="hintText" class="inputDefault"></b-input>
    </b-field>
    <div class="row">
      <b-field label=""> </b-field>
      <button
        id="add-tip"
        :class="[isDisabled ? 'add-inactive' : 'button-active']"
        :disabled="isDisabled"
        @click="addTip"
      >
        <iconify-icon :icon="icons.add" height="32" />
      </button>
    </div>
    <div v-for="(tip, index) in tips" :key="index" class="columns">
      <div class="column is-three-fifths">
        <p class="label">Dica (opcional)</p>
        <b-input class="inputStep" value="" v-model="tip.text"></b-input>
      </div>

      <div class="column is-centered">
        <p class="label">Custo em créditos</p>
        <div class="counter-align">
          <div>
            <button class="counter-button" @click="removeCreditsCost(tip)">
              -
            </button>
            <input
              class="counter"
              placeholder="20"
              min="20"
              max="25"
              v-model="tip.value"
              type="number"
              disabled
            />
            <button class="counter-button" @click="addCreditsCost(tip)">
              +
            </button>
          </div>
          <div>
            <button
              id="remove-tip"
              class="remove-button"
              @click="removeTip(index)"
            >
              <div class="justify-left">
                <iconify-icon
                  v-if="tips.length > 1"
                  :icon="icons.remove"
                  height="24"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconifyIcon from "@iconify/vue";
import addR from "@iconify-icons/gg/add-r";
import bxTrash from "@iconify-icons/bx/bx-trash";
export default {
  emits: ["clicked"],
  props: ["hintText", "tips"],
  components: {
    IconifyIcon,
  },
  data() {
    return {
      inactiveNavigation: false,
      creditsCost: 20,
      index: 0,
      inactiveAdd: true,
      icons: {
        add: addR,
        remove: bxTrash,
      },
    };
  },
  computed: {
    isDisabled: function () {
      return this.inactiveAdd;
    },
  },
  watch: {
    tips: {
      deep: true,
      handler: function (newVal) {
        if (newVal[this.index].text == "") {
          this.inactiveAdd = true;
        } else {
          this.inactiveAdd = false;
          this.sendData();
        }
        if (this.tips.length === 3) {
          this.inactiveAdd = true;
        }
      },
    },
    hintText() {
      this.sendData();
    },
  },

  methods: {
    addCreditsCost(tip) {
      if (tip.value === "") {
        tip.value = 20;
      }

      tip.value++;
      if (tip.value > 25) {
        tip.value = 25;
      }
    },
    removeCreditsCost(tip) {
      tip.value--;
      if (tip.value < 20) {
        tip.value = 20;
      }
    },
    addTip: function () {
      if (this.tips.length !== 3 || this.index === -1) {
        this.tips.push({ text: "", value: 20 });
        this.inactiveAdd = true;
        this.index++;
      }
    },

    removeTip(index) {
      this.tips.splice(index, 1);
      this.index--;
      this.inactiveAdd = false;
    },
    sendData() {
      this.$emit("hintInfo", {
        text: this.hintText,
        tips: this.tips,
      });
    },
    nextStep() {
      if (!this.inactiveNavigation) {
        this.$emit("clicked", 2);
      }
    },
    previousStep() {
      this.$emit("clicked", 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/_game-hint-option";
</style>
