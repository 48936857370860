<template>
  <div class="game-card">
    <div v-if="savedGames.includes(game._id)" class="title-bookmark">
      <span id="title">{{ game.name }}</span>
      <span @click="$emit('removeGame', game._id)"><bookmark-filled /></span>
    </div>
    <div v-else class="title-bookmark">
      <span id="title">{{ game.name }}</span>
      <span @click="$emit('saveGame', game._id)"><bookmark /></span>
    </div>
    <br />
    <div class="game-info-icons">
      <icon-location class="location" /><span id="description">{{
        game.location
      }}</span>
      <icon-clock class="time" /><span id="description">{{
        game.estimatedTime
      }}</span>
      <icon-rating class="rating" /><span id="description">{{
        game.rating
      }}</span>
    </div>
    <div class="badge-row">
      <div v-if="game.gameMode.playersType === 'both'">
        <game-mode-card>
          <span id="title">
            <icon-individual class="icon" />
            Jogo Individual
          </span></game-mode-card
        ><game-mode-card>
          <icon-group class="icon" />
          <span id="title"> Jogo de Equipa </span></game-mode-card
        >
      </div>

      <div v-else>
        <game-mode-card>
          <icon-group class="icon" v-if="getGameIcon(game) === 'group'" />
          <icon-individual class="icon" v-else />
          <span id="title">
            {{ getGameModeName(game) }}
          </span>
        </game-mode-card>
      </div>

      <difficulty-card
        v-if="game.difficultyDetails.difficultyText !== 'Sem Dificuldade'"
        :class="game.difficultyDetails.difficultyClass"
        :title="game.difficultyDetails.difficultyText"
      ></difficulty-card>
    </div>
  </div>
</template>

<script>
import IconClock from "../../assets/icons/icon-clock";
import IconLocation from "../../assets/icons/icon-location";
import IconRating from "../../assets/icons/icon-rating";
import IconIndividual from "../../assets/icons/icon-individual";
import IconGroup from "../../assets/icons/icon-group";
import Bookmark from "../../assets/icons/bookmark-outline";
import BookmarkFilled from "../../assets/icons/bookmark-filled";
export default {
  props: ["game", "savedGames"],
  components: {
    IconClock,
    IconLocation,
    IconRating,
    IconGroup,
    IconIndividual,
    Bookmark,
    BookmarkFilled,
  },

  methods: {
    getGameModeName: function (game) {
      if (game.gameMode.playersType === "both") {
        return "Ambos";
      } else if (game.gameMode.playersType === "group") {
        return "Jogo de Equipa";
      } else return "Jogo Individual";
    },
    getGameIcon: function (game) {
      return game.gameMode.playersType;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/_game-card";
</style>
