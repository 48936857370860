<template>
  <div class="step-container">
    <div class="icons">
      <iconify-icon
      v-if="(stepNumber-1) > 4"
        class="delete-icon"
        @click="$emit('delete', stepId)"
        :icon="icons.delete"
        height="24"
      />
    </div>
    <div
      @click="$emit('toogleSelection', stepId)"
      class="step-box"
      :class="{ 'active-step': isSelected }"
    >
      <iconify-icon
        v-if="!isValid"
        class="info-icon"
        :icon="icons.info"
        height="24"
      />
      <p class="step-number">{{ stepNumber }}</p>
    </div>
  </div>
</template>

<script>
import IconifyIcon from "@iconify/vue";
import bxTrash from "@iconify-icons/bx/bx-trash"; 
import infoFill from "@iconify-icons/eva/info-fill";

export default {
  components: {
    IconifyIcon,
  },
  props: ["stepNumber", "stepId", "isSelected", "isValid" ],
  emits: ["delete"],
  data() {
    return {
      icons: { 
        delete: bxTrash,
        info: infoFill,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_step-container.scss";
</style>
