<template>
  <section class="monitor-game page-content">
    <header-desktop class="is-hidden-touch" />
    <sidebar />
    <header-mobile :route="true" title="" />
    <div v-if="gameInfo.creator.id === userId">
      <b-tabs v-model="activeTab">
        <b-tab-item label="Ver localização dos códigos">
          <GmapMap
            ref="mymap"
            :center="{ lat: coordinates[0].lat, lng: coordinates[0].lng }"
            :zoom="14"
          >
            <gmap-info-window
              :options="infoOptions"
              :position="infoPosition"
              :opened="infoOpened"
              @closeclick="infoOpened = false"
            >
              {{ infoContent }}
            </gmap-info-window>

            <gmap-marker
              v-for="(item, key) in coordinates"
              :key="key"
              :position="getPosition(item)"
              :clickable="true"
              @click="toggleInfo(item, key)"
            />
          </GmapMap>

          <div
            v-for="message in gameInfo.missingQrcodes"
            :key="message.messageId"
          >
            <div class="notification-message">
              {{ message.username }} reportou o código Qr da
              {{ message.index }}ª etapa como desaparecido ou manipulado

              <p class="message-button" @click="resolveMissingCode(message)">
                Marcar como resolvido
              </p>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="Monitorizar Jogo">
          <p class="header-subtitle">Monitorizar Jogo</p>
          <p class="header-title">IoTech- Team Building</p>
          <ul id="progress">
            <p class="progress-title">Progresso dos jogadores</p>
            <div v-for="(step, index) in gameInfo.steps" :key="index">
              <li>
                <div class="node">
                  <p>{{ index + 1 }}</p>
                </div>
                <div class="progress-content">
                  <p>Nesta etapa:</p>
                  <div class="columns is-multiline is-mobile">
                    <div v-for="user in playersProgress" :key="user.id">
                      <figure
                        v-if="user.step === index"
                        class="column user-image"
                      >
                        <img class="is-rounded" :src="user.photo" />
                      </figure>
                    </div>
                  </div>

                  <div v-if="!unique.includes(index)">
                    <p class="empty-step">Não está ninguém nesta etapa</p>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </b-tab-item>
      </b-tabs>
    </div>
    <div v-else class="permission-denied">
      <access-denied />
      <p>Não tem permissão para monitorizar este jogo</p>
    </div>
  </section>
</template>

<script>
//import headerMobile from "../components/layout/header-mobile.vue";
import HeaderDesktop from "../components/Header.vue";
import Sidebar from "../components/layout/Sidebar.vue";
import GameRequests from "../api/GameRequests";
import HeaderMobile from "../components/layout/header-mobile.vue";
import AccessDenied from "../components/AccessDenied.vue";
const io = require("socket.io-client");
export default {
  components: {
    /*  headerMobile, */ Sidebar,
    HeaderDesktop,
    HeaderMobile,
    AccessDenied,
  },
  data() {
    return {
      userId: this.$cookies.get("userId"),
      gameInfo: null,
      hasPlayers: [],
      unique: [],
      socket: io(`${process.env.VUE_APP_API_URL}`),
      playersProgress: [],
      activeTab: 0,
      startLocation: {
        lat: 10.3157,
        lng: 123.8854,
      },
      coordinates: [],
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  methods: {
    async resolveMissingCode(messageData) {
      let data = {
        operationType: "remove",
        username: messageData.username,
        userId: messageData.userId,
        messageId: messageData.messageId,
        index: messageData.index,
      };

      this.gameInfo.missingQrcodes = this.gameInfo.missingQrcodes.filter(
        (message) => {
          return message.messageId !== messageData.messageId;
        }
      );
      /* Send notification to the game administrator */
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/games/${this.$route.params.id}/qrcode`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      );
      const responseData = await response.json();
      console.log(responseData);
    },
    getPosition: function (marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
    },
    toggleInfo: function (marker, key) {
      this.infoPosition = this.getPosition(marker);
      this.infoContent = marker.step_number;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },
    updateUserSteps() {
      this.hasPlayers = [];
      this.playersIds = [];
      this.unique = [];
      this.uniqueIds = [];

      this.playersProgress.map((user) => {
        this.hasPlayers.push(user.step); // see what steps are not empty
        this.playersIds.push(user.id); //get all players ids
      });

      this.hasPlayer = this.hasPlayers.forEach((element) => {
        if (!this.unique.includes(element)) {
          this.unique.push(element);
        }
      });

      this.playersIds = this.playersIds.forEach((element) => {
        if (!this.uniqueIds.includes(element)) {
          this.uniqueIds.push(element);
        }
      });
    },
  },
  mounted() {
    GameRequests.MonitorGame(this.$route.params.id).then((data) => {
      this.gameInfo = data;

      this.gameInfo.steps.map((step, index) => {
        this.coordinates.push({
          step_number: `${index + 1}ª etapa`,
          lat: step.qrCodeCoordinates.lat,
          lng: step.qrCodeCoordinates.lng,
        });
      });

      this.gameInfo.playing.map((player) => {
        this.playersProgress.push({
          id: player.id,
          step: player.step,
        });
      });
      this.updateUserSteps();
    });

    this.socket.on("updatePlayerStep", (data) => {
      if (data.gameId === this.$route.params.id) {
        if (this.playersProgress.length === 0) {
          this.playersProgress.push(data);
        } else {
          // if player is new, create object with their info

          let playerExist = this.playersProgress
            .map(function (e) {
              return e.id;
            })
            .indexOf(data.id);
          if (playerExist !== -1) {
            this.playersProgress[playerExist].step = data.step;
          } else {
            this.playersProgress.push(data);
          }
        }

        this.updateUserSteps();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_monitor-game.scss";
</style>
