import Vue from "vue";
import Vuex from "vuex";
import idb from '@/api/idb';
import gameProgress from '@/api/gameprogress'
import authModule from './auth/auth'

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    games: []
  },
  mutations: {},
  actions: {
    async deleteGame(context, game) {
      await idb.deleteGame(game);
    },
    async getGames(context) {
      context.state.games = [];
      let games = await idb.getGames();
      games.forEach(game => {
        context.state.games.push(game);
      });
    }, 
    async saveGame(context, game) {
      await idb.saveGame(game);
    }, 

    async saveProgress(context, step) {
      await gameProgress.saveProgress(step);
    }, 


  },
  modules: {
    auth: authModule
  },
});
