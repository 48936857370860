const InviteToPrivateGame = {

    invite: async (data) => {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/games/private`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            }
        );
        const responseData = await response.json();
        return [response, responseData]

    },




}

export default InviteToPrivateGame;