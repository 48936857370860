<template>
  <div>
    <div class="sidebar-page columns is-mobile">
      <iconify-icon
        :icon="icons.menu"
        height="32"
        class="icon-menu icon-left"
        @click="open = true"
      />
      <div class="icon-left column is-1">
        <figure class="logo">
          <img src="../../assets/logo/ioGame_Logo_-03.png" />
        </figure>
      </div>

      <div class="column">
        <h4 class="header-title has-text-centered column" v-if="title">
          {{ title }}
        </h4>
      </div>
      <div class="icon-right column is-1">
        <b-dropdown
          class="header-dropdown"
          position="is-bottom-left"
          aria-role="menu"
          :mobile-modal="false"
        >
          <template #trigger>
            <a class="navbar-item" role="button">
              <figure class="image">
                <img
                  v-if="profile_picture && profile_picture != null"
                  class="is-rounded"
                  :src="profile_picture"
                />
                <i v-else class="mdi mdi-account mdi-48px" />
              </figure>
            </a>
          </template>
          <b-dropdown-item has-link aria-role="menuitem">
            <icon-create-game />
            <a @click="createGame"> <span>Criar Jogo</span></a>
          </b-dropdown-item>

          <b-dropdown-item has-link aria-role="menuitem">
            <settings />

            <router-link to="/profile"> <span>Definições</span></router-link>
          </b-dropdown-item>

          <b-dropdown-item has-link aria-role="menuitem">
            <icon-logout />
            <router-link to="" @click.native="logout">
              <span>Terminar Sessão</span></router-link
            >
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <section class="sidebar-layout">
      <b-sidebar
        position="fixed"
        :mobile="mobile"
        :expand-on-hover="expandOnHover"
        :reduce="reduce"
        :overlay="overlay"
        :can-cancel="canCancel"
        :fullheight="fullheight"
        v-model="open"
      >
        <div class="p-1">
          <div class="block">
            <router-link to="/home">
              <svg
                width="176"
                height="32"
                viewBox="0 0 176 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 13.7143H14.3673V0H0V13.7143ZM4.78912 4.57143H9.57823V9.14286H4.78912V4.57143ZM19.1565 0V13.7143H33.5238V0H19.1565ZM28.7347 9.14286H23.9456V4.57143H28.7347V9.14286ZM0 32H14.3673V18.2857H0V32ZM4.78912 22.8571H9.57823V27.4286H4.78912V22.8571ZM31.1292 18.2857H33.5238V27.4286H26.3401V25.1429H23.9456V32H19.1565V18.2857H26.3401V20.5714H31.1292V18.2857ZM31.1292 29.7143H33.5238V32H31.1292V29.7143ZM26.3401 29.7143H28.7347V32H26.3401V29.7143Z"
                  fill="#F9F9FB"
                />
                <path
                  d="M82.0795 16.2727C82.0795 10.6903 78.7386 7.30682 74.2216 7.30682C69.6875 7.30682 66.3551 10.6903 66.3551 16.2727C66.3551 21.8466 69.6875 25.2386 74.2216 25.2386C75.3466 25.2386 76.3949 25.0341 77.3324 24.6335L78.8239 26.5682H81.517L79.1648 23.5C80.9716 21.9744 82.0795 19.4858 82.0795 16.2727ZM73.3352 19.3409L75.8153 22.5966C75.321 22.7585 74.7841 22.8352 74.2216 22.8352C71.2131 22.8352 68.9716 20.5256 68.9716 16.2727C68.9716 12.0199 71.2131 9.71023 74.2216 9.71023C77.2216 9.71023 79.4716 12.0199 79.4716 16.2727C79.4716 18.6591 78.7642 20.4403 77.6051 21.5312L75.9261 19.3409H73.3352ZM85.3629 25H87.9964V18.3949H91.576C91.6357 18.3949 91.6868 18.3949 91.7464 18.3949L95.2919 25H98.2919L94.4226 17.9347C96.5618 17.142 97.6271 15.3608 97.6271 13.017C97.6271 9.80398 95.6328 7.54545 91.5845 7.54545H85.3629V25ZM87.9964 16.1278V9.80398H91.3033C93.9027 9.80398 94.9766 11.0398 94.9766 13.017C94.9766 14.9858 93.9027 16.1278 91.3374 16.1278H87.9964ZM118.996 13.0597H121.697C121.109 9.65909 118.203 7.30682 114.436 7.30682C109.919 7.30682 106.621 10.7074 106.621 16.2812C106.621 21.804 109.825 25.2386 114.555 25.2386C118.791 25.2386 121.817 22.4602 121.817 18.054V16.0682H114.956V18.2415H119.268C119.209 21.054 117.351 22.8352 114.555 22.8352C111.479 22.8352 109.237 20.5085 109.237 16.2642C109.237 12.0369 111.496 9.71023 114.453 9.71023C116.771 9.71023 118.339 10.9886 118.996 13.0597ZM126.095 25L127.697 20.3807H134.516L136.109 25H138.905L132.624 7.54545H129.581L123.3 25H126.095ZM128.464 18.1648L131.038 10.7159H131.175L133.749 18.1648H128.464ZM141.425 7.54545V25H143.931V12.3608H144.093L149.241 24.9744H151.32L156.468 12.3693H156.63V25H159.136V7.54545H155.94L150.383 21.1136H150.178L144.621 7.54545H141.425ZM162.964 25H174.01V22.733H165.598V17.3892H173.337V15.1307H165.598V9.8125H173.908V7.54545H162.964V25Z"
                  fill="#F9F9FB"
                />
              </svg>
            </router-link>
          </div>
          <b-menu class="is-custom-mobile">
            <b-menu-list>
              <div class="menu-items">
                <div class="inline absolute">
                  <discover />
                </div>

                <div class="inline">
                  <b-menu-item>
                    <template #label="props">
                      <span>Descobrir</span>
                      <b-icon
                        class="is-pulled-right"
                        :icon="props.expanded ? 'menu-up' : 'menu-down'"
                      ></b-icon>
                    </template>

                    <router-link to="/best-qualified">
                      <span>Melhores Qualificados</span></router-link
                    >
                    <router-link to="/creator-ranking">
                      <span>Criadores Populares</span></router-link
                    >
                    <router-link to="/most-played">
                      <span>Jogos Populares</span></router-link
                    >

                    <router-link to="/search">
                      <span>Pesquisa</span></router-link
                    >
                  </b-menu-item>
                </div>
              </div>
            </b-menu-list>

            <b-menu-list>
              <div class="menu-items">
                <div class="inline absolute">
                  <create-game />
                </div>

                <div class="inline">
                  <b-menu-item>
                    <template #label="props">
                      <span>Jogos</span>

                      <b-icon
                        class="is-pulled-right"
                        :icon="props.expanded ? 'menu-up' : 'menu-down'"
                      ></b-icon>
                    </template>
                    <a @click="createGame">
                      <span class="items">Criar Jogo</span></a
                    >
                    <router-link to="/my-games">
                      <span class="items">Os Meus Jogos</span></router-link
                    >
                  </b-menu-item>
                </div>
              </div>
            </b-menu-list>

            <b-menu-list>
              <div class="menu-items">
                <div class="inline absolute">
                  <bookmark />
                </div>

                <div class="inline">
                  <router-link to="/saved-games">
                    <span class="main-label">Jogos Guardados</span></router-link
                  >
                </div>
              </div>
            </b-menu-list>

            <b-menu-list>
              <div class="menu-items">
                <div class="inline absolute">
                  <user />
                </div>

                <div class="inline">
                  <router-link to="/profile">
                    <span>A Minha Conta</span></router-link
                  >
                </div>
              </div>
            </b-menu-list>

            <b-menu-list>
              <div class="logout">
                <div class="inline absolute">
                  <sign-out />
                </div>

                <div class="inline">
                  <router-link to="" @click.native="logout">
                    <span>Terminar Sessão</span></router-link
                  >
                </div>
              </div>
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </section>
  </div>
</template>

<script>
import IconifyIcon from "@iconify/vue";
import appsIcon from "@iconify-icons/tabler/apps";
import compassFill from "@iconify-icons/ri/compass-fill";
import bookmark16Filled from "@iconify-icons/fluent/bookmark-16-filled";
import bxsUser from "@iconify-icons/bx/bxs-user";
import logoutIcon from "@iconify-icons/fe/logout";
import arrowDownFill from "@iconify-icons/eva/arrow-down-fill";
import bxMenuAltLeft from "@iconify-icons/bx/bx-menu-alt-left";
/* Icons */
import discover from "../../assets/icons/discover";
import CreateGame from "../../assets/icons/create-game-menu";
import bookmark from "../../assets/icons/bookmark";
import user from "../../assets/icons/user";
import signOut from "../../assets/icons/sign-out";
import IconLogout from "../../assets/icons/logout";
import Settings from "../../assets/icons/settings.vue";
import IconCreateGame from "../../assets/icons/create-game.vue";
import { createGame } from "../CreateGame/NewGame";
import apiProfile from "../../api/profile";
export default {
  props: ["title", "logo", "dropdown"],
  components: {
    IconifyIcon,
    discover,
    CreateGame,
    bookmark,
    user,
    signOut,
    Settings,
    IconCreateGame,
    IconLogout,
  },
  data() {
    return {
      profile_picture: null,
      userId: "",
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      fullheight: true,
      open: false,
      overlay: true,
      canCancel: true,
      size: 0,
      icons: {
        compass: compassFill,
        games: appsIcon,
        bookmark: bookmark16Filled,
        user: bxsUser,
        logout: logoutIcon,
        arrowDown: arrowDownFill,
        menu: bxMenuAltLeft,
      },
    };
  },
  methods: {
    async getUserInfo() {
      apiProfile
        .getUserInfo(this.userId)
        .then((result) => {
          this.profile_picture = result.user.photo;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createGame() {
      createGame();
    },
    getWindowWidth() {
      this.size = window.innerWidth;
      if (this.size < 1024) {
        this.open = false;
        this.overlay = true;
        this.canCancel = true;
      } else if (this.size >= 1024) {
        this.open = true;
        this.overlay = false;
        this.canCancel = false;
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/auth");
    },
  },
  toggleMenu() {
    this.open = !this.open;
  },
  mounted() {
    this.userId = this.$cookies.get("userId");
    window.addEventListener("resize", this.getWindowWidth);
    this.getWindowWidth();
    this.getUserInfo();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_sidebar";
</style>
