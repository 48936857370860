const profile = {
  getUserInfo: async (userId) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/${userId}`
    );
    const responseData = await response.json();
    return responseData;
  },

  updatePassword: async (data, token) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/password`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "same-origin",
      }
    );
    const responseData = await response.json();
    return [response, responseData];
  },

  updateUserInfo: async (data, token) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/info`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "same-origin",
      }
    );
    const responseData = await response.json();
    return [response, responseData];
  },

  updateUserPhoto: async (data, token) => {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/qrgame/users/photo`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "same-origin",
      }
    );
    const responseData = await response.json();
    return [response, responseData];
  },
};

export default profile;
