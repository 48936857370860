<template>
  <section class="page-content">
    <sidebar title="Melhores Qualificados" dropdown="true"/>
    <header-desktop class="is-hidden-touch" />
    <div class="title">
      <span>Melhores Qualificados</span>
    </div>

    <div v-if="searchResult.length !== 0">
      <div class=" columns is-multiline">
        <div v-for="game in searchResult" :key="game._id">
          <div class="is-5 column">
            <base-card
              @click.native="seeGameDetails(game._id)"
              :game="game"
              :savedGames="savedGame"
              @saveGame="saveGame"
              @removeGame="removeGame"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import HeaderDesktop from "../../components/Header";
import apiBestQualified from "../../api/bestQualified";

export default {
  components: {
    Sidebar,
    HeaderDesktop,
  },
  data() {
    return {
      searchResult: [],
      savedGame: [],
      searchType: "",
      isComponentModalActive: false,
      seeDetails: true,
      userId: "",
    };
  },
  methods: {
    search() {
      apiBestQualified
        .search()
        .then((result) => {
          this.searchResult = result;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUserSavedGames() {
      apiBestQualified.getUserSavedGames(this.userId).then((result) => {
        this.savedGame = result.gameIDs;
      });
    },

    updateGamesSaved(gameId) {
      apiBestQualified.updateGamesSaved(this.userId, { gameID: gameId });
    },

    removeGame(gameId) {
      this.seeDetails = false;
      this.savedGame.splice(this.savedGame.indexOf(gameId), 1);

      this.updateGamesSaved(gameId);
    },
    saveGame(gameId) {
      this.seeDetails = false;
      this.isGameSaved = !this.isGameSaved;
      this.savedGame.push(gameId);
      this.updateGamesSaved(gameId);
    },
    seeGameDetails(gameId) {
      if (this.seeDetails) {
        this.$router.push({ path: "game-info/" + gameId });
      } else {
        this.seeDetails = !this.seeDetails;
      }
    },
  },
  created() {
    this.userId = this.$cookies.get("userId");
    this.search();
    this.getUserSavedGames();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import "../../styles/_bestQualified.scss";
</style>
