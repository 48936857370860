<template>
  <div class="user-games page-content">
    <sidebar title="Os meus jogos" logo="true" />
    <header-desktop class="is-hidden-touch" />
    <div v-if="games.length > 0">
      <p class="title-md">Continuar a criar</p>
      <div class="columns is-multiline">
        <div class="column is-4" v-for="game in games" :key="game.id">
          <card :game="game" @delete="deleteGame" @edit="editGame"></card>
        </div>
      </div>
    </div>

    <div class="my-games-tabs">
      <h4
        :class="{ 'tab-active': this.activeTab === 1 }"
        @click="setActiveTab(1)"
      >
        Os meus jogos
      </h4>
      <h4
        :class="{ 'tab-active': this.activeTab === 2 }"
        @click="setActiveTab(2)"
      >
        A decorrer
      </h4>
    </div>
    <div>
      <div v-if="activeTab === 1">
        <div class="columns is-multiline" v-if="userGames.length > 0">
          <div v-for="game in userGames" :key="game._id" class="column is-6">
            <my-games-card
              :gameName="game.name"
              :gameDuration="game.estimatedTime"
              :gameLocation="game.location"
              :gameId="game._id"
              selectedTab="my-games"
              @editGame="editGame"
              @deleteGame="deleteCreatedGame"
              @seeDetails="seeDetails"
            />
          </div>
        </div>
      </div>

      <div v-if="activeTab === 2">
        <div class="columns is-multiline">
          <div v-for="game in userGames" :key="game._id" class="column is-6">
            <my-games-card
              v-if="game.playing.length > 0"
              :gameName="game.name"
              :gameDuration="game.estimatedTime"
              :gameLocation="game.location"
              :gameId="game._id"
              selectedTab="monitor"
              @editGame="editGame"
              @deleteGame="deleteCreatedGame"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="center-img" v-if="!userGames.length > 0">
      <no-games />
      <span class="no-game">Ainda não criaste nenhum jogo!</span>
    </div>

    <div class="hint-div" v-if="!userGames.length > 0">
      <h5 class="text-hint">Clica aqui para criar um jogo</h5>
      <arrow />
    </div>
    <div class="fab-button" @click="addGame"><icon-add /></div>
  </div>
</template>

<script>
import Card from "../components/layout/card";
import Sidebar from "../components/layout/Sidebar.vue";
import MyGamesCard from "../components/my-games-card.vue";
import IconAdd from "../assets/icons/icon-add";
import Arrow from "../assets/icons/arrow.vue";
import NoGames from "../assets/icons/no-games";
import HeaderDesktop from "../components/Header";
import userRequests from "../api/UserRequests";
import GameRequests from "../api/GameRequests";
import { createGame } from "../components/CreateGame/NewGame";

export default {
  data() {
    return {
      file: {},
      activeTab: 1,
      userGames: null,
      isSeeDetails: true,
      newGame: {
        creator: {
          name: this.$cookies.get("username"),

          id: this.$cookies.get("userId"),
        },
        name: "",
        id: null,
        description: "",
        gameMode: {
          gameType: "",
          playersType: "",
          timeControl: {
            value: false,
            time: null,
          },
        },
        playersLimit: 0,
        location: "",
        estimatedTime: 0,
        gameTypesButtons: [
          {
            id: 0,
            isActive: false,
            text: "Jogo Público",
            value: "public",
          },
          {
            id: 1,
            isActive: false,
            text: "Jogo Privado",
            value: "private",
          },
        ],
        playersTypeButtons: [
          {
            id: 0,
            isActive: false,
            text: "Individual",
            value: "individual",
          },
          {
            id: 1,
            isActive: false,
            text: "Equipa",
            value: "group",
          },
          {
            id: 2,
            isActive: false,
            text: "Ambos",
            value: "both",
          },
        ],
        tags: [],
        categories: [],
        suggestedCategories: [],
        invalidStepsCounter: 0,
        mapCenter: {
          lat: null,
          lng: null,
        },
        steps: [
          {
            id: 1,
            type: { text: "Pista", value: "hint" },
            selected: true,
            isStepValid: false,
            estimatedDuration: {
              duration: null,
              formatedDuration: null,
            },
            numberOfCreditsToWin: 5,
            numberOfCreditsToLose: 5,
            qrCodeCoordinates: {
              coordinates: {
                lat: null,
                lng: null,
              },
              hasPositionChanged: false,
            },

            question: {
              question: "",
              answers: [
                {
                  optionText: "",
                  rightOption: true,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
              ],
            },
            hint: {
              text: "",
              tips: [
                {
                  text: "",
                  value: 20,
                },
              ],
            },
          },
          {
            id: 2,
            type: { text: "Pista", value: "hint" },
            selected: true,
            isStepValid: false,
            estimatedDuration: {
              duration: null,
              formatedDuration: null,
            },
            numberOfCreditsToWin: 5,
            numberOfCreditsToLose: 5,
            qrCodeCoordinates: {
              coordinates: {
                lat: null,
                lng: null,
              },
              hasPositionChanged: false,
            },

            question: {
              question: "",
              answers: [
                {
                  optionText: "",
                  rightOption: true,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
              ],
            },
            hint: {
              text: "",
              tips: [
                {
                  text: "",
                  value: 20,
                },
              ],
            },
          },
          {
            id: 3,
            type: { text: "Pista", value: "hint" },
            selected: true,
            isStepValid: false,
            estimatedDuration: {
              duration: null,
              formatedDuration: null,
            },
            numberOfCreditsToWin: 5,
            numberOfCreditsToLose: 5,
            qrCodeCoordinates: {
              coordinates: {
                lat: null,
                lng: null,
              },
              hasPositionChanged: false,
            },

            question: {
              question: "",
              answers: [
                {
                  optionText: "",
                  rightOption: true,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
              ],
            },
            hint: {
              text: "",
              tips: [
                {
                  text: "",
                  value: 20,
                },
              ],
            },
          },
          {
            id: 4,
            type: { text: "Pista", value: "hint" },
            selected: true,
            isStepValid: false,
            estimatedDuration: {
              duration: null,
              formatedDuration: null,
            },
            numberOfCreditsToWin: 5,
            numberOfCreditsToLose: 5,
            qrCodeCoordinates: {
              coordinates: {
                lat: null,
                lng: null,
              },
              hasPositionChanged: false,
            },

            question: {
              question: "",
              answers: [
                {
                  optionText: "",
                  rightOption: true,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
              ],
            },
            hint: {
              text: "",
              tips: [
                {
                  text: "",
                  value: 20,
                },
              ],
            },
          },
          {
            id: 5,
            type: { text: "Pista", value: "hint" },
            selected: true,
            isStepValid: false,
            estimatedDuration: {
              duration: null,
              formatedDuration: null,
            },
            numberOfCreditsToWin: 5,
            numberOfCreditsToLose: 5,
            qrCodeCoordinates: {
              coordinates: {
                lat: null,
                lng: null,
              },
              hasPositionChanged: false,
            },

            question: {
              question: "",
              answers: [
                {
                  optionText: "",
                  rightOption: true,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
                {
                  optionText: "",
                  rightOption: false,
                },
              ],
            },
            hint: {
              text: "",
              tips: [
                {
                  text: "",
                  value: 20,
                },
              ],
            },
          },
        ],
      },
      newGoFb_User: false, // this will be changed acording to the database info, its set has false by default
    };
  },
  components: {
    Card,
    MyGamesCard,
    Sidebar,
    IconAdd,
    Arrow,
    NoGames,
    HeaderDesktop,
  },
  computed: {
    games() {
      return this.$store.state.games.filter((game) => game.creator.id == this.$cookies.get('userId'));
    },
  },
  created() {
    this.$store.dispatch("getGames");
    userRequests.getCreatedGames(this.$cookies.get("userId")).then((game) => {
      this.userGames = game;
    });
    // when created everything, check of the user is a new google or facebook or not, if so then change that
    // the info from data base then do the if

    userRequests
      .getFirstTimeSocial(
        this.$cookies.get("userId"),
        this.$cookies.get("token")
      )
      .then((result) => {
        this.newGoFb_User = result.user.firstTimeSocial;

        if (this.newGoFb_User) {
          this.promptNew();
        }
      });

  },

  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    addGame() {
      createGame();
    },
    async deleteGame(game) {
      await this.$store.dispatch("deleteGame", game);
      this.$store.dispatch("getGames");
    },
    deleteCreatedGame(gameId) {
      GameRequests.deleteGame(gameId).then((result) => {
        if (result.message === "success") {
          let filteredGames = [];
          this.userGames = this.userGames.map((game) => {
            if (game._id !== gameId) {
              filteredGames.push(game);
            }
          });
          this.userGames = filteredGames;
          this.$buefy.toast.open({
            message: "O jogo foi apagado.",
            type: "is-primary",
          });
        } else {
          this.$buefy.toast.open({
            message:
              "Não conseguimos apagar o jogo. Tenta outra vez mais tarde",
            type: "is-primary",
          });
        }
      });
    },
    editGame(game) {
      this.$router.push({ path: `edit/${game.id}`, params: { game: game } });
    },

    seeDetails(gameId) {
      if (this.isSeeDetails) {
        this.$router.push({ path: "game-info/" + gameId });
      }
    },
    // prompt to trigger, when its a new google/facebook user
    promptNew() {
      this.$buefy.dialog.prompt({
        message: "Insira o seu novo Username",
        type: "is-primary",
        inputAttrs: {
          placeholder: "ex.. walter93",
        },
        confirmText: "Confirmar",
        trapFocus: true,
        closeOnConfirm: false,
        canCancel: false,
        onConfirm: (value, { close }) => {
          // will check if the username already exist, if it does, then doesnt close and nochances are done
          //if so then allow it to chance , chance user name and the first time variable, and set it on cookies?

          // change in to a funtion for sending the request for the db and then return if sucessfull and then close
          // dont forget to set the cookie with the new username
          let data = {
            newUsername: value,
          };
          userRequests
            .updateUsername(
              this.$cookies.get("userId"),
              data,
              this.$cookies.get("token")
            )
            .then((result) => {
              if (result.success == true) {
                this.$cookies.set("username", value);
                this.$buefy.toast.open({
                  message: `Novo username com Sucesso!`,
                  type: "is-primary",
                });
                close();
              } else {
                this.$buefy.toast.open({
                  message: `O username já existe!`,
                  type: "is-danger",
                });
              }
            })
            .catch((error) => {
              this.$buefy.toast.open({
                message: `Error encontrado: ${error}`,
                type: "is-danger",
              });
            });
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_user-games.scss";
</style>
