<template>
	<div class="card">
		<div class="card-content">
			<p class="title" v-if="game.name.length > 0">
				{{ game.name }}
			</p>
			<p class="title" v-else>
				Sem nome
			</p>

			<p class="subtitle" v-if="game.description.length > 0">
				{{ game.description }}
			</p>
			<p class="subtitle" v-else>
				Sem descrição
			</p>

			<footer class="card-footer">
				<div class="buttons">
					<b-button type="is-danger" @click="$emit('delete', game)">Apagar</b-button>
					<!--  <button class="button is-danger" @click="$emit('delete', game)">Apagar</button>
          -->
					<b-button type="is-info is-light" @click="$emit('edit', game)">Editar</b-button>
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
export default {
	props: ['game'],
};
</script>

<style>
.card .is-danger {
	color: #f14668;
	background-color: transparent;
	border: none;
}

.card .is-light {
	background-color: transparent;
	color: #296fa8;
	border-color: #296fa8;
}

.card .buttons {
	float: right !important;
	margin-top: 0.5rem;
}

.card .card-footer {
	justify-content: flex-end !important;
	border-top: none !important;
}

.card .subtitle {
	color: #1c3e7080;
	padding-top: 0.75rem;
	font-family: 'Inter', sans-serif;
	margin-bottom: 8px;
	font-size: 16px;
}

.card .title {
	color: #3158a3;
	font-size: 20px;
	font-weight: normal;
}

.card {
	background-color: #f0f3fa;
	border-radius: 0.25rem;
	box-shadow: none;
	color: #4a4a4a;
	max-width: 100%;
	position: relative;
}
</style>
