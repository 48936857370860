const popularCreated = {

    search: async () => {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/games/most-played`);
        const responseData = await response.json();
        return responseData
    },

    getUserSavedGames: async (userId) => {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${userId}/games-saved`);
        const responseData = await response.json();
        return responseData
    },

    updateGamesSaved: async (userId, data) => {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${userId}/games-saved`,
            {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            }
        );
        const responseData = await response.json();
        return responseData

    },




}

export default popularCreated;