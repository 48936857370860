<template>
  <section class="page-content">
    <sidebar title="Jogos Guardados"  />
    <header-desktop class="is-hidden-touch" />
    <div class="title is-hidden-touch" >
      <span>Jogos Guardados</span>
    </div>
        <div class='no-saved-games' v-if="searchResult.games.length == 0">
      <span>Não tem nenhuns jogos guardados!</span>
    </div>
    <div v-if="searchResult.length !== 0">
      <div class="columns is-multiline ">
        <div v-for="game in searchResult.games" :key="game._id">
          <div class="column is-5">
            <base-card
              @click.native="seeGameDetails(game._id)"
              :game="game"
              @removeGame="removeGame"
              :savedGames="savedGame"
             
        
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import HeaderDesktop from "../../components/Header";
export default {
  components: {
    Sidebar,
    HeaderDesktop,
  },
  data() {
    return {
      searchResult: [],
      savedGame: [],
      searchType: "",
      isComponentModalActive: false,
      seeDetails: true,
      userId: "",
    };
  },
  methods: {
    async getUserSavedGames() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/${this.userId}/games-saved`
      );

      let responseData = await response.json();
      this.searchResult = responseData;
      this.savedGame = this.searchResult.gameIDs;
      console.log(this.searchResult);
   
    },
    async updateGamesSaved(gameId) {
      await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/${this.userId}/games-saved`,
        {
          method: "PUT",
          body: JSON.stringify({ gameID: gameId }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      );
    },
    removeGame(gameId) {
      this.seeDetails = false;
      this.searchResult.games.splice(
        this.searchResult.gameIDs.indexOf(gameId),
        1
      );
      this.updateGamesSaved(gameId);
    },
    seeGameDetails(gameId) {
      if (this.seeDetails) {
        this.$router.push({ path: "game-info/" + gameId });
      } else {
        this.seeDetails = !this.seeDetails;
      }
    },
    
  },
  created() {

    this.userId = this.$cookies.get("userId");

    this.getUserSavedGames();
  },
 
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import "../../styles/_savedGames.scss";

</style>
