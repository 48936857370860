import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",

  ctr() {
    return window.google.maps.DirectionsRenderer;

  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: Object },
    destination: { type: Object },
    travelMode: { type: String }
  },


  afterCreate(directionsRenderer) {
    let { origin, destination, travelMode } = this;
    //console.log(origin, destination)
    let directionsService = new window.google.maps.DirectionsService();

    this.$watch(
     /*  () => [origin,destination, travelMode], */
      () => {
        //let { origin, destination, travelMode } = this;

        directionsService.route(
          {
            origin,
            destination,
            travelMode
          },
          (response, status) => {
            if (status !== "OK") return;
            console.log(response);
            directionsRenderer.setDirections(response);
          }
        );
      }
    );

    /* directionsService.route(
      {
        origin,
        destination,
        travelMode
      },
      (response, status) => {
        console.log(response);
        if (status !== "OK") return;
        directionsRenderer.setDirections(response);
      }
    ); */
  }
});
