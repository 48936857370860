<template>
  <div class="estimated">
    <header-mobile class="is-hidden-desktop" :route="true" title="">
      <b-dropdown
        class="block"
        v-model="currentOption"
        @change="onOptionChange($event)"
        aria-role="list"
        :mobile-modal="false"
      >
        <template class="block" #trigger>
          <button class="dropdown">
            <icon-pin v-if="currentOption.text === 'Pista'" />
            <icon-multiple-choice v-else />
            <span class="btn-text"> {{ currentOption.text }}</span>
            <iconify-icon :icon="arrow" height="16" class="arrow" />
          </button>
        </template>

        <div id="test">
          <b-dropdown-item
            v-for="(menu, index) in menus"
            :key="index"
            :value="menu"
            aria-role="listitem"
          >
            <div class="media" v-if="menu.text === 'Pista'">
              <icon-pin />
              <div class="media-content">
                <h3>{{ menu.text }}</h3>
              </div>
            </div>
            <div class="media" v-else>
              <icon-multiple-choice />
              <div class="media-content">
                <h3>{{ menu.text }}</h3>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </header-mobile>
    <div class="is-hidden-touch">
      <b-field label="Tipo de Etapa"> </b-field>
      <b-dropdown
        class="block"
        v-model="currentOption"
        @change="onOptionChange($event)"
        aria-role="list"
      >
        <template class="block" #trigger>
          <button class="dropdown">
            <icon-pin v-if="currentOption.text === 'Pista'" />
            <icon-multiple-choice v-else />
            <span class="btn-text"> {{ currentOption.text }}</span>
            <iconify-icon :icon="arrow" height="16" class="arrow" />
          </button>
        </template>

        <div id="test">
          <b-dropdown-item
            v-for="(menu, index) in menus"
            :key="index"
            :value="menu"
            aria-role="listitem"
          >
            <div class="media" v-if="menu.text === 'Pista'">
              <icon-pin />
              <div class="media-content">
                <h3>{{ menu.text }}</h3>
              </div>
            </div>
            <div class="media" v-else>
              <icon-multiple-choice />
              <div class="media-content">
                <h3>{{ menu.text }}</h3>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </div>

    <!--  <p class="label block">Estimativa de duração</p>
    <b-clockpicker
      rounded
      inline
      hour-format="24"
      :min-time="minTime"
      :max-time="maxTime"
      v-model="duration"
      icon="clock"
    >
    </b-clockpicker> -->

    <div v-if="currentOption.text == 'Pergunta'">
      <div class="is-hidden-mobile">
        <p class="label">Créditos a ganhar</p>
        <div class="counter-container">
          <button class="counter-button" @click="decrementCreditsToWin">
            -
          </button>
          <input
            class="counter"
            placeholder="5"
            min="5"
            max="10"
            type="number"
            v-model="numberOfCreditsToWin"
            disabled
          />
          <button class="counter-button" @click="incrementCreditsToWin">
            +
          </button>
        </div>
        <p class="label">Créditos a perder</p>
        <div class="counter-container">
          <button class="counter-button" @click="decrementCreditsToLose">
            -
          </button>
          <input
            class="counter"
            placeholder="5"
            min="5"
            max="10"
            type="number"
            v-model="numberOfCreditsToLose"
            disabled
          />
          <button class="counter-button" @click="incrementCreditsToLose">
            +
          </button>
        </div>
      </div>
    </div>

    <div v-if="currentOption.text == 'Fotografia'">
      <div class="is-hidden-mobile">
        <p class="label">Créditos a ganhar fotografia</p>
        <div class="counter-container">
          <button class="counter-button" @click="decrementCreditsToWin">
            -
          </button>
          <input
            class="counter"
            placeholder="5"
            min="5"
            max="10"
            type="number"
            v-model="numberOfCreditsToWin"
            disabled
          />
          <button class="counter-button" @click="incrementCreditsToWin">
            +
          </button>
        </div>
        <p class="label">Créditos a perder</p>
        <div class="counter-container">
          <button class="counter-button" @click="decrementCreditsToLose">
            -
          </button>
          <input
            class="counter"
            placeholder="5"
            min="5"
            max="10"
            type="number"
            v-model="numberOfCreditsToLose"
            disabled
          />
          <button class="counter-button" @click="incrementCreditsToLose">
            +
          </button>
        </div>
      </div>
    </div>

    <div class="is-hidden-desktop">
      <div class="columns is-mobile">
        <div class="column">
          <p class="label">Créditos a ganhar</p>
          <div class="counter-container">
            <button class="counter-button" @click="decrementCreditsToWin">
              -
            </button>
            <input
              class="counter"
              placeholder="5"
              min="5"
              max="10"
              type="number"
              v-model="numberOfCreditsToWin"
              disabled
            />
            <button class="counter-button" @click="incrementCreditsToWin">
              +
            </button>
          </div>
        </div>

        <div class="column">
          <p class="label">Créditos a perder</p>
          <div class="counter-container">
            <button class="counter-button" @click="decrementCreditsToLose">
              -
            </button>
            <input
              class="counter"
              placeholder="5"
              min="5"
              max="10"
              type="number"
              v-model="numberOfCreditsToLose"
              disabled
            />
            <button class="counter-button" @click="incrementCreditsToLose">
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPin from "../../assets/icons/icon-pin";
import IconMultipleChoice from "../../assets/icons/icon-multiple-choice";
import IconifyIcon from "@iconify/vue";
import arrowDownFill from "@iconify-icons/eva/arrow-down-fill";
import HeaderMobile from "../layout/header-mobile";

export default {
  props: [
    /* "estimatedDuration", */
    "numberOfCreditsToLose",
    "numberOfCreditsToWin",
    "currentOption",
  ],
  emits: [
    /* "newDuration", */ "setCreditsToWin",
    "setCreditsToLose",
    "stepType",
  ],
  components: {
    IconPin,
    IconMultipleChoice,
    IconifyIcon,
    HeaderMobile,
  },
  data() {
    /*   const min = new Date();
    min.setHours(0);
    min.setMinutes(0);
    min.setSeconds(0);
    const max = new Date();
    max.setHours(0);
    max.setMinutes(59);
    max.setSeconds(0); */

    return {
      arrow: arrowDownFill,
      menus: [
        { text: "Pista", value: "hint" },
        { text: "Pergunta", value: "question" },
        { text: "Fotografia", value: "photo" },
      ],
      /*   minTime: min,
      maxTime: max,
      duration: this.estimatedDuration, */
    };
  },
  watch: {
    /*  duration(value) {
      this.$emit("newDuration", {
        duration: value,
        formatedDuration:
          (value.getMinutes() < 10 ? "0" : "") + value.getMinutes(),
      });
    }, */

    numberOfCreditsToWin(event) {
      this.$emit("setCreditsToWin", event);
    },

    numberOfCreditsToLose(event) {
      this.$emit("setCreditsToLose", event);
    },
  },

  methods: {
    nextStep() {
      this.gameDetails.invalidStepsCounter = 0;
      this.gameDetails.steps.map((step) => {
        if (!step.isStepValid) {
          this.gameDetails.invalidStepsCounter++;
        }
      });
      if (this.gameDetails.invalidStepsCounter == 0) {
        this.inactiveNavigation = false;
        this.saveGameInfo(2);
      }
    },
    previousStep() {
      this.saveGameInfo(0);
    },

    saveGameInfo(goToStep) {
      // prepare game data to be sent to the db
      //this.stepsFormated = [];
      this.stepsFormated = this.gameDetails.steps.map((step) => {
        if (step.type.value === "hint") {
          return {
            type: step.type.value,
            text: step.hint.text,
            tips: step.hint.tips,
            //estimatedTime: step.estimatedDuration.formatedDuration * 60,
            qrCodeCoordinates: {
              lat: step.qrCodeCoordinates.coordinates.lat,
              lng: step.qrCodeCoordinates.coordinates.lng,
            },
          };
        } else if (step.type.value === "question") {
          return {
            type: step.type.value,
            question: step.question.question,
            answers: step.question.answers,
            creditsToWin: step.numberOfCreditsToWin,
            creditsToLose: step.numberOfCreditsToLose,
            //estimatedTime: step.estimatedDuration.formatedDuration * 60,
          };
        } else {
          return {
            type: step.type.value,
            text: step.photo.text,
            qrCodeCoordinates: {
              lat: step.qrCodeCoordinates.coordinates.lat,
              lng: step.qrCodeCoordinates.coordinates.lng,
            },
          };
        }
      });

      this.formatedGame = {
        details: this.formatedDetails,
        steps: this.stepsFormated,
      };

      this.$emit("clicked", {
        step: goToStep,
        gameDetails: this.gameDetails,
        formatedGame: this.formatedGame,
      });
    },

    onOptionChange(event) {
      this.$emit("stepType", event.value);
    },

    incrementCreditsToWin() {
      this.numberOfCreditsToWin++;
      if (this.numberOfCreditsToWin > 10) {
        this.numberOfCreditsToWin = 10;
      }
    },

    decrementCreditsToWin() {
      this.numberOfCreditsToWin--;
      if (this.numberOfCreditsToWin < 5) {
        this.numberOfCreditsToWin = 5;
      }
    },

    incrementCreditsToLose() {
      this.numberOfCreditsToLose++;
      if (this.numberOfCreditsToLose > 10) {
        this.numberOfCreditsToLose = 10;
      }
    },

    decrementCreditsToLose() {
      this.numberOfCreditsToLose--;
      if (this.numberOfCreditsToLose < 5) {
        this.numberOfCreditsToLose = 5;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_step-option";
</style>
