<template>
  <section>
    <header-desktop class="is-hidden-touch" />

    <div class="page-content" v-if="inviteMembers === false">
      <header-mobile :route="true" title="" />
      <sidebar class="is-hidden-touch" />
      <div class="columns is-mobile game-info">
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <div
          class="column is-full-desktop is-full-laptop is-full-tablet is-full-mobile"
          v-if="!isLoading"
        >
          <span class="main-title">{{ receivedGame.name }}</span
          ><br />

          <div
            class="details-card"
            @click="
              getCreatorInfo(receivedGame.creator.id);
              openCreatorInfo();
            "
          >
            <icon-individual class="creator-name" /><span class="details">{{
              receivedGame.creator.name
            }}</span>
          </div>
          <div class="details-card">
            <icon-location-filled class="location" /><span class="details">{{
              receivedGame.location
            }}</span>
          </div>

          <div class="details-card">
            <icon-clock-filled class="time" /><span class="details">{{
              receivedGame.estimatedTime
            }}</span>
          </div>
          <div class="details-card">
            <icon-rating-filled class="rating" /><span class="details">{{
              receivedGame.rating
            }}</span>
          </div>

          <div
            v-if="
              receivedGame.creator.id === userId &&
              receivedGame.gameMode.gameType === 'private'
            "
            class="description-title"
          >
            Convidar Amigo(s)
          </div>
          <div
            v-if="
              receivedGame.creator.id === userId &&
              receivedGame.gameMode.gameType === 'private'
            "
            class="description"
          >
            <b-field>
              <b-input
                v-model="emails"
                placeholder="example@email.com example1@email.com"
                type="textarea"
              ></b-input>
            </b-field>
            <button @click="inviteFriends">Convidar</button>
          </div>

          <div class="bookmark-display">
            <div class="description-title">Descrição</div>
            <div
              v-if="savedGame.includes(receivedGame._id)"
              class="title-bookmark"
            >
              <span @click="removeGame(receivedGame._id)"
                ><bookmark-filled
              /></span>
            </div>
            <div v-else class="title-bookmark">
              <span @click="saveGame(receivedGame._id)"><bookmark /></span>
            </div>
          </div>

          <div class="description">{{ receivedGame.description }}</div>
          <div class="description-title">Dificuldade</div>

          <div v-if="chart">
            <dl>
              <span class="difficulty">{{ receivedGame.dificulty }}</span>
              <div style="display: inline">
                <dd class="percentage-very-hard" :class="getPercentage[4]">
                  <span class="text">5</span>
                </dd>
                <dd class="percentage-hard" :class="getPercentage[3]">
                  <span class="text">4</span>
                </dd>
                <dd class="percentage-medium" :class="getPercentage[2]">
                  <span class="text">3</span>
                </dd>
                <dd class="percentage-easy" :class="getPercentage[1]">
                  <span class="text">2</span>
                </dd>
                <dd class="percentage-very-easy" :class="getPercentage[0]">
                  <span class="text">1</span>
                </dd>
              </div>
            </dl>
          </div>
          <div class="button-div">
            <div v-if="receivedGame.creator.id !== userId" class="button-div">
              <button class="is-hidden-desktop" @click="playGame">
                <play-icon />
                Jogar</button
              ><br />
              <span class="error-info" v-show="isPlayed"
                >Já jogou este jogo, não pode voltar a jogar!</span
              >
              <span class="error-info" v-show="isPlaying"
                >Já se encontra a jogar um jogo!</span
              >
            </div>
            <div class="ranking-container">
              <div class="description-title">
                <span>Ranking</span>
              </div>
              <div class="ranking-card">
                <div v-if="playersRanking.length > 0">
                  <ul
                    v-for="(player, index) in playersRanking.slice(0, 9)"
                    :key="player._id"
                  >
                    <li>
                      <div>
                        <gold-medal class="first" v-if="index === 0" />
                        <silver-medal class="second" v-else-if="index === 1" />
                        <bronze-medal class="third" v-else-if="index === 2" />
                        <div v-else class="position">
                          <span class="position-number">{{ index + 1 }}</span>
                        </div>
                        <div class="name-container">
                          <span class="player-name">{{ player.name }}</span>
                          <span class="points">{{ player.points }}pts</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else class="no-results">
                  <span class="player-name">Sem Resultados!</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          v-model="isComponentModalActive"
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-modal
          :can-cancel="true"
          :has-modal-card="true"
        >
          <template>
            <div class="modal-card" style="width: auto">
              <section class="modal-card-body">
                <span class="title">Introduza o nome do grupo</span>
                <input
                  class="text-modal"
                  v-validate="'required'"
                  name="groupName"
                  type="text"
                  v-model="groupName"
                />
                <p v-show="errors.has('groupName')" class="error-info">
                  Introduza o nome do grupo!
                </p>
                <button class="modal-create" @click="createGroup">Criar</button>
              </section>
            </div>
          </template>
        </b-modal>

        <b-modal
          v-model="isSelectModeModalActive"
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-modal
          :can-cancel="true"
          :has-modal-card="true"
        >
          <template>
            <div class="modal-card" style="width: auto">
              <section class="modal-card-body">
                <span class="title">Em que modo quer jogar este jogo?</span
                ><br />
                <button class="modal-individual" @click="selectMode(1)">
                  Individual
                </button>
                <button class="modal-team" @click="selectMode(2)">
                  Equipa
                </button>
              </section>
            </div>
          </template>
        </b-modal>

        <div class="creator-info" v-if="isVisible == true">
          <div class="info-container">
            <div @click="closeCreatorInfo">
              <icon-close class="close" />
            </div>
            <div class="creator-name-container">
              <p class="name-label">{{ creatorResult[0].creator.name }}</p>
            </div>
            <div>
              <div v-if="creatorResult.length !== 0" class="title-container">
                <span class="title-top-games"
                  >Melhores jogos deste criador</span
                >
              </div>
              <div v-if="creatorResult.length !== 0">
                <ul
                  v-for="creator in creatorResult.slice(0, 3)"
                  :key="creator._id"
                >
                  <li class="creator-top-games">
                    <base-card
                      class="creator-top-games-card"
                      @click.native="seeGameDetails(creator._id)"
                      :game="creator"
                      :savedGames="savedGame"
                      @saveGame="saveGame"
                      @removeGame="removeGame"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconLocationFilled from "../../assets/icons/icon-location-filled";
import IconClockFilled from "../../assets/icons/icon-clock-filled";
import IconRatingFilled from "../../assets/icons/icon-rating-filled";
import IconIndividual from "../../assets/icons/icon-individual";
import playIcon from "../../assets/icons/play.vue";
import HeaderMobile from "../../components/layout/header-mobile";
import Sidebar from "../../components/layout/Sidebar.vue";
import HeaderDesktop from "../../components/Header";
import goldMedal from "../../assets/icons/gold-medal";
import silverMedal from "../../assets/icons/silver-medal";
import bronzeMedal from "../../assets/icons/bronze-medal";
import apiInviteToPrivateGame from "../../api/InviteToPrivateGame";
import Bookmark from "../../assets/icons/bookmark-outline";
import BookmarkFilled from "../../assets/icons/bookmark-filled";
import apiCreatorRanking from "../../api/creatorRanking";
import iconClose from "../../assets/icons/icon-close";
/* import Sidebar from "../../components/layout/Sidebar"; */
export default {
  props: ["savedGames"],
  components: {
    playIcon,
    HeaderMobile,
    IconLocationFilled,
    IconClockFilled,
    IconRatingFilled,
    IconIndividual,
    HeaderDesktop,
    goldMedal,
    silverMedal,
    Sidebar,
    bronzeMedal,
    Bookmark,
    BookmarkFilled,
    iconClose,
    /*  Sidebar */
  },
  data() {
    return {
      isLoading: true,
      isComponentModalActive: false,
      isSelectModeModalActive: false,
      groupName: "",
      gameId: "",
      groupId: "",
      inviteMembers: false,
      receivedGame: [],
      chart: false,
      organizedDifficulty: [],
      getPercentage: [],
      gameType: "",
      gamesPlayed: null,
      isPlayed: false,
      playersRanking: [],
      emails: null,
      userId: null,
      isPlaying: false,
      searchResult: [],
      savedGame: [],
      isVisible: false,
      creatorResult: [],
      seeDetails: true,
    };
  },
  methods: {
    async createGroup() {
      this.$validator.validateAll().then(async (valid) => {
        if (valid) {
          let data = {
            name: this.groupName,
            leaderID: this.$cookies.get("userId"),
            gameID: this.gameId,
            members: [
              {
                username: this.$cookies.get("username"),
                id: this.$cookies.get("userId"),
              },
            ],
          };
          await fetch(`${process.env.VUE_APP_API_URL}/qrgame/groups`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "same-origin",
          })
            .then((response) => response.json())
            .then((data) => {
              this.groupId = data._id;
              this.playGame();
            });
          this.isComponentModalActive = false;
          this.inviteMembers = true;
        }
      });
    },
    async playGame() {
      const userData = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/${this.userId}`
      );
      let receivedUserData = await userData.json();

      let gameExists;
      this.gamesPlayed.map((game) => {
        gameExists = game.members.find(
          (user) => user.id === this.$cookies.get("userId")
        );
      });

      if (receivedUserData.user.playingNow !== "") {
        this.isPlaying = true;
      } else if (gameExists) {
        this.isPlayed = true;
      } else {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/qrgame/games/groups/${
            this.gameId
          }/?userID=${this.$cookies.get("userId")}`
        );
        let receivedData = await response.json();

        if (this.receivedGame.gameMode.playersType === "group") {
          if (receivedData.length > 0) {
            this.$router.push({
              path: "/invite-members/" + receivedData[0]._id,
            });
            this.inviteMembers = true;
            this.inviteMembers = true;
          } else {
            this.isComponentModalActive = true;
          }
        } else if (this.receivedGame.gameMode.playersType === "both") {
          if (receivedData.length > 0) {
            this.inviteMembers = true;
            this.$router.push({
              path: "/invite-members/" + receivedData[0]._id,
            });
          } else {
            this.isSelectModeModalActive = true;
          }
        } else if (this.receivedGame.gameMode.playersType === "individual") {
          this.$router.push({
            name: `play-game`,
            query: {
              game: this.gameId,
              gameType: "individual",
            },
          });
        }
      }
    },
    async getGameDetails() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/games/${this.gameId}`
      );
      this.receivedGame = await response.json();

      this.gamesPlayed = this.receivedGame.usersThatPlayed;
      this.playersRanking = this.receivedGame.ranking;
      this.organizedDifficulty = this.receivedGame.organizedDifficulty;
      this.organizedDifficulty.map((value, index) => {
        this.getPercentage[index] = `percentage-${value}`;
      });

      this.chart = true;
      this.isLoading = false;
    },
    async selectMode(value) {
      if (value === 1) {
        this.$router.push({
          path: "/play-game",
          query: {
            game: this.gameId,
            gameType: "individual",
          },
        });
      } else {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/qrgame/games/groups/${
            this.gameId
          }/?userID=${this.$cookies.get("userId")}`
        );
        let receivedData = await response.json();
        if (receivedData.length > 0) {
          this.inviteMembers = true;
        } else {
          this.isSelectModeModalActive = false;
          this.isComponentModalActive = true;
        }
      }
    },
    inviteFriends() {
      let data = {
        gameID: this.gameId,
        gameName: this.receivedGame.name,
        creatorName: this.receivedGame.creator.name,
        emails: this.emails,
      };
      apiInviteToPrivateGame
        .invite(data)
        .then(() => {
          this.$buefy.toast.open({
            message: "Convites enviados com sucesso!",
            type: "is-primary",
          });
          this.emails = null;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getUserSavedGames() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/${this.userId}/games-saved`
      );

      let responseData = await response.json();
      this.searchResult = responseData;
      this.savedGame = this.searchResult.gameIDs;
    },
    async updateGamesSaved(gameId) {
      await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/users/${this.userId}/games-saved`,
        {
          method: "PUT",
          body: JSON.stringify({ gameID: gameId }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      );
    },
    removeGame(gameId) {
      this.seeDetails = false;
      this.savedGame.splice(this.savedGame.indexOf(gameId), 1);
      this.updateGamesSaved(gameId);
    },
    saveGame(gameId) {
      this.seeDetails = false;
      this.isGameSaved = !this.isGameSaved;
      this.savedGame.push(gameId);
      this.updateGamesSaved(gameId);
    },
    getCreatorInfo(value) {
      apiCreatorRanking
        .getCreatorInfo(value)
        .then((result) => {
          this.creatorResult = result;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openCreatorInfo() {
      setTimeout(() => (this.isVisible = !this.isVisible), 150);
    },
    closeCreatorInfo() {
      this.isVisible = !this.isVisible;
    },
    seeGameDetails(gameId) {
      if (this.seeDetails) {
        this.$router.push({ path: "game-info/" + gameId });
      } else {
        this.seeDetails = !this.seeDetails;
      }
    },
  },
  created() {
    this.gameId = this.$route.params.id;
    this.getGameDetails();
    this.userId = this.$cookies.get("userId");
    this.getUserSavedGames();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_gameDetails";
</style>
