<template>
  <div class="bottom-nav-container">
    <transition name="expand">
      <div class="panel-container" v-if="showPanel">
        <div class="link-align">
          <router-link to="/best-qualified" @click.native="togglePanel"
            ><span class="link">Melhores Qualificados</span></router-link
          >
          <br />
          <router-link to="/creator-ranking" @click.native="togglePanel"
            ><span class="link">Criadores Populares</span></router-link
          >
          <br />
          <router-link to="/most-played" @click.native="togglePanel"
            ><span class="link">Jogos Populares</span></router-link
          >
        </div>
      </div>
    </transition>

    <div class="icons-container">
      <svg
        width="34"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="non-active"
        @click="togglePanel"
        :class="{
          active:
            $route.path == '/most-played' ||
            $route.path == '/creator-ranking' ||
            $route.path == '/best-qualified' ||
            showPanel == true,
        }"
      >
        <path
          d="M0 13.7143H14.3673V0H0V13.7143ZM4.78912 4.57143H9.57823V9.14286H4.78912V4.57143ZM19.1565 0V13.7143H33.5238V0H19.1565ZM28.7347 9.14286H23.9456V4.57143H28.7347V9.14286ZM0 32H14.3673V18.2857H0V32ZM4.78912 22.8571H9.57823V27.4286H4.78912V22.8571ZM31.1292 18.2857H33.5238V27.4286H26.3401V25.1429H23.9456V32H19.1565V18.2857H26.3401V20.5714H31.1292V18.2857ZM31.1292 29.7143H33.5238V32H31.1292V29.7143ZM26.3401 29.7143H28.7347V32H26.3401V29.7143Z"
          fill="#F9F9FB"
        />
      </svg>
      <router-link to="/search" class="non-active"><discover /></router-link>
      <router-link to="/join-group" class="non-active"><scan /></router-link>
      <router-link
        to="/my-games"
        :class="{
          active: $route.path == '/' || $route.path == '/home',
        }"
        class="non-active"
        ><create-game
      /></router-link>
      <router-link to="/saved-games" class="non-active"
        ><bookmark
      /></router-link>
    </div>
  </div>
</template>

<script>
import discover from "../../assets/icons/discover";
import createGame from "../../assets/icons/create-game-menu";
import scan from "../../assets/icons/scan-menu";
import bookmark from "../../assets/icons/bookmark";

export default {
  components: {
    discover,
    createGame,
    scan,
    bookmark,
  },
  data() {
    return {
      showPanel: false,
    };
  },
  methods: {
    gotToPage(page) {
      this.$router.push(page);
    },
    togglePanel() {
      this.showPanel = !this.showPanel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_bottomNav";
</style>
