<template id="container">
  <qrcode-stream
    id="stream"
    @init="onInit"
    @detect="onDetect"
    v-if="!destroyed"
  >
    <b-loading :is-full-page="isFullPage" v-model="loading"></b-loading>

    <div class="close-icon" @click="$parent.close()" v-if="!loading">
      <icon-close />
    </div>

    <div class="info-message" v-if="!loading">
      <p class="message-content">
        {{ displayMessage }}
      </p>
    </div>
  </qrcode-stream>

  <!-- <a-scene
      vr-mode-ui="enabled: false;"
      renderer="logarithmicDepthBuffer: true;"
      embedded
      arjs="trackingMethod: best; sourceType: webcam;debugUIEnabled: false;"
    >
      <a-nft
        type="nft"
        url="../../assets/arjs/nft-aframe/qrcode"
        smooth="true"
        smoothCount="10"
        smoothTolerance=".01"
        smoothThreshold="5"
      >
        <a-entity
          id="model"
          gltf-model="../../assets/arjs/totemModel.gltf"
          scale="5 5 5"
          animation="property: rotation; to: 0 360 0; loop: true; dur: 5000; easing: linear"
        >
        </a-entity>
      </a-nft>

      <a-entity camera></a-entity>
    </a-scene> -->
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import IconClose from "../../assets/icons/icon-close";
import { io } from "socket.io-client";
/* import Vue from "vue";
import VueMeta from "vue-meta";
import "@ar-js-org/ar.js/"; */

/* Vue.use(VueMeta); */

export default {
  components: {
    QrcodeStream,
    IconClose,
  },

  props: ["currentStep", "gameId"],

  data() {
    return {
      loading: false,
      isFullPage: true,
      destroyed: false,
      wrongGame: false,
      wrongStep: false,
      socket: io(`${process.env.VUE_APP_API_URL}`, {
        cors: {
          origin: "*",
        },
      }),
      displayMessage: "Aponta a câmara para o código QR",
      receivedGame: null,
    };
  },

    /* metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0",
      },
    ],
  }, */

  methods: {
    async onDetect(promise) {
      try {
        const content = await promise;

        const codeData = JSON.parse(content.content);

        const gameSteps = this.receivedGame.steps;

        const data = {
          gameID: this.gameId,
          step: this.currentStep,
          index: codeData.index + 1,
        };

        if (gameSteps.find((step) => step.stepCode === codeData.stepCode)) {
          if (this.currentStep === codeData.index) {
            this.$emit("stepDetails", data);
            this.$parent.close();
          } else {
            this.displayMessage = "Este código Qr não pertence a esta etapa";
          }
        } else {
          this.displayMessage = "Este código Qr não pertence a este jogo";
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onInit(promise) {
      // show loading indicator
      this.loading = true;
      try {
        await promise;

        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
        this.loading = false;
      }
    },
    async getGameDetails() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/qrgame/games/${this.gameId}`
      );
      this.receivedGame = await response.json();
    },
  },
  created() {
    this.getGameDetails();
  },
};
</script>

<style>
#container {
  margin: 0;
  overflow: hidden;
}

/* #arjs-video {
  position: fixed !important;
  z-index: 100 !important;
  margin-left: 0 !important;
} */

.info-message {
  padding: 5%;
  width: calc(100% - 32px);
  margin: 16px;
  bottom: 0;
  position: absolute;
  background-color: #00000066;
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.message-content {
  color: #fff;
  text-align: -webkit-center;
  font-size: 14px;
}

.close-icon {
  background-color: #00000066;
  backdrop-filter: blur(20px);
  border-radius: 50px;
  padding: 4px;
  width: 32px;
  height: 32px;
  text-align: -webkit-center;
  vertical-align: middle;
  margin: 16px;
}
</style>
