<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="loading"></b-loading>

    <div v-show="!loading" class="camera-box">
      <video v-show="!isPhotoTaken" ref="camera" autoplay id="video"></video>

      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
    </div>

    <div
      class="close-icon"
      @click="$parent.close()"
      v-if="isCameraOpen && !loading"
    >
      <icon-close />
    </div>

    <div v-if="isCameraOpen && !loading" class="camera-shoot">
      <button type="button" class="button" @click="takePhoto">
        <img
          src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
        />
      </button>

      <div v-if="isPhotoTaken && isCameraOpen" class="camera-upload">
        <a
          id="sendPhotoButton"
          class="button"
          role="button"
          @click="uploadImage"
          >Send Picture</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "../../assets/icons/icon-close";
import { io } from "socket.io-client";
import { fb } from "../../firebase/firebase";
export default {
  components: {
    IconClose,
  },

  props: ["currentStep", "gameId"],

  data() {
    return {
      loading: false,
      isFullPage: true,
      socket: io(`${process.env.VUE_APP_API_URL}`, {
        cors: {
          origin: "*",
        },
      }),
      isCameraOpen: true,
      isPhotoTaken: false,
      isShotPhoto: false,
    };
  },

  methods: {
    createCameraElement() {
      this.loading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.loading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      let video = document.getElementById("video");

      this.$refs.canvas.height = video.videoHeight;
      this.$refs.canvas.width = video.videoWidth;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
    },

    uploadImage() {
      let data = {
        gameID: this.gameId,
        step: this.currentStep,
        index: this.currentStep + 1,
      };

      const username = this.$cookies.get("username");
      const userId = this.$cookies.get("userId");

      const photo = document.getElementById("photoTaken");

      photo.toBlob((blob) => {
        let image = new Image();
        image.src = blob;
        const storageRef = fb
          .storage()
          .refFromURL(
            `gs://qrgame-fe8b2.appspot.com/PhotoGameMode/${
              this.gameId
            }/${username}_${userId}/Etapa nº${this.currentStep + 1}`
          );
        let uploadTask = storageRef.put(blob);

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            console.error(error);
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(() => {
                this.isCameraOpen = false;
                this.$emit("stepDetails", data);
                this.$parent.close();
              })
              .catch((error) => {
                console.error(error);
              });
          }
        );
      });
    },
  },
  created() {
    this.createCameraElement();
  },
};
</script>

<style lang="scss">
video {
  left: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
}

canvas {
  left: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
}

.camera-shoot {
  display: flex;
  justify-content: center;

  #sendPhotoButton {
    position: absolute;
    bottom: 2.5rem;
    right: 3rem;
  }

  button {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    position: absolute;
    bottom: 2rem;

    img {
      height: 35px;
      object-fit: cover;
    }
  }
}

.close-icon {
  background-color: #00000066;
  backdrop-filter: blur(20px);
  border-radius: 50px;
  padding: 4px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
</style>
