<template>
  <div class="game-card">
    <div class="game-card-header">
      <p>{{ gameName }}</p>
      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        :mobile-modal="false"
        :triggers="['click']"
        v-if="selectedTab !== 'monitor'"
      >
        <template #trigger>
          <icon-options-menu />
        </template>

        <b-dropdown-item aria-role="listitem">
          <router-link :to="{ path: '/update/' + this.gameId }" append
            ><a>Editar</a></router-link
          ></b-dropdown-item
        >

        <b-dropdown-item aria-role="listitem">
          <a @click="deleteGame">Apagar Jogo</a>
        </b-dropdown-item>

        <b-dropdown-item aria-role="listitem">
          <a @click="seeDetails">Ver Detalhes</a>
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        :mobile-modal="false"
        :triggers="['click']"
        v-if="selectedTab === 'monitor'"
      >
        <template #trigger>
          <icon-options-menu />
        </template>

        <b-dropdown-item aria-role="listitem">
          <router-link :to="{ path: '/monitor-game/' + this.gameId }" append
            ><a>Monitorizar</a></router-link
          ></b-dropdown-item
        >
      </b-dropdown>
    </div>

    <div class="game-card-content">
      <span class="game-card-body"
        ><icon-location class="card-icon" /> {{ gameLocation }}</span
      >
      <span class="game-card-body"
        ><icon-clock class="card-icon" /> {{ gameDuration }}</span
      >
    </div>
  </div>
</template>
<script>
import IconOptionsMenu from "../assets/icons/icon-options-menu";
import IconLocation from "../assets/icons/icon-location";
import IconClock from "../assets/icons/icon-clock";
//import GameRequest from '../api/GameRequests'
export default {
  props: ["gameName", "gameLocation", "gameDuration", "gameId", "selectedTab"],
  components: {
    IconOptionsMenu,
    IconLocation,
    IconClock,
  },

  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    monitorGame() {
      this.$router.push({ name: "monitor-game/12345" });
    },
    deleteGame() {
      //GameRequest.deleteGame(this.gameId)
      this.$emit("deleteGame", this.gameId);
    },

    seeDetails() {
      //GameRequest.deleteGame(this.gameId)
      this.$emit("seeDetails", this.gameId);
    },
  },
};
</script>

<style>
.dropdown-content {
  background-color: #f9f9fb !important;
}
.game-card {
  width: 100%;
  height: auto;
  background-color: #f0f3fa;
  border-radius: 10px;
  padding: 10px 23px;
}
.game-card-header {
  display: flex;
  place-content: space-between;
  color: #3158a3;
  font-size: 20px;
}
.game-card-body {
  padding-right: 12px;
  color: #7186a7;
}
.game-card-content {
  margin: 16px 0px 8px 0px;
}

.game-card-header svg {
  cursor: pointer;
}
.game-card .game-card-content svg {
  transform: translateY(1px);
  margin-right: 4px;
}
.game-card .dropdown {
  width: auto !important;
  text-align: right;
}
a.dropdown-item,
.dropdown .dropdown-menu .has-link a,
button.dropdown-item {
  text-align: left !important;
}
</style>
