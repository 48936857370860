<template>
  <section class="page-content">
    <sidebar class="is-hidden-touch" />
    <header-mobile :route="true" class="is-hidden-desktop" />
    <header-desktop class="is-hidden-touch" />
    <div class="columns is-mobile">
      <div
        class="column is-full-desktop is-full-laptop is-full-tablet is-full-mobile"
      >
        <div class="mobile-title"><span>Definições</span></div>
        <div class="img">
          <img v-if="userImg !== null && !file" :src="userImage" alt="" />
          <img v-else-if="file" :src="getFileURL" alt="" />
          <i v-else class="photo-icon mdi mdi-account mdi-48px" />
          <div class="edit-icon">
            <b-field class="file is-primary" :class="{ 'has-name': !!file }">
              <editIcon @click.native="isComponentModalActive = true" />
            </b-field>
          </div>
        </div>

        <div class="user-info">
          <span class="name">{{ receivedData.name }}</span
          ><br />
          <span class="username">@{{ receivedData.username }}</span
          ><br />
          <div class="gamesInfo">
            <span>Jogos Jogados</span><br />
            <span class="totalGames">{{ gamesPlayed }}</span>
          </div>
          <div class="gamesInfo">
            <span>Jogos Criados</span><br />
            <span class="totalGames">{{ gamesCreated }}</span>
          </div>
        </div>
        <div class="settings">
          <span class="settings-title">Definições</span><br />
          <div>
            <div class="settings-subtitle"><span>Dados Pessoais</span></div>

            <div class="inputs-top">
              <b-field class="name-container custom-label" label="Nome">
                <b-input
                  v-model="name"
                  @input="showUpdateInfoButton"
                  class="input-default"
                >
                </b-input>
              </b-field>
            </div>
            <div class="inputs-top">
              <b-field class="custom-label" label="Email">
                <b-input
                  v-model="email"
                  @input="showUpdateInfoButton"
                  class="input-default"
                >
                </b-input>
              </b-field>
            </div>
            <div class="button-container">
              <b-button
                @click="updateUserInfo"
                :class="disabledSaveInfo ? 'disable' : 'is-primary'"
                :disabled="disabledSaveInfo"
                >Guardar Alterações</b-button
              >
            </div>
          </div>

          <div>
            <div class="settings-subtitle"><span>Segurança</span></div>

            <div class="inputs-bot">
              <b-field
                class="password-container custom-label"
                label="Password Atual"
              >
                <b-input
                  type="password"
                  v-model="password"
                  class="input-default"
                  password-reveal
                  @input="showChangePasswordButton"
                >
                </b-input>
              </b-field>
            </div>

            <div class="inputs-bot">
              <b-field class="custom-label" label="Nova Password">
                <b-input
                  type="password"
                  v-model="newPassword"
                  class="input-default"
                  password-reveal
                  @input="showChangePasswordButton"
                >
                </b-input
                ><br />
              </b-field>
            </div>

            <div class="button-container">
              <b-button
                :class="disabledSavePassword ? 'disable' : 'is-primary'"
                :disabled="disabledSavePassword"
                @click="updatePassword"
                >Alterar Password</b-button
              >
            </div>
            <p v-show="newPasswordError" class="error-info">
              A nova password deve ter no mínimo 8 caracteres!
            </p>
            <p v-show="passwordError" class="error-info">
              Password atual incorreta!
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isComponentModalActive"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      :has-modal-card="true"
    >
      <template>
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <modal
              @closeModal="closeModal"
              @updatePhoto="updatePhoto"
              @removePhoto="removePhoto"
            ></modal>
          </section>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import modal from "../../components/Profile/SelectPhotoModal";
import HeaderDesktop from "../../components/Header";
import apiProfile from "../../api/profile";
import editIcon from "../../assets/icons/edit-photo";
import HeaderMobile from "../../components/layout/header-mobile.vue";
import { fb } from "../../firebase/firebase";

export default {
  components: {
    Sidebar,
    modal,
    HeaderDesktop,
    editIcon,
    HeaderMobile,
  },
  data() {
    return {
      userId: "",
      receivedData: [],
      name: "",
      email: "",
      username: "",
      password: "",
      newPassword: "",
      changeName: false,
      changeEmail: false,
      changePassword: false,
      isComponentModalActive: false,
      newPasswordError: false,
      passwordError: false,
      token: "",
      disabledSaveInfo: true,
      disabledSavePassword: true,
      gamesPlayed: 0,
      gamesCreated: 0,
      file: null,
      userImg: null,
    };
  },
  methods: {
    async getUserInfo() {
      apiProfile
        .getUserInfo(this.userId)
        .then((result) => {
          this.receivedData = result.user;
          this.name = result.user.name;
          this.email = result.user.email;
          this.username = result.user.username;
          this.userImg = result.user.photo;
          this.gamesPlayed = result.user.gamesPlayed.length;
          this.gamesCreated = result.user.gamesCreated.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showUpdateInfoButton() {
      if (
        this.name !== this.receivedData.name ||
        this.email !== this.receivedData.email
      ) {
        this.disabledSaveInfo = false;
      } else this.disabledSaveInfo = true;
    },
    showChangePasswordButton() {
      if (this.password !== "" && this.newPassword !== "") {
        this.disabledSavePassword = false;
      } else {
        this.disabledSavePassword = true;
      }
    },

    updatePassword() {
      let data = {
        password: this.password,
        newPassword: this.newPassword,
      };
      if (this.newPassword.length < 8) {
        this.newPasswordError = true;
      } else {
        this.newPasswordError = false;
        apiProfile
          .updatePassword(data, this.token)
          .then((response) => {
            console.log(response[0].status);
            if (response[0].status !== 400) {
              this.passwordError = false;
              this.showSavePassword = false;
              this.$buefy.toast.open({
                message: "Password alterada com sucesso!",
                type: "is-primary",
              });
              this.password = "";
              this.newPassword = "";
            } else {
              this.passwordError = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    updateUserInfo() {
      let data = {
        email: this.email,
        name: this.name,
      };
      apiProfile
        .updateUserInfo(data, this.token)
        .then((response) => {
          console.log(response[0].status);
          if (response[0].status !== 400) {
            this.$buefy.toast.open({
              message: "Dados alterados com sucesso!",
              type: "is-primary",
            });
          }
          this.receivedData.name = this.name;
          this.showSaveInfo = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeModal(value) {
      this.isComponentModalActive = value;
    },

    updatePhoto(value) {
      this.file = value;

      const storageRef = fb
        .storage()
        .refFromURL(
          `gs://qrgame-fe8b2.appspot.com/ProfilePictures/${
            this.receivedData.name
          }_${this.userId}/${Math.random()}_${this.file.name}`
        );

      let uploadTask = storageRef.put(this.file);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          console.error(error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              let data = {
                photo: downloadURL,
              };
              apiProfile
                .updateUserPhoto(data, this.token)
                .then((response) => {
                  if (response[0].status !== 400) {
                    this.$buefy.toast.open({
                      message: "Foto alterada com sucesso!",
                      type: "is-primary",
                    });
                  }
                  this.receivedData.photo = downloadURL;
                  this.showSaveInfo = false;
                  this.getUserInfo();
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      );
    },
    removePhoto() {
      let imgURL = this.userImg;
      let firebaseImage = fb.storage().refFromURL(imgURL);
      firebaseImage
        .delete()
        .then(() => {
          let data = {
            photo: null,
          };
          apiProfile
            .updateUserPhoto(data, this.token)
            .then((response) => {
              if (response[0].status !== 400) {
                this.$buefy.toast.open({
                  message: "Foto apagada com sucesso!",
                  type: "is-primary",
                });
              }
              this.receivedData.photo = null;
              this.showSaveInfo = false;
              this.getUserInfo();
            })
            .catch((error) => {
              console.error(error);
            });
          this.$router.go();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  computed: {
    userImage() {
      return this.userImg;
    },
    getFileURL() {
      return window.URL.createObjectURL(this.file);
    },
  },
  created() {
    this.userId = this.$cookies.get("userId");
    this.token = this.$cookies.get("token");
    this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_profile.scss";
</style>
