<template>
  <section>
    <div id="game-overview">
      <h3>Resumo do Jogo</h3>
      <div class="game-data">
        <div v-if="gameDetails">
          <ul>
            <li>
              <label>Nome do Jogo: </label> <span>{{ gameDetails.name }}</span>
            </li>
            <li>
              <label>Descrição: </label
              ><span>{{ gameDetails.description }}</span>
            </li>
            <li>
              <label>Localização do Jogo: </label
              ><span>{{ gameDetails.location }}</span>
            </li>
            <li>
              <label>Tipo de Jogo: </label
              ><span v-if="gameDetails.gameMode.gameType === 'public'">
                Público</span
              >
              <span v-else> Privado</span>
            </li>
            <li>
              <label>Tipo de Jogadores: </label
              ><span v-if="gameDetails.gameMode.playersType === 'group'"
                >Equipa</span
              >
              <span
                v-else-if="gameDetails.gameMode.playersType === 'individual'"
                >Individual</span
              >
              <span v-else>Equipa e Individual</span>
            </li>

            <li v-if="gameDetails.gameMode.playersType !== 'individual'">
              <label>Limite de Jogadores por grupo: </label
              ><span>{{ gameDetails.playersLimit }}</span>
            </li>

            <li>
              <label>Dificuldade de jogo: </label
              ><span v-if="gameDetails.dificulty === 1">Fácil</span>
              <span v-else-if="gameDetails.dificulty === 3">Médio</span>
              <span v-else>Difícil</span>
            </li>

            <li>
              <label>Número de etapas: </label
              ><span>{{ gameDetails.steps.length }}</span>
            </li>
            <!--Estimated time setup-->
            <li>
              <label>Tempo estimado do jogo: </label>
              <span>{{ gameTotalTime }}</span>
            </li>

            <li v-if="gameDetails.categories.length > 0">
              <label v-if="gameDetails.categories.length === 1"
                >Categoria:</label
              >
              <label v-else>Categorias:</label>

              <b-field grouped group-multiline>
                <div
                  class="control"
                  v-for="category in gameDetails.categories"
                  :key="category.name"
                >
                  <b-tag class="is-radiusless">
                    {{ category.name }}
                  </b-tag>
                </div>
              </b-field>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="!hasEmail">
        <h3>Email para enviar os códigos qr do jogo</h3>
        <b-field
          v-if="email.trim().length === 0"
          class="block form-label"
          type="is-danger"
          message="É obrigatório adicionar um email para podermos mandar os códigos qr"
        >
          <b-input type="email" v-model="email"></b-input>
        </b-field>
        <b-field
          v-else
          class="block form-label"
          message="Este email é inválido"
        >
          <b-input type="email" v-model="email"></b-input>
        </b-field>
      </div>

      <div class="navigation-buttons">
        <b-button @click="previousStep">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </b-button>
        <b-button
          v-if="this.$route.name === 'create' || this.$route.name === 'edit'"
          @click="nextStep"
        >
          Criar Jogo
        </b-button>
        <b-button v-if="this.$route.name === 'update'" @click="nextStep">
          Atualizar Jogo
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import GameRequest from "../../api/GameRequests.js";
import apiProfile from "../../api/profile";

export default {
  props: ["gameDetails", "formatedData", "formatedDetails"],
  emits: ["clicked"],
  data() {
    return {
      inactiveNavigation: true,
      newData: null,
      email: "",
      name: "",
      token: this.$cookies.get("token"),
      hasEmail: true,
      userHadEmail: true,
      gameTotalTime: 0,
    };
  },

  beforeMount() {
    this.timeCalc();
  },

  mounted() {
    this.getUserInfo();
  },

  methods: {
    // callculate the time estimate
    async timeCalc() {
      let newEstTime = 0;
      let counter = 0;

      const stepNavigation = this.formatedData.steps.filter(
        (step) => step.type == "hint" || step.type == "photo"
      );

      await Promise.all(
        stepNavigation.map(async (step, index) => {
          if (stepNavigation[index + 1]) {
            fetch(
              `https://maps.googleapis.com/maps/api/directions/json?origin=${
                step.qrCodeCoordinates.lat
              }, ${step.qrCodeCoordinates.lng}&destination=${
                stepNavigation[index + 1].qrCodeCoordinates.lat
              }, ${
                stepNavigation[index + 1].qrCodeCoordinates.lng
              }&key=AIzaSyB3KdFR4_QaGG5Dv01xkwrgLr2LO2Zwr_w&mode=walking`
            )
              .then((res) => res.json())
              .then((json) => {
                newEstTime += json.routes[0].legs[0].duration.value;
                //estimatedTime = estimatedTime + step.estimatedTime
                counter++;
                if (counter == stepNavigation.length - 1) {
                  console.log(newEstTime);
                  // this new time dont forget its seconds, so it needs to be formated for min or hours

                  // we will test it here the format and put in on console for the time beeing

                  let hours = Math.floor(newEstTime / 3600);
                  let minutes = Math.floor((newEstTime - hours * 3600) / 60);

                  //if its less then an hour
                  if (hours <= 0) {
                    this.gameTotalTime = `${minutes} Minutos`;
                  }
                  //if its an hour long
                  else {
                    if (minutes < 10) {
                      console.log(`${hours} Horas : 0${minutes} Minutos`);
                      this.gameTotalTime = `${hours} Horas : 0${minutes} Minutos`;
                    } else {
                      console.log(`${hours}:${minutes}`);
                      this.gameTotalTime = `${hours} Horas :${minutes} Minutos`;
                    }
                  }

                  this.formatedData.details[0].estimatedTime = newEstTime;
                  this.gameDetails.estimatedTime = newEstTime;

                  console.log(
                    "Checker: formateData.details.estimatedTime= " +
                      this.formatedData.details[0].estimatedTime
                  );
                  console.log(
                    "Checker: gameDetails.estimatedTime= " +
                      this.gameDetails.estimatedTime
                  );
                  //just to check something
                  console.log(this.formatedData.details[0]);
                  console.log(this.gameDetails);
                }
              });
          }
        })
      );
    },

    async getUserInfo() {
      apiProfile
        .getUserInfo(this.$cookies.get("userId"))
        .then((result) => {
          this.email = result.user.email;
          this.name = result.user.name;
          if (this.email.trim().length === 0) {
            this.hasEmail = false;
            this.userHadEmail = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    nextStep() {
      let data = {
        email: this.email,
        name: this.name,
      };
      // Checker to see if the details are being properly sent
      console.log("current fommated data sent:");
      console.log(this.formatedData.details[0]);
      if (this.$route.name === "update") {
        if (!this.userHadEmail) {
          apiProfile
            .updateUserInfo(data, this.token)
            .then((response) => {
              if (response[0].status !== 400) {
                this.$buefy.toast.open({
                  message: "O teu email foi atualizado com sucesso!",
                  type: "is-primary",
                });
                this.formatedData.details[0].available = true;
                GameRequest.updateGame(this.$route.params.id.toString(), {
                  details: this.formatedData.details[0],
                  steps: this.formatedData.steps,
                }).then((game) => {
                  if (game.success) {
                    this.$buefy.toast.open({
                      message: "O teu jogo foi editado",
                      type: "is-primary",
                    });
                    this.$store.dispatch("deleteGame", this.gameDetails);
                    this.$store.dispatch("getGames");
                    this.$router.push({ path: "/my-games" });
                  } else {
                    this.$buefy.toast.open({
                      message: "Não foi possível editar o jogo",
                      type: "is-danger",
                    });
                  }
                });
              }
            })
            .catch(() => {
              this.$buefy.toast.open({
                message: "Não foi possível editar o jogo",
                type: "is-danger",
              });
            });
        } else {
          GameRequest.updateGame(this.$route.params.id.toString(), {
            details: this.formatedData.details[0],
            steps: this.formatedData.steps,
          }).then((game) => {
            console.log(game);
            if (game.success) {
              this.$buefy.toast.open({
                message: "O teu jogo foi atualizado",
                type: "is-primary",
              });
              this.$store.dispatch("deleteGame", this.gameDetails);
              this.$store.dispatch("getGames");
              this.$router.push({ path: "/my-games" });
            } else {
              this.$buefy.toast.open({
                message: "Não foi possível editar o jogo",
                type: "is-danger",
              });
            }
          });
        }
      }

      if (this.$route.name === "create" || this.$route.name === "edit") {
        if (!this.userHadEmail) {
          apiProfile
            .updateUserInfo(data, this.token)
            .then((response) => {
              if (response[0].status !== 400) {
                this.$buefy.toast.open({
                  message: "O teu email foi atualizado com sucesso!",
                  type: "is-primary",
                });
                GameRequest.createGame({
                  details: this.formatedDetails[0],
                  steps: this.formatedData.steps,
                }).then((game) => {
                  if (game.success) {
                    if (this.$route.name === "create") {
                      this.$buefy.toast.open({
                        message: "O teu jogo foi criado com sucesso!",
                        type: "is-primary",
                      });
                    } else if (this.$route.name === "edit") {
                      this.$buefy.toast.open({
                        message: "O teu jogo foi editado com sucesso!",
                        type: "is-primary",
                      });
                    }
                    this.$store.dispatch("deleteGame", this.gameDetails);
                    this.$store.dispatch("getGames");
                    this.$router.push({ path: "/my-games" });
                  } else {
                    if (this.$route.name === "create") {
                      this.$buefy.toast.open({
                        message:
                          "Não foi possível criar o jogo. Tenta novamente mais tarde",
                        type: "is-danger",
                      });
                    } else if (this.$route.name === "edit") {
                      this.$buefy.toast.open({
                        message:
                          "Não foi possível atualizar o jogo. Tenta novamente mais tarde",
                        type: "is-danger",
                      });
                    }
                  }
                });
              }
            })
            .catch(() => {
              if (this.$route.name === "create") {
                this.$buefy.toast.open({
                  message:
                    "Não foi possível criar o jogo. Tenta novamente mais tarde",
                  type: "is-danger",
                });
              } else if (this.$route.name === "edit") {
                this.$buefy.toast.open({
                  message:
                    "Não foi possível atualizar o jogo. Tenta novamente mais tarde",
                  type: "is-danger",
                });
              }
            });
        } else {
          console.log(this.formatedDetails[0]);
          console.log(this.formatedData.steps);
          GameRequest.createGame({
            details: this.formatedDetails[0],
            steps: this.formatedData.steps,
          }).then((game) => {
            console.log(game);
            if (game.success) {
              if (this.$route.name === "create") {
                this.$buefy.toast.open({
                  message: "O teu jogo foi criado com sucesso!",
                  type: "is-primary",
                });
              }
              if (this.$route.name === "edit") {
                this.$buefy.toast.open({
                  message: "O teu jogo foi editado com sucesso!",
                  type: "is-primary",
                });
              }
              this.$store.dispatch("deleteGame", this.gameDetails);
              this.$store.dispatch("getGames");
              this.$router.push({ path: "/my-games" });
            } else {
              if (this.$route.name === "create") {
                this.$buefy.toast.open({
                  message:
                    "Não foi possível criar o jogo. Tenta novamente mais tarde",
                  type: "is-danger",
                });
              }
              if (this.$route.name === "edit") {
                this.$buefy.toast.open({
                  message:
                    "Não foi possível atualizar o jogo. Tenta novamente mais tarde",
                  type: "is-danger",
                });
              }
            }
          });
        }
      }
    },
    previousStep() {
      this.$emit("clicked", {
        step: 1,
        gameDetails: this.gameDetails,
        formatedDetails: this.formatedDetails,
        formatedGame: this.formatedData,
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/_create-game";
</style>
