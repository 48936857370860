<template>
  <div class="search-options">
    <div class="search-type">
      <div class="game-mode-container">
        <span class="title">Tipo de pesquisa</span>
        <b-field>
          <div>
            <b-radio-button
              v-model="searchType"
              native-value="categories"
              class="search-type-button"
            >
              Categoria
            </b-radio-button>
          </div>
          <div>
            <b-radio-button
              v-model="searchType"
              native-value="creator"
              class="search-type-button"
            >
              Criador
            </b-radio-button>
          </div>
          <div>
            <b-radio-button
              v-model="searchType"
              native-value="location"
              class="search-type-button"
            >
              Localização
            </b-radio-button>
          </div>
          <br />

          <div @click="clearType" class="clear">
            <iconify-icon :icon="icons.remove" height="24" />
          </div>
        </b-field>
      </div>

      <span class="title">Tipo de jogador</span>

      <b-field>
        <b-field>
          <div>
            <b-radio-button
              v-model="playersType"
              native-value="individual"
              class="search-type-button"
            >
              Individual
            </b-radio-button>
          </div>
          <div>
            <b-radio-button
              v-model="playersType"
              native-value="group"
              class="search-type-button"
            >
              Equipa
            </b-radio-button>
          </div>
          <div>
            <b-radio-button
              v-model="playersType"
              native-value="both"
              class="search-type-button"
            >
              Ambos
            </b-radio-button>
          </div>
          <div @click="clearPlayersType" class="clear">
            <iconify-icon :icon="icons.remove" height="24" />
          </div>
        </b-field>
      </b-field>

      <div class="players-limit-container">
        <span class="title">Limite de jogadores</span>
        <div class="counter-align">
          <button class="counter-button" @click="removePlayersLimit()">
            -
          </button>
          <input
            class="counter"
            placeholder="1"
            min="1"
            max="7"
            type="number"
            disabled
            v-model="playersLimit"
          />
          <button class="counter-button" @click="addPlayersLimit()">
            +
          </button>
        </div>
      </div>

      <!-- Funcionalidade ainda não implementada por isso não dá para pesquisar por modo de jogo -->
      <!-- <div class="game-mode-container">
        <span class="title">Modo de jogo</span>
        <b-field>
          <div>
            <b-radio-button
              v-model="gameMode"
              native-value="orientation"
              class="search-type-button"
            >
              Orientação
            </b-radio-button>
          </div>
          <div>
            <b-radio-button
              v-model="gameMode"
              native-value="pedipaper"
              class="search-type-button"
            >
              Pedipaper
            </b-radio-button>
          </div>
        </b-field>
      </div> -->

      <div class="slider-container">
        <span class="title">Avaliação</span>
        <div class="slider">
          <b-field>
            <b-slider
              v-model="gameAvaliation"
              :min="1"
              :max="5"
              :step="1"
              :tooltip="false"
            >
              <template v-for="val in label">
                <b-slider-tick class="label" :value="val" :key="val">{{
                  val
                }}</b-slider-tick>
              </template>
            </b-slider>
          </b-field>
        </div>
      </div>

      <div class="slider-container">
        <span class="title">Dificuldade</span>
        <div class="slider">
          <b-field>
            <b-slider
              v-model="gameDifficulty"
              :min="1"
              :max="5"
              :step="1"
              :tooltip="false"
            >
              <template v-for="val in label">
                <b-slider-tick class="label" :value="val" :key="val">{{
                  val
                }}</b-slider-tick>
              </template>
            </b-slider>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bxTrash from "@iconify-icons/bx/bx-trash";
import IconifyIcon from "@iconify/vue";
export default {
  components: {
    IconifyIcon,
  },
  data() {
    return {
      searchOptions: {
        type: "",
        playersType: "",
        playersLimit: 0,
        rating: 1,
        difficulty: 1,
      },
      searchType: "",
      playersType: "",
      playersLimit: 0,
      gameAvaliation: [1, 5],
      gameDifficulty: [1, 5],
      label: [1, 2, 3, 4, 5],
      gameMode: "",
      icons: {
        remove: bxTrash,
      },
    };
  },
  methods: {
    addPlayersLimit() {
      if (this.playersLimit === "") {
        this.playersLimit = 0;
      }

      this.playersLimit++;
      if (this.playersLimit > 7) {
        this.playersLimit = 7;
      }
    },
    removePlayersLimit() {
      this.playersLimit--;
      if (this.playersLimit < 0) {
        this.playersLimit = 0;
      }
    },
    clearType() {
      this.searchType = "";
    },

    clearPlayersType() {
      this.playersType = "";
    },
  },
  watch: {
    searchType(event) {
      this.searchOptions.type = event;
      this.$emit("setSearchOptions", this.searchOptions);
    },
    playersType(event) {
      this.searchOptions.playersType = event;
      this.$emit("setSearchOptions", this.searchOptions);
    },
    playersLimit(event) {
      this.searchOptions.playersLimit = event;
      this.$emit("setSearchOptions", this.searchOptions);
    },
    gameMode(event) {
      this.searchOptions.type = event;
      this.$emit("setSearchOptions", this.searchOptions);
    },
    gameAvaliation(event) {
      this.searchOptions.rating = event;
      this.$emit("setSearchOptions", this.searchOptions);
    },
    gameDifficulty(event) {
      this.searchOptions.difficulty = event;
      this.$emit("setSearchOptions", this.searchOptions);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_searchOptions.scss";
</style>
