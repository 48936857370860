<template>
  <div class="badge" :class="type">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: ["type", "title"],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.badge {
  border-radius: 5px;
  box-shadow: none;
  height: 25px;
  display: flex;
  margin-right: 5px;
  font-size: 13px;
  padding: 4px;
  width: fit-content;
  margin-top: 10px;
}

.easy {
  background-color: #c8e1d3;
  color: #218730;
}

.medium {
  background-color: #f3e3cf;
  color: #f89500;
}

.hard {
  background-color: #eacad7;
  color: #d1274b;
}
</style>
