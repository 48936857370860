<template>
  <div>
    <GmapMap
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
      :center="mapCoordinates"
      :zoom="zoom"
      map-style-id="roadmap"
      style="width:70%; height:329px;"
      ref="mapRef"
      @click="handleMapClick"
      class="vue-map-container"
    >
      <gmap-marker
        :position="qrCodeCoordinates"
        :draggable="true"
        :clickable="true"
        @drag="handleMapClick"
        @click="panToMarker"
      ></gmap-marker>
    </GmapMap>
    <div>
       <p  v-if="!hasPositionChanged" class="required-message" >Carrega no mapa para indicar onde estará o código QR</p>
    </div>
   
  </div>
</template>
<script>
export default {
  props: ["qrCodeCoordinates", "mapCenter"],
  data() {
    return {
      mapCoordinates: {
        lat: this.qrCodeCoordinates.lat,
        lng: this.qrCodeCoordinates.lng,
      },
      zoom: 18,
      hasPositionChanged: true,
    };
  },

  mounted() { 
   
    if (
       this.qrCodeCoordinates.lat === null &&
       this.qrCodeCoordinates.lng === null
    ) { 
      this.hasPositionChanged = false; 
      this.qrCodeCoordinates ={
        lat: this.mapCenter.lat , 
        lng: this.mapCenter.lng
      }  
      
      this.mapCoordinates ={
        lat: this.mapCenter.lat , 
        lng: this.mapCenter.lng
      }  
    }
    this.panToMarker(); 
  },

  methods: {
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.sendData(e);
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.qrCodeCoordinates);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.sendData(e);
    },

    sendData(e) {
      this.hasPositionChanged = true;
      this.qrCodeCoordinates = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.$emit("newQRCodePosition", {
        coordinates: this.qrCodeCoordinates,
        hasPositionChanged: this.hasPositionChanged,
      });
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 30px;
  color: #3158a3;
}

.vue-map-container {
  width: 100% !important;
}

.vue-map-container,
.vue-map-container .vue-map {
  width: 100%;
  height: 100%;
}
.required-message{
  color: #D1274B
}
</style>
