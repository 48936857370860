<template>
	<div class="modal-card">
		<section class="modal-card-body">
			<header>Suspeitas que alguém tenha tirado o código do sítio?</header>
			<p class="modal-body"><span class="danger">Atenção:</span> Ao dizer que sim não receberás nenhum crédito relativo a esta etapa.</p>
			<div class="button-group">
				<b-button @click="$emit('closeReportModal', {action:'report', index: stepId})" type="is-primary is-light">Sim</b-button>

				<b-button @click="$emit('closeReportModal',  {action:'close', index: stepId})" class="is-primary">Não</b-button>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props:["stepId"]
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '../../styles/_reportCodeMissingModal';
</style>
