<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <span class="modal-card-title">Recuperar Password</span>
      <button type="button" class="delete" @click="closeModal()" />
    </header>
    <section class="modal-card-body">
      <span class="info"
        >Introduza o email da conta que quer recuperar a password. Dentro de
        momentos receberá um email de confirmação para proceder à
        alteração.</span
      ><br />
      <b-field label="">
        <b-input
          type="email"
          placeholder="Email"
          v-model="email"
          :class="{ 'error-text': errors.has('email') }"
          v-validate="'email|required'"
          name="email"
        >
        </b-input>
      </b-field>
      <p v-show="errors.has('email')" class="error-info">
        Introduza um endereço de email válido!
      </p>
      <div class="button-container">
        <b-button label="Enviar" @click="sendEmail" class="send" />
      </div>
      <p v-if="emailNotFound" class="error-info">Email não encontrado!</p>
    </section>
  </div>
</template>

<script>
import apiAuth from "../../api/auth";
export default {
  data() {
    return {
      email: "",
      emailNotFound: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    async sendEmail() {
      this.$validator.validateAll().then(async (valid) => {
        if (valid) {
          apiAuth.sendEmail(this.email).then((result) => {
          
            if (result[0].status === 404) {
              this.emailNotFound = true;
            } else {
              this.emailNotFound = false;
              this.email = "";
              this.$buefy.toast.open({
                message: "Email enviado com sucesso!",
                type: "is-primary",
              });
              this.closeModal();
            }
          });

         
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../../styles/_resetPasswordModal";


</style>
