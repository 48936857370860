<template>
  <section id="game-form">
    <b-field
      v-if="game.name.trim().length === 0"
      label="Nome do jogo"
      class="block form-label"
      type="is-danger"
      message="É obrigatório adicionar um nome para o jogo"
    >
      <b-input v-model="game.name" @change="checkButtonNavigation"></b-input>
    </b-field>
    <b-field v-else label="Nome do jogo" class="block form-label">
      <b-input v-model="game.name" @change="checkButtonNavigation"></b-input>
    </b-field>

    <b-field
      v-if="game.description.trim().length === 0"
      label="Descrição do Jogo"
      class="form-label"
      type="is-danger"
      message="É obrigatório adicionar uma descrição ao jogo"
    >
      <b-input
        maxlength="200"
        type="textarea"
        v-model="game.description"
        @change="checkButtonNavigation"
      ></b-input
    ></b-field>
    <b-field v-else label="Descrição do Jogo" class="form-label">
      <b-input
        maxlength="200"
        type="textarea"
        v-model="game.description"
        @change="checkButtonNavigation"
      ></b-input>
    </b-field>

    <b-field label="Categoria(s)" class="block form-label">
      <b-taginput
        :data="filteredTags"
        field="name"
        v-model="game.tags"
        autocomplete
        :allow-new="allowNew"
        :open-on-focus="openOnFocus"
        icon="label"
        placeholder="Adicionar uma categoria"
        aria-close-label="Apagar esta categoria"
        @typing="getFilteredTags"
        @change="checkButtonNavigation"
      >
      </b-taginput>
    </b-field>

    <div class="columns">
      <div class="column">
        <b-field
          v-if="game.location.trim().length === 0"
          label="Localização do Jogo"
          class="form-label block"
          type="is-danger"
          message="É obrigatório adicionar uma localização para o jogo"
        >
          <b-autocomplete
            icon="map-marker"
            :keep-first="keepFirst"
            :open-on-focus="openOnFocus"
            v-model="game.location"
            :data="filteredDataObj"
            field="city"
            @select="(option) => changeLocation(option)"
            :clearable="clearable"
          >
          </b-autocomplete>
        </b-field>
        <b-field v-else label="Localização do Jogo" class="form-label block">
          <b-autocomplete
            icon="map-marker"
            :keep-first="keepFirst"
            :open-on-focus="openOnFocus"
            v-model="game.location"
            :data="filteredDataObj"
            field="city"
            @select="(option) => changeLocation(option)"
            :clearable="clearable"
          >
          </b-autocomplete>
        </b-field>
      </div>

      <div class="column">
        <p class="label">Tipo de Jogo</p>
        <div class="columns block">
          <div class="column">
            <b-button
              v-for="button in game.gameTypesButtons"
              :class="{ active: button.isActive }"
              :key="button.id"
              type="is-primary is-light"
              @click="activateGameTypeButton(button)"
              >{{ button.text }}</b-button
            >
          </div>
        </div>
        <p
          style="margin-left: 16px"
          class="help is-danger"
          v-if="game.gameMode.gameType.trim().length === 0"
        >
          É obrigatório indicar o tipo de jogo
        </p>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <p class="label">Tipo de Jogadores</p>
        <div class="columns block">
          <div class="column">
            <b-button
              v-for="button in game.playersTypeButtons"
              :class="{ active: button.isActive }"
              :key="button.id"
              type="is-primary is-light"
              @click="activateButton(button)"
              >{{ button.text }}</b-button
            >
          </div>
        </div>
        <p
          style="margin-left: 16px"
          class="help is-danger"
          v-if="game.gameMode.playersType.trim().length === 0"
        >
          É obrigatório indicar o tipo de jogadores
        </p>
      </div>

      <div class="column" v-if="game.gameMode.playersType !== 'individual'">
        <p class="label">Limite de membros por equipa</p>
        <button class="counter-button" @click="decrementPlayersLimit">-</button>
        <input
          class="counter"
          placeholder="2"
          min="2"
          max="7"
          type="number"
          v-model="game.playersLimit"
          disabled
        />
        <button class="counter-button" @click="incrementPlayersLimit">+</button>
      </div>
    </div>


    <!---------Dificulty buttons like others -------->

    <div class="column">
        <p class="label">Dificuldade do Jogo</p>
        <div class="columns block">
          <div class="column">
            <b-button
              v-for="button in game.dificulttyTypeButtons"
              :class="{ active: button.isActive }"
              :key="button.id"
              type="is-primary is-light"
              @click="activeDificultyTypeButton(button)"
              >{{ button.text }}</b-button
            >
          </div>
        </div>
        <!-- This is the part has to be done diferently sence its not strings and trims only work for strings -->
        <p
          style="margin-left: 16px"
          class="help is-danger"
          v-if="game.dificulty === 0"
        >
          É obrigatório indicar a Dificuldade do Jogo
        </p>
      </div>

    

    <div class="navigation-buttons">
      <b-button @click="onPreviousStepClick">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </b-button>
      <b-button
        :class="{ 'button-inactive': inactiveNavigation }"
        @click="onClickNextStep"
      >
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </b-button>
    </div>
  </section>
</template>

<script>
import GameRequest from "../../api/GameRequests.js";
import CityNames from "../../api/CityNames";
import idb from "@/api/idb";
// All game categories in the database

let data = [];
let CityNamesList = CityNames.cityNames;

GameRequest.getCategories().then((result) => {
  data = result;
});

export default {
  emits: ["clicked"],
  data() {
    return {
      game: null,
      formatedDetails: [],
      filteredTags: data,
      allowNew: true,
      openOnFocus: true,
      categories: [],
      keepFirst: false,
      selected: null,
      clearable: false,
      gameExists: false
    };
  },

  created() {
    if (this.$route.name === "update") {
      this.saveGameToStore();
    } else if (this.$route.name === "edit" || this.$route.name === "create") {
      this.getGameInfo();
    }
    this.inactiveNavigation = false;
    this.clearable = false;
    this.openOnFocus = false;
    this.keepFirst = false;
    this.allowNew = true;
    this.filteredTags = data;
    this.checkButtonNavigation();
  },

  watch: {
    "game.tags"(val) {
      this.isCategoryNew(val);
      this.checkButtonNavigation();
    },
    "game.name"() {
      this.checkButtonNavigation();
    },
    "game.description"() {
      this.checkButtonNavigation();
    },

    "game.location"() {
      this.checkButtonNavigation();
    }

  },

  computed: {
    filteredDataObj() {
      return CityNamesList.filter((option) => {
        return (
          option.city
            .toString()
            .toLowerCase()
            .indexOf(this.game.location.toLowerCase()) >= 0
        );
      });
    },
  },

  methods: {
    getGameInfo() {
      idb.getGameById(Number(this.$route.params.id)).then((gameInfo) => {
        this.game = gameInfo;
      });
      this.game.mapCenter = this.game.steps[0].qrCodeCoordinates;
    },
    async saveGameToStore() {
      await this.$store.dispatch("getGames");

      let gameArray = JSON.parse(JSON.stringify(this.$store.state.games));
      let gameData = [];
      let stepsObject = [];
      if (gameArray.length > 0) {
        await gameArray.map((game) => {
          if (
            game._id !== undefined &&
            game._id === this.$route.params.id.toString()
          ) {
            this.gameExists = true;
          }
        });
      }

      if (!this.gameExists || gameArray.length === 0) {
        GameRequest.getGameData().then((game) => {
          game.steps.map((step, index) => {
            if (step.type === "hint") {
              let tips = [];
              step.tips.map((tip) => {
                tips.push({ text: tip.text, value: tip.value });
              });
              stepsObject.push({
                id: index,
                type: { text: "Pista", value: "hint" },
                isStepValid: true,
                estimatedDuration: {
                  duration: new Date(
                    `Wed May 26 2021 00:${step.estimatedTime}:00 GMT+0100 (Western European Summer Time)`
                  ),
                  formatedDuration: step.estimatedTime,
                },
                hint: {
                  text: step.text,
                  tips: tips,
                },
                question: {
                  question: "",
                  answers: [
                    {
                      optionText: "",
                      rightOption: true,
                    },
                    {
                      optionText: "",
                      rightOption: false,
                    },
                    {
                      optionText: "",
                      rightOption: false,
                    },
                    {
                      optionText: "",
                      rightOption: false,
                    },
                  ],
                },
                photo: {
                  text: "",
                },
                numberOfCreditsToWin: 5,
                numberOfCreditsToLose: 5,
                qrCodeCoordinates: {
                  coordinates: {
                    lat: step.qrCodeCoordinates.lat,
                    lng: step.qrCodeCoordinates.lng,
                  },
                  hasPositionChanged: true,
                },
              });
            } else if (step.type === "question") {
              let answers = [];
              step.answers.map((answer) => {
                answers.push({
                  optionText: answer.optionText,
                  rightOption: answer.rightOption,
                });
              });
              stepsObject.push({
                id: index,
                type: { text: "Pergunta", value: "question" },
                isStepValid: true,
                estimatedDuration: {
                  duration: new Date(
                    `Wed May 26 2021 00:${step.estimatedTime}:00 GMT+0100 (Western European Summer Time)`
                  ),
                  formatedDuration: step.estimatedTime,
                },
                numberOfCreditsToWin: 5,
                numberOfCreditsToLose: 5,
                question: {
                  question: step.question,
                  answers: answers,
                },
                hint: {
                  text: "",
                  tips: [
                    {
                      text: "",
                      value: 20,
                    },
                  ],
                },
                photo: {
                  text: "",
                },
              });
            } else {
              stepsObject.push({
                id: index,
                type: { text: "Fotografia", value: "photo" },
                isStepValid: true,
                estimatedDuration: {
                  duration: new Date(
                    `Wed May 26 2021 00:${step.estimatedTime}:00 GMT+0100 (Western European Summer Time)`
                  ),
                  formatedDuration: step.estimatedTime,
                },
                photo: {
                  text: step.text,
                },
                question: {
                  question: "",
                  answers: [
                    {
                      optionText: "",
                      rightOption: true,
                    },
                    {
                      optionText: "",
                      rightOption: false,
                    },
                    {
                      optionText: "",
                      rightOption: false,
                    },
                    {
                      optionText: "",
                      rightOption: false,
                    },
                  ],
                },
                hint: {
                  text: "",
                  tips: [
                    {
                      text: "",
                      value: 20,
                    },
                  ],
                },
              });
            }
          });
          let newId = 0;
          if (this.$store.state.games.length > 0) {
            newId =
              this.$store.state.games[this.$store.state.games.length - 1].id +
              1;
          } else {
            newId = 1;
          }
          console.log(newId);
          gameData = {
            ...game,
            _id: game._id,
            id: newId,
            gameTypesButtons: [
              {
                id: 0,
                isActive: game.gameMode.gameType === "public" ? true : false,
                text: "Jogo Público",
                value: "public",
              },
              {
                id: 1,
                isActive: game.gameMode.gameType === "private" ? true : false,
                text: "Jogo Privado",
                value: "private",
              },
            ],
            playersTypeButtons: [
              {
                id: 0,
                isActive:
                  game.gameMode.playersType === "individual" ? true : false,
                text: "Individual",
                value: "individual",
              },
              {
                id: 1,
                isActive: game.gameMode.playersType === "group" ? true : false,
                text: "Equipa",
                value: "group",
              },
              {
                id: 2,
                isActive: game.gameMode.playersType === "both" ? true : false,
                text: "Ambos",
                value: "both",
              },
            ],
            /* Difficulty buttons */
            dificulttyTypeButtons: [
              {
                id: 0,
                isActive:
                  game.dificulty === 1
                    ? true
                    : false,
                text: "Fácil",
                value: 1,
              },
              {
                id: 1,
                isActive:
                  game.dificulty === 3
                    ? true
                    : false,
                text: "Médio",
                value: 3,
              },
              {
                id: 2,
                isActive:
                  game.dificulty === 4
                    ? true
                    : false,
                text: "Difícil",
                value: 4,
              },
            ], 
            tags: [],
            suggestedCategories: [],
            steps: stepsObject,
            invalidStepsCounter: 0,
            mapCenter: {
              lat: game.steps[game.steps.length - 1].qrCodeCoordinates.latitude,
              lng: game.steps[game.steps.length - 1].qrCodeCoordinates
                .longitude,
            },
          };
          this.game = gameData;
          console.log(gameData);
        });
      } else {
        idb.getGameById(this.$route.params.id).then((gameInfo) => {
          this.game = gameInfo;
        });
      }
      this.game.mapCenter = this.game.steps[0].qrCodeCoordinates;
    },
    async save() {
      await this.$store.dispatch("saveGame", this.game);
    },
    changeLocation(option) {
      this.game.location = option.city;
      this.game.mapCenter.lat = parseFloat(option.latitude);
      this.game.mapCenter.lng = parseFloat(option.longitude);
    },
    checkButtonNavigation() {
      this.save();
      if (
        this.game.name.trim() !== "" &&
        this.game.location.trim() !== "" &&
        this.game.description.trim() !== "" &&
        this.game.gameMode.gameType.trim() !== "" &&
        this.game.gameMode.playersType.trim() !== "" &&
        this.game.dificulty !== 0 
      ) {
        this.inactiveNavigation = false;
      } else {
        this.inactiveNavigation = true;
      }
    },
    onClickNextStep() {
      if (!this.inactiveNavigation) {
        this.formatedDetails = [
          {
            creator: this.game.creator,
            name:
              this.game.name.charAt(0).toUpperCase() + this.game.name.slice(1),
            description:
              this.game.description.charAt(0).toUpperCase() +
              this.game.description.slice(1),
            gameMode: this.game.gameMode, // change array format
            playersLimit: this.game.playersLimit,
            location: this.game.location,
            categories: this.game.categories,
            dificulty: this.game.dificulty,
            available: true,
          },
        ];
        this.save();
        // Add the first step coordinates to the a added step in case the game is being edited
        if (this.game.mapCenter.lat === undefined) {
          this.game.mapCenter =
            this.game.steps[0].qrCodeCoordinates.coordinates;
        }
        this.$emit("clicked", {
          step: 1,
          gameDetails: this.game,
          formatedDetails: this.formatedDetails,
          mapCenter: this.game.mapCenter,
        });
      }
    },
    onPreviousStepClick() {
      this.$router.push("/");
    },
    isCategoryNew(newValue) {
      let tagsArray = JSON.parse(JSON.stringify(newValue));
      this.game.suggestedCategories = [];
      this.game.categories = [];

      // If the category doesn't exist in our database, make a request to suggest the category
      tagsArray.filter((tag) => {
        if (typeof tag === "string") {
          this.game.suggestedCategories.push({
            name: tag,
            status: "suggested",
          });
          this.game.categories.push({
            name: tag,
            status: "suggested",
          });
        } else {
          this.game.categories.push({ name: tag.name, status: tag.status });
        }
      });

      this.game.categories = JSON.parse(JSON.stringify(this.game.categories));
    },

    getFilteredTags(text) {
      this.filteredTags = data.filter((option) => {
        // only suggest categories that haven't been added yet
        if (!this.game.tags.find((category) => category.name === option.name)) {
          return (
            option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >=
            0
          );
        }
      });
    },

    setGameType(type) {
      this.game.gameMode.gameType = type;
    },

    setPlayersType(type) {
      this.game.gameMode.playersType = type;
    },

    setDificultyType(type) {
      this.game.dificulty = type;
    },

    activateButton(activatedButton) {
      for (let button of this.game.playersTypeButtons) {
        button.isActive = button.text === activatedButton.text;
      }
      this.game.gameMode.playersType = activatedButton.value;
      this.checkButtonNavigation();
    },

    activateGameTypeButton(activatedButton) {
      for (let button of this.game.gameTypesButtons) {
        button.isActive = button.text === activatedButton.text;
      }
      this.game.gameMode.gameType = activatedButton.value;
      this.checkButtonNavigation();
    },


    activeDificultyTypeButton(activatedButton) {
      for (let button of this.game.dificulttyTypeButtons) {
        button.isActive = button.text === activatedButton.text;
      }
      this.game.dificulty= activatedButton.value;      
      this.checkButtonNavigation();
    },

    

    incrementPlayersLimit() {
      this.game.playersLimit++;
      if (this.game.playersLimit > 7) {
        this.game.playersLimit = 7;
      }
    },

    decrementPlayersLimit() {
      this.game.playersLimit--;
      if (this.game.playersLimit <= 2) {
        this.game.playersLimit = 2;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_create-game";
</style>
