<template>
  <div class="columns is-mobile">
    <div class="column is-full-desktop is-full-mobile">
      <div class="container">
        <div class="header">
          <span class="is-header title">Alterar Password</span>
        </div>
        <b-field label="Password">
          <b-input
            ref="password"
            type="password"
            password-reveal
            placeholder="Password"
            class="input-default"
            v-model="password"
            :class="{ 'error-text': errors.has('name') }"
            v-validate="'required|min:8'"
            name="password"
          >
          </b-input>
        </b-field>
        <ul>
          <li
            v-for="error in errors.collect('password')"
            :key="error"
            class="error-info"
          >
            {{ error }} <span v-if="error[2] == 't'">caracteres!</span>
          </li>
        </ul>
        <b-field label="Confirmar Password">
          <b-input
            type="password"
            password-reveal
            placeholder="Password"
            class="input-default"
            v-model="repeatPassword"
            :class="{ 'error-text': errors.has('repeatPassword') }"
            v-validate="'required|confirmed:password'"
            name="repeatPassword"
          >
          </b-input>
        </b-field>
        <p v-show="errors.has('repeatPassword')" class="error-info">
          As passwords não coincidem!
        </p>
        <div class="button-container">
          <b-button label="Confirmar" @click="resetPassword" class="send" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiAuth from "../../api/auth";
export default {
  data() {
    return {
      token: "",
      password: "",
      repeatPassword: "",
    };
  },
  created() {
    console.log(this.$route.query.token);
    this.token = this.$route.query.token;
  },
  methods: {
    async resetPassword() {
      this.$validator.validateAll().then(async (valid) => {
        if (valid) {
          apiAuth
            .resetPassword({ password: this.password }, this.token)
            .then((response) => {
              console.log(response);
              this.$buefy.toast.open({
                message: "Password alterada com sucesso!",
                type: "is-primary",
              });
              this.$router.push("/auth");
            })
            .catch((error) => {
            console.error(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_resetPassword";


</style>
