<template>
	<section class="page-content search-modal">
		<sidebar title="Pesquisa" dropdown="true"/>
		<header-desktop />

		<div class="columns">
			<div
				class="column  is-mobile is-three-fifths-desktop is-three-fifths-laptop   is-full-tablet   is-full-mobile"
			>
				<div class="search ">
					<b-field class="search-input" @click="search">
						<b-input
							placeholder="Pesquisa..."
							type="search"
							icon="magnify"
							icon-clickable
							@typing="search"
							v-model="searchValue"
							@icon-click="search"
						>
							>
						</b-input>
					</b-field>
					<div @click="isComponentModalActive = true">
						<icon-filter class="menu-settings-button" />
					</div>

					<b-modal
						v-model="isComponentModalActive"
						trap-focus
						:destroy-on-hide="false"
						aria-role="dialog"
						aria-modal
						:can-cancel="true"
						:has-modal-card="true"
					>
						<template class="options">
							<div class="modal-card" style="width: auto">
								<section class="modal-card-body">
									<search-menu @setSearchOptions="setSearchOptions"></search-menu>
								</section>
							</div>
						</template>
					</b-modal>
				</div>

				<div v-if="searchResult.length !== 0">
					<div class="columns is-multiline ">
						<div v-for="game in paginatedItems" :key="game._id">
							<div class="column">
								<base-card
									@click.native="seeGameDetails(game._id)"
									:game="game"
									:savedGames="savedGame"
									@saveGame="saveGame"
									@removeGame="removeGame"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="image-container" v-else>
					<img src="../../assets/search.png" alt="" /><br />
					<span class="not-found">Não encontrámos nenhum resultado</span>
				</div>

				<div class="pagination">
					<b-pagination
						:total="total"
						v-model="current"
						:range-before="rangeBefore"
						:range-after="rangeAfter"
						:order="order"
						:simple="isSimple"
						:rounded="isRounded"
						:per-page="perPage"
						aria-next-label="Next page"
						aria-previous-label="Previous page"
						aria-page-label="Page"
						aria-current-label="Current page"
					>
					</b-pagination>
				</div>
			</div>

			<div id="options-menu" class="column">
				<search-menu @setSearchOptions="setSearchOptions"></search-menu>
			</div>
		</div>
	</section>
</template>

<script>
import SearchMenu from '../../components/Search/SearchOptions';
import Sidebar from '../../components/layout/Sidebar';
import BaseCard from '../../components/layout/BaseCard.vue';
import HeaderDesktop from '../../components/Header';
import apiSearch from '../../api/search';
import IconFilter from '../../assets/icons/icon-filter.vue';

export default {
	components: {
		SearchMenu,
		Sidebar,
		BaseCard,
		HeaderDesktop,
		IconFilter,
	},
	data() {
		return {
			searchValue: '',
			searchResult: [],
			savedGame: [],
			isGameSaved: false,
			searchType: '',
			seeDetails: true,
			isComponentModalActive: false,
			current: 1,
			perPage: 10,
			rangeBefore: 3,
			rangeAfter: 1,
			order: 'is-centered',
			isSimple: false,
			isRounded: false,
			test: [],
			userId: '',
		};
	},
	computed: {
		total() {
			return this.searchResult.length;
		},
		paginatedItems() {
			let page_number = this.current - 1;

			return this.searchResult.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
		},
	},

	methods: {
		async search() {
			const queryString = `searchType=${this.searchType.type}&searchValue=${this.searchValue}&playersType=${this.searchType.playersType}&playersLimit=${this.searchType.playersLimit}&type=${this.searchType.type}&rating=${this.searchType.rating[0]}&rating=${this.searchType.rating[1]}&difficulty=${this.searchType.difficulty[0]}&difficulty=${this.searchType.difficulty[1]}`;
			apiSearch
				.search(queryString)
				.then((result) => {
					
					this.searchResult = result;
				})
				.catch((error) => {
					console.error(error);
				});
		},

		setSearchOptions(value) {
			this.searchType = value;
			this.search();
		},

		getUserSavedGames() {
			apiSearch.getUserSavedGames(this.userId).then((result) => {
				this.savedGame = result.gameIDs;
			});
		},

		updateGamesSaved(gameId) {
			apiSearch.updateGamesSaved(this.userId, { gameID: gameId });
		},

		removeGame(gameId) {
			this.seeDetails = false;
			this.savedGame.splice(this.savedGame.indexOf(gameId), 1);

			this.updateGamesSaved(gameId);
		},
		saveGame(gameId) {
			this.seeDetails = false;
			this.isGameSaved = !this.isGameSaved;
			this.savedGame.push(gameId);
			this.updateGamesSaved(gameId);
		},

		seeGameDetails(gameId) {
			if (this.seeDetails) {
				this.$router.push({ path: 'game-info/' + gameId });
			} else {
				this.seeDetails = !this.seeDetails;
			}
		},
	},
	watch: {
		searchValue() {
			this.search();
		},
	},
	created() {
		this.userId = this.$cookies.get('userId');
		this.getUserSavedGames();
	},
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import '../../styles/_search.scss';
</style>
