<template>
	<img :src="fordidden" />
</template>
<script>
import fordidden from '../assets/icons/forbidden.png';
export default {
    data(){
        return{
            fordidden: fordidden
        }
    }

};
</script>
