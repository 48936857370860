const groupRequests = {
  joinGroup: async (groupId, data) => {
    try {
      const request = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/groups/${groupId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            console.log(data);
            return true;
          }
        })
        .catch((error) => {
          console.error(error.message);
          return true;
        });
      return JSON.parse(JSON.stringify(request));
    } catch (error) {
      return error;
    }
  },

  getGroupInfo: async (groupId) => {
    try {
      const request = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/groups/${groupId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          return error.message;
        });
      return JSON.parse(JSON.stringify(request));
    } catch (error) {
      return error;
    }
  },

  removeGroup:async (groupId) => {
    try {
      const request = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${groupId}/group`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          return error.message;
        });
      return JSON.parse(JSON.stringify(request));
    } catch (error) {
      return error;
    }
  },

  leaveGroup: async (userId, data) => {
    try {
      const request = await fetch(`${process.env.VUE_APP_API_URL}/qrgame/users/${userId}/group`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          return error.message;
        });
      return JSON.parse(JSON.stringify(request));
    } catch (error) {
      return error;
    }
  },


};

export default groupRequests;
